import React from "react";
/* components */
import CustomContainer from "src/components/common/data-display/custom-container";
import MerchantTopNavbar from "src/components/common/navigation/merchant-top-navbar";
/* 3rd party lib */
import { Global } from "@emotion/react";
import { QrReader } from "react-qr-reader";
import { Box, Button, useTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
/* Util */
import routes from "src/routes";

interface MerchantScanProps {}

type Props = MerchantScanProps;

const MerchantScan: React.FC<Props> = () => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const theme = useTheme();
  const navigate = useNavigate();

  const { state } = useLocation();
  const { stamp, scanFirst } = state;

  const handleQrScan = async (result: any) => {
    if (!result) return;
    // where's there a result, open the asset dialog
    alert(result);

    // const asset = await fetchAssetByBarcode(result);
  };

  //   const fetchAssetByBarcode = async barcode => {
  //     const { data } = await http.get(apiEndpoint.ASSETS, { params: { barcode } });
  //     const { id, name } = data.results[0];

  //     return { id, name };
  //   };

  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */

  return (
    <>
      <Global
        styles={{
          ".qr-reader": {
            zIndex: 1,
            div: {
              paddingTop: 0,
              marginTop: "50px",
            },
          },
        }}
      ></Global>
      <CustomContainer>
        <Box sx={{ position: "relative", background: theme.palette.primary.light, height: "52px", width: "100%" }}>
          <MerchantTopNavbar back />
        </Box>
        <Box sx={{ width: "100%", height: "calc(100vh - 102px)", position: "relative" }}>
          <QrReader
            constraints={{ facingMode: "environment", aspectRatio: 1 }}
            onResult={(result: any) => handleQrScan(result?.text)}
            containerStyle={{ overflow: "visible", height: "calc(100vh - 102px)", width: "100%" }}
            videoStyle={{ height: "calc(100vh - 102px)", width: "100%" }}
            videoContainerStyle={{
              height: "calc(100vh - 102px)",
              transform: "translateY(-50px)",
              width: "100%",
              paddingTop: 0,
            }}
            className="qr-reader"
          />
        </Box>
        <Button
          onClick={() => {
            if (scanFirst) {
              // scan user's phone first to get loyalty program info

              navigate(routes.MERCHANT_USER_REDEEM);
            } else {
              // setup loyalty program first
              navigate(routes.MERCHANT_SCAN_CONFIRM, { state: { stamp: stamp } });
            }
          }}
        >
          Next Page (demo)
        </Button>
      </CustomContainer>
    </>
  );
};

export default MerchantScan;
