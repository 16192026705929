import routes from "src/routes";
import { useTranslation } from "react-i18next";
import { NAVBAR_DASHBOARD, SUBJECT_CONFIGURATION, SUBJECT_LOYALTY } from "src/constants/translate-keys/common";
import { getTranslateString } from "src/utils/translate";
import { useLocation, useSearchParams } from "react-router-dom";

interface IPath {
  path: string;
  title: string;
  url: string;
}

const useBreadcrumb = (): { paths: IPath[] } => {
  const location = useLocation();

  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const breadcrumbsMap = new Map();

  breadcrumbsMap.set(routes.DASHBOARD, getTranslateString(t, NAVBAR_DASHBOARD));
  breadcrumbsMap.set(routes.LOYALTY, getTranslateString(t, SUBJECT_LOYALTY, false, false));
  breadcrumbsMap.set(routes.CONFIGURATION, getTranslateString(t, SUBJECT_CONFIGURATION, false, false));
  breadcrumbsMap.set(routes.MEMBER, "Member");

  const getPaths = () => {
    const names = location.pathname.split("/").filter(name => name);

    const queries = Object.fromEntries([...searchParams]);

    return names.map((name: string, index) => {
      const sanitizedQuery = name.replace("[", "").replace("]", "");
      const isQuery = Object.keys(queries).includes(sanitizedQuery);
      const path = `/${names.slice(0, index + 1).join("/")}`;

      return {
        path: path,
        title: breadcrumbsMap.get(path),
        url: isQuery ? path.replace(name, queries[sanitizedQuery]) : path,
      };
    });
  };

  return { paths: getPaths() };
};

export default useBreadcrumb;
