import * as React from "react";
import { styled, Paper, BottomNavigationAction, useTheme, BottomNavigation } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import routes from "src/routes";

/* components */
/* 3rd party lib */
/* Util */

const StyledBottomNavigationAction = styled(BottomNavigationAction)(({ theme }) => ({
  "&.Mui-selected": {
    color: theme.palette.secondary.main,
  },
}));

interface MerchantBottomNavbarProps {}

type Props = MerchantBottomNavbarProps;

const MerchantBottomNavbar: React.FC<Props> = () => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const theme = useTheme();

  /* ================================================== */
  /*  method */
  /* ================================================== */

  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  useEffect(() => {
    let pageName = pathname.split("/")[2];
    switch (pageName) {
      case "stamp":
        setValue(0);
        break;
      case "home":
        setValue(1);
        break;
      case "scan":
        setValue(2);
        break;
      default:
        setValue(0);
    }
  }, [pathname]);

  /* ================================================== */
  /* ================================================== */
  return (
    <Paper sx={{ position: "fixed", width: "inherit", bottom: 0, height: "50px", zIndex: theme.zIndex.appBar }} elevation={3}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
          switch (newValue) {
            case 0:
              navigate(routes.MERCHANT_STAMP);
              break;
            case 1:
              navigate(routes.MERCHANT_HOME);
              break;
            case 2:
              navigate(routes.MERCHANT_SCAN, { state: { scanFirst: true } });
              break;
            default:
              navigate(routes.MERCHANT_HOME);
          }
        }}
      >
        <StyledBottomNavigationAction label="Stamp" icon={<i className="fak fa-stamp" style={{ fontSize: "20px" }}></i>} />
        <StyledBottomNavigationAction label="Home" icon={<i className="fak fa-customhome" style={{ fontSize: "20px" }}></i>} />
        <StyledBottomNavigationAction label="Scan" icon={<i className="fak fa-customscan" style={{ fontSize: "20px" }}></i>} />
      </BottomNavigation>
    </Paper>
  );
};

export default MerchantBottomNavbar;
