import React, { useEffect } from "react";
/* components */
/* 3rd party lib */
import { Box, Grid, Link, styled, Stack, Button, TextField, Typography, FormControl } from "@mui/material";
import { getTranslateString } from "src/utils/translate";
import { FORM_WARNING_REQUIRED } from "src/constants/translate-keys/common";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import useAuth from "src/hooks/use-auth";
import { useNavigate } from "react-router-dom";
import routes from "src/routes";
import CustomContainer from "src/components/common/data-display/custom-container";
import { useSelector } from "react-redux";
import { RootState } from "src/store";

const StyledTextField = styled(TextField)(({ theme }) => ({
  ".MuiInputBase-root": {
    borderRadius: "50px",
    backgroundColor: "white",
  },
}));

/* Util */
interface SignUpProps {}

type Props = SignUpProps;

const schema = (t: any) =>
  Yup.object().shape({
    username: Yup.string().required(getTranslateString(t, FORM_WARNING_REQUIRED)),
    password: Yup.string().required(getTranslateString(t, FORM_WARNING_REQUIRED)),
  });

const SignUp: React.FC<Props> = () => {
  /* ================================================== */
  /*  state */
  /* ================================================== */

  const { t } = useTranslation();
  const auth = useAuth();
  const bottomHeight = 60;
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);

  const formik = useFormik({
    validateOnChange: false,
    validationSchema: schema(t),
    initialValues: {
      username: "",
      password: "",
      confirm_password: "",
      firstname: "",
      lastname: "",
      mobile_no: "",
      dob: "",
      gender: "",
    },
    onSubmit: async values => {
      try {
        if (auth) {
          await auth.login(values.username, values.password);
        }
        navigate(routes.DASHBOARD, { replace: true });
      } catch (error) {
        console.error(error);
      }
    },
  });
  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */
  useEffect(() => {
    if (isAuthenticated) {
      navigate(routes.HOME, { replace: true });
    }
  }, [isAuthenticated, navigate]);
  /* ================================================== */
  /* ================================================== */
  return (
    <>
      <CustomContainer>
        <Stack sx={{ display: "grid", placeItems: "center", minHeight: "100vh" }}>
          <Box py={3} px={2} sx={{ display: "grid", placeItems: "center", height: `calc(100% - ${bottomHeight}px)` }}>
            <Box>
              <Stack justifyContent="center" alignItems="center" spacing={1} mb={3}>
                <Typography variant="h4" fontWeight={"bolder"} color="primary.dark">
                  SIGN UP
                </Typography>
                <Typography variant="body1" color="text.primary">
                  Sign up to create an account
                </Typography>
              </Stack>
              <form noValidate onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <StyledTextField
                        hiddenLabel
                        // variant="filled"
                        name="username"
                        placeholder="Email/Username"
                        value={formik.values.username}
                        onChange={formik.handleChange}
                        error={formik.errors.username ? true : false}
                        helperText={formik.errors.username}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <StyledTextField
                        // variant="filled"
                        hiddenLabel
                        type="password"
                        name="password"
                        placeholder="Password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={formik.errors.password ? true : false}
                        helperText={formik.errors.password}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <StyledTextField
                        // variant="filled"
                        hiddenLabel
                        type="password"
                        name="confirm_password"
                        placeholder="Confirm Password"
                        value={formik.values.confirm_password}
                        onChange={formik.handleChange}
                        error={formik.errors.password ? true : false}
                        helperText={formik.errors.password}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <StyledTextField
                        hiddenLabel
                        // variant="filled"
                        name="firstname"
                        placeholder="First Name"
                        value={formik.values.firstname}
                        onChange={formik.handleChange}
                        error={formik.errors.username ? true : false}
                        helperText={formik.errors.username}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <StyledTextField
                        hiddenLabel
                        // variant="filled"
                        name="lastname"
                        placeholder="Last Name"
                        value={formik.values.lastname}
                        onChange={formik.handleChange}
                        error={formik.errors.username ? true : false}
                        helperText={formik.errors.username}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <StyledTextField
                        hiddenLabel
                        // variant="filled"
                        name="mobile_no"
                        placeholder="Mobile No."
                        value={formik.values.mobile_no}
                        onChange={formik.handleChange}
                        error={formik.errors.username ? true : false}
                        helperText={formik.errors.username}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <StyledTextField
                        hiddenLabel
                        // variant="filled"
                        name="dob"
                        placeholder="Date of Birth"
                        value={formik.values.dob}
                        onChange={formik.handleChange}
                        error={formik.errors.username ? true : false}
                        helperText={formik.errors.username}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <StyledTextField
                        hiddenLabel
                        // variant="filled"
                        name="gender"
                        placeholder="Gender"
                        value={formik.values.gender}
                        onChange={formik.handleChange}
                        error={formik.errors.username ? true : false}
                        helperText={formik.errors.username}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Box sx={{ mt: 2 }}>
                  <Button fullWidth size="large" disabled={formik.isSubmitting} type="submit" variant="contained">
                    SIGN UP
                  </Button>
                </Box>
              </form>
            </Box>
          </Box>
          <Box sx={{ height: `${bottomHeight}px`, mt: 1 }}>
            <Stack direction="row" alignItems="center" justifyContent={"center"} spacing={1} sx={{ width: "100%" }}>
              <Typography variant="body1" color="text.primary" sx={{ whiteSpace: "nowwrap" }}>
                Already have an account?
              </Typography>
              <Link
                onClick={() => navigate(routes.LOGIN)}
                // href={routes.SIGNUP}
                variant="body1"
                fontWeight={"bolder"}
                color="text.primary"
                underline="hover"
                sx={{ cursor: "pointer", whiteSpace: "nowwrap" }}
              >
                LOG IN
              </Link>
            </Stack>
          </Box>
        </Stack>
      </CustomContainer>
    </>
  );
};

export default SignUp;
