import Typography from "@mui/material/Typography";
import { Card, CardContent, CardHeader, Grid, useTheme } from "@mui/material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { AuthGuard } from "src/components/authentication/auth-guard";
import PageHeaderLayout from "src/components/common/layout/page-header-layout";
import DashboardSidebarLayout from "src/components/dashboard/dashboard-sidebar-layout";
import useMediaQueries from "src/hooks/use-mediaqueries";
import DataTableList from "src/components/common/data-display/data-table-list";
import { getTranslateString, getTranslateStringWithSubject } from "src/utils/translate";
import { faPenToSquare } from "@fortawesome/pro-regular-svg-icons";
import { ACTION_ADD, EDIT, FORM_NAMING, SEARCH_PLACEHOLDER, SUBJECT_OUTLET } from "src/constants/translate-keys/common";
import { useTranslation } from "react-i18next";
import { apiEndpoint } from "src/contexts/http-context";
import Link from "@mui/material/Link/Link";
import Button from "@mui/material/Button";
import { useState } from "react";
import OutletCrudDialog from "src/components/common/feedback/outlet-crud-dialog";

export interface IOutlet {
  uuid: string;
  outlet_name: string;
}

const Configuration = () => {
  // const [muiTab, setMuiTab] = useState(WORK_ORDER_TAB.OVERVIEW);
  const { t } = useTranslation();
  const theme = useTheme();
  const [createOpen, setCreateOpen] = useState(false);
  const [editOpen, setEditOpen] = useState<{ open: boolean; outlet: IOutlet | null }>({ open: false, outlet: null });

  const { smDown } = useMediaQueries();
  const DataDisplay = ({ label, value, alignCenter = true }: { label: string; value: string | React.ReactNode; alignCenter?: boolean }) => (
    <Grid container>
      <Grid item xs={4} sm={3} md={3} lg={3} sx={{ whiteSpace: "pre-line", display: "flex", alignItems: alignCenter ? "center" : "flex-start" }}>
        {typeof label === "string" ? (
          <Typography variant="body2" color={(theme.palette.neutral as any)[500]}>
            {label}
          </Typography>
        ) : (
          label
        )}
      </Grid>
      <Grid item xs={8} sm={9} md={9} lg={9} sx={{ pl: smDown ? 1.5 : 0, display: "flex", alignItems: alignCenter ? "center" : "flex-start" }}>
        {typeof value === "string" ? (
          <Typography variant="body2" fontWeight={400}>
            {value !== "" && value !== null && value !== undefined && value.toLowerCase() !== "Invalid Date".toLowerCase() ? value : "-"}
          </Typography>
        ) : (
          <>{value && value !== "invalid date" ? value : "-"}</>
        )}
      </Grid>
    </Grid>
  );

  const columns = [{ path: "outlet_name", label: getTranslateString(t, FORM_NAMING), sortKey: "outlet_name" }];

  const listMetadata = {
    primary: { path: "outlet_name" },
    fields: [{ path: "outlet_name", label: getTranslateString(t, FORM_NAMING), sortKey: "outlet_name" }],
  };

  return (
    // <AuthGuard permissionKey="WORK_ORDERS">
    <DashboardSidebarLayout>
      <PageHeaderLayout title={"Configurations"}>
        {/* ====================== Merchant Details ====================== */}
        <Card variant={"elevation"} elevation={2}>
          <CardHeader
            sx={{ pb: 0, px: smDown ? 0 : 2 }}
            title={
              <Typography textAlign="left" variant="body1" fontWeight={700} color="text.secondary">
                Merchant Details
              </Typography>
            }
          />
          <CardContent sx={{ px: smDown ? 0 : 2 }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <DataDisplay label="Merchant Name" value={"Merchant X"} />
              </Grid>
              <Grid item xs={12}>
                <DataDisplay label="Email" value={"test@gmail.com"} />
              </Grid>
              <Grid item xs={12}>
                <DataDisplay label="Contact No." value={"98765432"} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        {/* ====================== Loyalty Program Logos ====================== */}
        <Card variant={"elevation"} elevation={2}>
          <CardHeader
            sx={{ pb: 0, px: smDown ? 0 : 2 }}
            title={
              <Typography textAlign="left" variant="body1" fontWeight={700} color="text.secondary">
                Loyalty Program Logos
              </Typography>
            }
          />
          <CardContent sx={{ px: smDown ? 0 : 2 }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <DataDisplay
                  label="Placeholder"
                  value={
                    <Link
                      component="button"
                      variant="subtitle2"
                      onClick={() => {
                        console.info("I'm a button.");
                      }}
                    >
                      Upload Image
                    </Link>
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <DataDisplay
                  label="Stamped"
                  value={
                    <Link
                      component="button"
                      variant="subtitle2"
                      onClick={() => {
                        console.info("I'm a button.");
                      }}
                    >
                      Upload Image
                    </Link>
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <DataDisplay
                  label="Redeemables"
                  value={
                    <Link
                      component="button"
                      variant="subtitle2"
                      onClick={() => {
                        console.info("I'm a button.");
                      }}
                    >
                      Upload Image
                    </Link>
                  }
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        {/* ====================== Outlets ====================== */}
        <Card variant={"elevation"} elevation={2}>
          <CardHeader
            sx={{ pb: 0, px: smDown ? 0 : 2 }}
            title={
              <Typography textAlign="left" variant="body1" fontWeight={700} color="text.secondary">
                Outlets
              </Typography>
            }
            action={
              <Button
                component="a"
                variant="contained"
                onClick={() => setCreateOpen(true)}
                size="small"
                startIcon={<AddRoundedIcon fontSize="small" />}
              >
                {getTranslateStringWithSubject(t, ACTION_ADD, SUBJECT_OUTLET, false, false)}
              </Button>
            }
          />
          <CardContent sx={{ px: smDown ? 0 : 2 }}>
            <DataTableList
              rowClick
              // filters={filters}
              filterType="drawer"
              columns={columns}
              listMetadata={listMetadata}
              rowAction={(rowData: IOutlet) => setEditOpen({ open: true, outlet: rowData })}
              rowActions={[
                {
                  icon: faPenToSquare,
                  label: getTranslateString(t, EDIT),
                  onClick: (rowData: IOutlet) => setEditOpen({ open: true, outlet: rowData }),

                  tooltip: () => getTranslateString(t, EDIT),
                },
              ]}
              search={{
                key: "search",
                label: getTranslateStringWithSubject(t, SEARCH_PLACEHOLDER, SUBJECT_OUTLET, false, false),
              }}
              url={apiEndpoint.OUTLETS("2a903b85-0c17-4468-b9c5-fbaeacf1f401")}
            />
          </CardContent>
        </Card>
      </PageHeaderLayout>

      <OutletCrudDialog crud="create" open={createOpen} onClose={() => setCreateOpen(false)} />
      <OutletCrudDialog crud="update" outlet={editOpen.outlet} open={editOpen.open} onClose={() => setEditOpen({ open: false, outlet: null })} />
    </DashboardSidebarLayout>
    // </AuthGuard>
  );
};

export default Configuration;
