import React from "react";
/* components */
import CustomContainer from "src/components/common/data-display/custom-container";
import MerchantTopNavbar from "src/components/common/navigation/merchant-top-navbar";

/* 3rd party lib */
import { Box, Stack, Container, Typography, useTheme } from "@mui/material";
import useMediaQueries from "src/hooks/use-mediaqueries";

/* Util */
interface MerchantActivtiesProps {}

type Props = MerchantActivtiesProps;

const MerchantActivties: React.FC<Props> = () => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const theme = useTheme();
  const { smDown } = useMediaQueries();

  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <>
      <CustomContainer>
        <MerchantTopNavbar back />

        <Stack
          sx={{
            width: smDown ? "100vw" : "420px",
            background: theme.palette.secondary.light,
            // borderRadius: "50%",
            pt: 8,
            height: "240px",
            clipPath: smDown ? "circle(100% at 50% -50vw)" : " circle(100% at 50% -210px)",
          }}
          spacing={2}
        >
          <Typography variant="h5" fontWeight={"bolder"} color="secondary.main">
            All Activities
          </Typography>
        </Stack>

        <Container sx={{ marginTop: 3, transform: "translateY(-140px)" }}>
          <Typography textAlign={"start"} variant="body1" fontWeight={"bolder"} color="secondary.main">
            Transaction History
          </Typography>
          <Stack spacing={2} mt={1}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Stack alignItems={"flex-start"}>
                <Box sx={{ textAlign: "left" }}>
                  Employee A issued&nbsp;
                  <Typography sx={{ display: "inline-block" }} variant="body1" color="text.primary" fontWeight={"bolder"}>
                    X number of stamps
                  </Typography>
                  &nbsp;to USER B on&nbsp;
                  <Typography sx={{ display: "inline-block" }} variant="body1" color="text.primary" fontWeight={"bolder"}>
                    Loyalty Program A
                  </Typography>
                </Box>
                <Typography variant="caption" color="text.primary">
                  05 Feb 2023, 09:00AM
                </Typography>
              </Stack>
            </Stack>

            <Stack direction="row" alignItems="center" spacing={1}>
              <Stack alignItems={"flex-start"}>
                <Box sx={{ textAlign: "left" }}>
                  Employee B issued&nbsp;
                  <Typography sx={{ display: "inline-block" }} variant="body1" color="text.primary" fontWeight={"bolder"}>
                    X number of stamps
                  </Typography>
                  &nbsp;to USER B on&nbsp;
                  <Typography sx={{ display: "inline-block" }} variant="body1" color="text.primary" fontWeight={"bolder"}>
                    Loyalty Program A
                  </Typography>
                </Box>
                <Typography variant="caption" color="text.primary">
                  05 Feb 2023, 09:00AM
                </Typography>
              </Stack>
            </Stack>

            <Stack direction="row" alignItems="center" spacing={1}>
              <Stack alignItems={"flex-start"}>
                <Box sx={{ textAlign: "left" }}>
                  Employee B issued&nbsp;
                  <Typography sx={{ display: "inline-block" }} variant="body1" color="text.primary" fontWeight={"bolder"}>
                    X number of stamps
                  </Typography>
                  &nbsp;to USER B on&nbsp;
                  <Typography sx={{ display: "inline-block" }} variant="body1" color="text.primary" fontWeight={"bolder"}>
                    Loyalty Program A
                  </Typography>
                </Box>
                <Typography variant="caption" color="text.primary">
                  05 Feb 2023, 09:00AM
                </Typography>
              </Stack>
            </Stack>

            <Stack direction="row" alignItems="center" spacing={1}>
              <Stack alignItems={"flex-start"}>
                <Box sx={{ textAlign: "left" }}>
                  Employee A issued&nbsp;
                  <Typography sx={{ display: "inline-block" }} variant="body1" color="text.primary" fontWeight={"bolder"}>
                    X number of stamps
                  </Typography>
                  &nbsp;to USER B on&nbsp;
                  <Typography sx={{ display: "inline-block" }} variant="body1" color="text.primary" fontWeight={"bolder"}>
                    Loyalty Program A
                  </Typography>
                </Box>
                <Typography variant="caption" color="text.primary">
                  05 Feb 2023, 09:00AM
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Container>
      </CustomContainer>
    </>
  );
};

export default MerchantActivties;
