import { Container, Link, Stack, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import routes from "src/routes";
import MerchantLoyalty from "src/components/merchant/merchant-loyalty";
/* components */
/* 3rd party lib */
/* Util */
import chocolateCake from "src/images/chococake.jpg";

export const merchantLoyaltyListArr = [
  {
    id: 0,
    title: "Get 1 chocolate cake",
    expiredDate: "20230620",
    totalStars: 15,
    image: chocolateCake,
  },
  {
    id: 1,
    title: "Get 2 chocolate cake",
    expiredDate: "20230620",
    totalStars: 15,
    image: chocolateCake,
  },
  {
    id: 2,
    title: "Get 3 chocolate cake",
    expiredDate: "20230620",
    totalStars: 15,
    image: chocolateCake,
  },
  {
    id: 3,
    title: "Get 4 chocolate cake",
    expiredDate: "20230620",
    totalStars: 15,
    image: chocolateCake,
  },
  {
    id: 4,
    title: "Get 5 chocolate cake",
    expiredDate: "20230620",
    totalStars: 15,
    image: chocolateCake,
  },
  {
    id: 5,
    title: "Get 6 chocolate cake",
    expiredDate: "20230620",
    totalStars: 15,
    image: chocolateCake,
  },
];

interface MerchantLoyaltyListProps {}

type Props = MerchantLoyaltyListProps;

const MerchantLoyaltyList: React.FC<Props> = () => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const navigate = useNavigate();
  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <Container sx={{ pb: 10 }}>
      <Stack direction="row" justifyContent={"space-between"}>
        <Typography variant="body1" color="secondary.main" fontWeight={"bolder"}>
          Ongoing Loyalty Programs
        </Typography>
        <Link
          // href={routes.SIGNUP}
          variant="body1"
          fontWeight={"bolder"}
          color="text.primary"
          underline="hover"
          sx={{ cursor: "pointer", whiteSpace: "nowwrap", textDecoration: "underline" }}
          onClick={() => navigate(routes.MERCHANT_LOYALTY_PROGRAMS)}
        >
          View All
        </Link>
      </Stack>
      <Stack mt={2} spacing={2}>
        {merchantLoyaltyListArr.map((child, index) => (
          <MerchantLoyalty key={index} cardObj={child} />
        ))}
      </Stack>
    </Container>
  );
};

export default MerchantLoyaltyList;
