import React from "react";
/* components */
import blue from "src/images/blue.png";
import qrCode from "src/images/qrCode.png";
import TopNavbar from "src/components/common/navigation/top-navbar";
import BottomNavbar from "src/components/common/navigation/bottom-navbar";
import CustomContainer from "src/components/common/data-display/custom-container";
import LoyaltyCardsList from "src/components/common/data-display/loyalty-cards-list";
/* 3rd party lib */
import { Box, Stack, Typography, useTheme } from "@mui/material";
/* Util */
interface HomePageProps {}

type Props = HomePageProps;

const HomePage: React.FC<Props> = () => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const theme = useTheme();

  const expandStars = (delay: number) => {
    return {
      position: "absolute",
      animation: `starExpands 1.5s linear infinite ${delay}s`,
      "@keyframes starExpands": {
        "0%": {
          transform: "scale(0.5)",
        },
        "50%": {
          transform: "scale(1)",
        },
        "100%": {
          transform: "scale(0.5)",
        },
      },
    };
  };

  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <CustomContainer>
      <TopNavbar />
      <Stack
        sx={{
          width: "420px",
          background: theme.palette.primary.light,
          // borderRadius: "50%",
          padding: "0 50px 0px 50px",
          clipPath: "circle(95% at 50% 0)",
        }}
        spacing={2}
        alignItems="center"
        justifyContent={"flex-start"}
      >
        <Stack direction="row">
          <Typography variant="h5" color="text.primary" fontWeight={"light"}>
            Welcome,&nbsp;
          </Typography>
          <Typography variant="h5" color="text.primary" fontWeight={"bolder"}>
            Henry
          </Typography>
        </Stack>
        <Box sx={{ position: "relative", pt: 1 }}>
          <Box
            sx={{
              animation: `logoBounce 2s ease infinite`,
              "@keyframes logoBounce": {
                "0%": {
                  transform: "translateY(0)",
                },
                "50%": {
                  transform: "translateY(-3px)",
                },
                "100%": {
                  transform: "translateY(0)",
                },
              },
            }}
          >
            <img src={blue} alt="bluestar" width="160" />
          </Box>
          <Box
            sx={{
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              width: "80px",
              height: "10px",
              background: "rgba(0, 0, 0, 0.1)",
              borderRadius: "50%",
              animation: `shadowExpand 2s ease infinite `,
              "@keyframes shadowExpand": {
                "0%": {
                  width: "80px",
                },
                "50%": {
                  width: "77px",
                },
                "100%": {
                  width: "80px",
                },
              },
            }}
          />
          <Box sx={{ ...expandStars(0.5), left: "-10px", top: "50px" }}>
            <i className="fak fa-diamond" style={{ color: "white", width: "8px", height: "8px" }}></i>
          </Box>
          <Box sx={{ ...expandStars(1), left: "30px", top: "-20px" }}>
            <i className="fak fa-diamond" style={{ color: "white", width: "8px", height: "8px" }}></i>
          </Box>
          <Box sx={{ ...expandStars(0.2), right: "-40px", top: "50px" }}>
            <i className="fak fa-diamond" style={{ color: "white", width: "8px", height: "8px" }}></i>
          </Box>
          <Box sx={{ ...expandStars(0.5), left: "-80px", bottom: "-20px" }}>
            <i className="fak fa-blink" style={{ color: "white", width: "30px", height: "30px" }}></i>
          </Box>
          <Box sx={{ ...expandStars(1.5), left: "0px", bottom: "10px" }}>
            <i className="fak fa-diamond" style={{ color: "white", width: "8px", height: "8px" }}></i>
          </Box>
          <Box sx={{ ...expandStars(2), right: "-30px", bottom: "-20px" }}>
            <i className="fak fa-blink" style={{ color: "white", width: "20px", height: "20px" }}></i>
          </Box>
          <Box
            sx={{
              ...expandStars(0),
              right: "-60px",
              bottom: "-0px",
            }}
          >
            <i className="fak fa-blink" style={{ color: "white", width: "15px", height: "15px" }}></i>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: theme.palette.primary.main,
            padding: "4px",
            width: "140px",
            color: "white",
            borderRadius: "16px",
          }}
        >
          <Typography variant="h6" fontWeight={"bolder"}>
            BRONZE
          </Typography>
        </Box>
        <Box>
          <Stack direction="row" justifyContent={"center"}>
            <Typography variant="h6" color="primary.dark" fontWeight={"bolder"}>
              SGD 2,000&nbsp;
            </Typography>
            <Typography variant="h6" color="text.primary">
              more
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent={"center"}>
            <Typography variant="h6" color="text.primary">
              to reach
            </Typography>
            <Typography variant="h6" color="primary.dark" fontWeight={"bolder"}>
              &nbsp;SILVER&nbsp;
            </Typography>
            <Typography variant="h6" color="text.primary">
              tier
            </Typography>
          </Stack>
        </Box>
        <Box sx={{ pb: "40px" }}>
          <Box sx={{ background: "white", borderRadius: "16px" }}>
            <img src={qrCode} alt="qrCode" width="140" />
          </Box>
        </Box>
      </Stack>
      <Box sx={{ width: "100%", height: "50px" }}>
        <LoyaltyCardsList />
      </Box>
      <BottomNavbar />
    </CustomContainer>
  );
};

export default HomePage;
