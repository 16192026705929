import React, { useEffect, useRef, useState } from "react";
/* components */
import TopNavbar from "src/components/common/navigation/top-navbar";
import CustomContainer from "src/components/common/data-display/custom-container";
/* 3rd party lib */
import { Button, Box, Container, Grid, Stack, Tab, Tabs, Typography, useTheme, styled, Card, CardContent, LinearProgress } from "@mui/material";
/* Util */
import WataaLogo from "src/images/wataa.png";
import Stamp, { IStampStatus } from "src/components/loyalty/stamp";
import useMediaQueries from "src/hooks/use-mediaqueries";
import LoyaltyCardRewards from "src/components/loyalty/loyalty-card-rewards";
import LoyaltyCardDetails from "src/components/loyalty/loyalty-card-details";
import LoyaltyCardHistory from "src/components/loyalty/loyalty-card-history";
import ClaimDialog from "src/components/common/feedback/claim-dialog";
import { totalCards } from "src/components/common/data-display/loyalty-cards-list";
import { useParams } from "react-router-dom";

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: "10px",
  borderRadius: "50px",
  background: theme.palette.primary.light,
  "& .MuiLinearProgress-bar": {
    borderRadius: "50px",
  },
}));

const TabPanel = ({ value, index, children }: { value: number; index: number; children: React.ReactNode }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`workorder-tabpanel-${index}`}
      aria-labelledby={`workorder-tab-${index}`}
      style={{ flexGrow: "1" }}
    >
      {value === index && children}
    </div>
  );
};

const tabProps = (index: number) => ({
  id: `workorder-tab-${index}`,
  "aria-controls": `workorder-tabpanel-${index}`,
});

interface CardDetailsProps {}

type Props = CardDetailsProps;

const CardDetails: React.FC<Props> = () => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const theme = useTheme();
  const divRef = useRef(null);
  const { smDown } = useMediaQueries();
  const [tab, setTab] = useState(0);
  const [height, setHeight] = useState(0);
  const [open, setOpen] = useState(false);
  let { id } = useParams();

  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */
  useEffect(() => {
    if (divRef.current) {
      setHeight((divRef.current as any).clientHeight);
    }
  }, []);

  /* ================================================== */
  /* ================================================== */
  return (
    <>
      {/* <Global
        styles={{
          ".MuiTabs-flexContainer > .custom_tab.Mui-selected": {
            color: "white",
          },
          ".MuiTabs-flexContainer": {
            "& .custom_tab": {
              padding: "0 20px",
              // position: "relative",
              // background: "black",
              // zIndex: -2,
            },
          },
          ".MuiTabs-flexContainer + .MuiTabs-indicator": {
            background: mainBlue,
            height: "100%",
            zIndex: -1,
            borderRadius: "50px",
          },
          ".details__list": {
            marginTop: 0,
            marginBottom: 0,
            paddingInlineStart: "24px",
          },
        }}
      /> */}

      <CustomContainer>
        <TopNavbar back version="curve" />
        <Box sx={{ position: "absolute", top: 0 }}>
          <Stack
            sx={{
              paddingTop: "52px",
              width: smDown ? "100vw" : "420px",
              height: "200px",
              background: theme.palette.primary.light,
              // borderRadius: "50%",,
              clipPath: "circle(65% at 50% 0)",
              backgroundImage: `url(${!!id && totalCards[parseInt(id)].image})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
            spacing={2}
            alignItems="center"
            justifyContent={"flex-start"}
          ></Stack>

          <Box ref={divRef} sx={{ position: "absolute", bottom: `calc(-${height}px + 60px)`, left: "50%", transform: "translateX(-50%)" }}>
            <Box sx={{ position: "relative" }}>
              <Box sx={{ zIndex: 2, position: "absolute", top: "-60px", left: "50%", transform: "translateX(-50%)" }}>
                <img src={WataaLogo} width="120px" alt="wataaLogo" />
              </Box>

              <Card
                elevation={0}
                sx={{
                  background: "transparent",
                  py: 2,
                }}
              >
                <Box>
                  <CardContent>
                    <Box sx={{ width: smDown ? "80vw" : "300px", height: "auto" }} className="center">
                      <Stack pt={6}>
                        <Stack mb={1}>
                          <Typography
                            variant="h5"
                            fontWeight={"bolder"}
                            sx={
                              {
                                // "-webkit-text-stroke-width": "0.5px",
                                // "-webkit-text-stroke-color": "white",
                              }
                            }
                          >
                            {!!id && totalCards[parseInt(id)].title}
                          </Typography>
                          <Typography
                            variant="caption"
                            // fontWeight={"bolder"}
                            sx={
                              {
                                // "-webkit-text-stroke-width": "0.3px",
                                // "-webkit-text-stroke-color": "white",
                              }
                            }
                          >
                            Expired by 20 Jun 2023
                          </Typography>
                        </Stack>
                        <Grid container rowSpacing={1} sx={{ width: "100%", height: "100%" }}>
                          {id &&
                            Array(totalCards[parseInt(id)].totalStars)
                              .fill(0)
                              .map((_child, index) => {
                                if (id === undefined) return null;
                                let cardObj = totalCards[parseInt(id)];
                                let stampType: IStampStatus = "inactive_star";

                                // inactive star when index is greater than currently stamped position, and not last stamp, and also not gifts
                                if (index > cardObj.stampedAt - 1 && index !== cardObj.totalStars - 1 && !cardObj.gift.includes(index + 1)) {
                                  stampType = "inactive_star";
                                }
                                // active star when index is less than currently stamped position, and not last stamp, and also not gifts
                                if (index <= cardObj.stampedAt - 1 && index !== cardObj.totalStars - 1 && !cardObj.gift.includes(index + 1)) {
                                  stampType = "active_star";
                                }
                                // inactive gift when index is greater than currently stamped position, and the array includes the index
                                if (index > cardObj.stampedAt - 1 && cardObj.gift.includes(index + 1)) {
                                  stampType = "inactive_gift";
                                }
                                // active gift when index is less than currently stamped position, and the array includes the index
                                if (index <= cardObj.stampedAt - 1 && cardObj.gift.includes(index + 1)) {
                                  stampType = "active_gift";
                                }
                                if (cardObj.stampedAt - 1 < cardObj.totalStars - 1 && index === cardObj.totalStars - 1) {
                                  stampType = "inactive_trophy";
                                }
                                if (index === cardObj.stampedAt - 1 && index === cardObj.totalStars - 1) {
                                  stampType = "active_trophy";
                                }

                                return (
                                  <Grid key={index} item xs={12 / 5} className="center" sx={{ paddingLeft: 0 }}>
                                    <Stamp size={1} status={stampType} />
                                  </Grid>
                                );
                              })}
                        </Grid>
                        <Stack mt={2} direction="row" alignItems={"center"} justifyContent={"space-between"}>
                          {!!id && (
                            <StyledLinearProgress
                              variant="determinate"
                              value={(totalCards[parseInt(id)].stampedAt / totalCards[parseInt(id)].totalStars) * 100}
                              sx={{ width: "80%" }}
                            />
                          )}
                          <Stack>
                            <Typography variant="h6" fontWeight="light" lineHeight={0.8}>
                              <b>{!!id && totalCards[parseInt(id)].stampedAt}</b> / {!!id && totalCards[parseInt(id)].totalStars}
                            </Typography>
                            <Typography variant="caption">Stamped</Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Box>
                  </CardContent>
                  <Button
                    sx={{ marginBottom: "8px" }}
                    variant="contained"
                    onClick={() => {
                      setOpen(true);
                    }}
                    size="small"
                    disabled={!!id && totalCards[parseInt(id)].stampedAt === totalCards[parseInt(id)].totalStars}
                  >
                    {!!id && totalCards[parseInt(id)].stampedAt === totalCards[parseInt(id)].totalStars ? "Completed" : "Claim Stamps"}
                  </Button>
                </Box>
              </Card>

              <Container sx={{ position: "absolute", pb: "24px" }}>
                <Tabs centered value={tab} onChange={(event: React.SyntheticEvent<Element, Event>, tabValue: number) => setTab(tabValue)}>
                  <Tab
                    // className="custom_tab"
                    label={
                      <Stack spacing={1} direction="row" alignItems={"center"}>
                        Rewards
                      </Stack>
                    }
                    {...tabProps(0)}
                  />
                  <Tab
                    // className="custom_tab"
                    label={
                      <Stack spacing={1} direction="row" alignItems={"center"}>
                        Details
                      </Stack>
                    }
                    {...tabProps(1)}
                  />
                  <Tab
                    // className="custom_tab"
                    label={
                      <Stack spacing={1} direction="row" alignItems={"center"}>
                        History
                      </Stack>
                    }
                    {...tabProps(2)}
                  />
                </Tabs>
                <TabPanel value={tab} index={0}>
                  <Stack mt={2} spacing={2}>
                    <LoyaltyCardRewards />
                  </Stack>
                </TabPanel>
                <TabPanel value={tab} index={1}>
                  <Stack mt={2} spacing={2}>
                    <LoyaltyCardDetails />
                  </Stack>
                </TabPanel>
                <TabPanel value={tab} index={2}>
                  <Stack mt={2} spacing={2}>
                    <LoyaltyCardHistory />
                  </Stack>
                </TabPanel>
              </Container>
            </Box>
          </Box>
        </Box>
      </CustomContainer>
      <ClaimDialog open={open} onClose={() => setOpen(false)} title={"Get 1 chocolate cake"} logo={WataaLogo} />
    </>
  );
};

export default CardDetails;
