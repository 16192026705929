import React from "react";
/* components */
import TopNavbar from "src/components/common/navigation/top-navbar";
import BottomNavbar from "src/components/common/navigation/bottom-navbar";
import CustomContainer from "src/components/common/data-display/custom-container";
/* 3rd party lib */
import { QrReader } from "react-qr-reader";
import { Box, useTheme } from "@mui/material";
import { Global } from "@emotion/react";
/* Util */
interface ScanProps {}

type Props = ScanProps;

const Scan: React.FC<Props> = () => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const theme = useTheme();
  const handleQrScan = async (result: any) => {
    if (!result) return;
    // where's there a result, open the asset dialog
    alert(result);

    // const asset = await fetchAssetByBarcode(result);
  };

  //   const fetchAssetByBarcode = async barcode => {
  //     const { data } = await http.get(apiEndpoint.ASSETS, { params: { barcode } });
  //     const { id, name } = data.results[0];

  //     return { id, name };
  //   };

  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */

  return (
    <>
      <Global
        styles={{
          ".qr-reader": {
            zIndex: 1,
            div: {
              paddingTop: 0,
              marginTop: "50px",
            },
          },
        }}
      ></Global>{" "}
      <CustomContainer>
        <Box sx={{ position: "relative", background: theme.palette.primary.light, height: "52px", width: "100%" }}>
          <TopNavbar />
        </Box>
        <Box sx={{ width: "100%", height: "calc(100vh - 102px)", position: "relative" }}>
          <QrReader
            constraints={{ facingMode: "environment", aspectRatio: 1 }}
            onResult={(result: any) => handleQrScan(result?.text)}
            containerStyle={{ overflow: "visible", height: "calc(100vh - 102px)", width: "100%" }}
            videoStyle={{ height: "calc(100vh - 102px)", width: "100%" }}
            videoContainerStyle={{
              height: "calc(100vh - 102px)",
              transform: "translateY(-50px)",
              width: "100%",
              paddingTop: 0,
            }}
            className="qr-reader"
          />
        </Box>
        <BottomNavbar />
      </CustomContainer>
    </>
  );
};

export default Scan;
