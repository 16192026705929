import routes from "src/routes";
import SimpleBar from "simplebar-react";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { closeSidebar, openSidebar, toggleSidebar } from "src/slices/layout";
import { Logo } from "src/components/common/logo";
import { getTranslateString } from "src/utils/translate";
import useMediaQueries from "src/hooks/use-mediaqueries";
import { NAVBAR_DASHBOARD } from "src/constants/translate-keys/common";
import { Box, darken, Drawer, Stack, Typography, useTheme } from "@mui/material";
import DashboardSidebarSection, { IDashboardItem } from "./dashboard-sidebar-section";
import { Scrollbar } from "src/components/common/layout/scrollbar";

/* components */
/* 3rd party lib */
/* Util */
interface dashboardSidebarProps {
  open: boolean;
}

type Props = dashboardSidebarProps;

const DashboardSidebar: React.FC<Props> = ({ open }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */

  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // const router = useRouter();
  const { lgUp } = useMediaQueries();
  const location = useLocation();

  const sections: { title: React.ReactNode; items: IDashboardItem[] }[] = [
    {
      title: <Logo />,
      items: [
        {
          permission_key: "DASHBBOARD",
          title: getTranslateString(t, NAVBAR_DASHBOARD),
          path: routes.DASHBOARD,
          // icon: <FontAwesomeIcon fixedWidth icon={isDayTime() ? faHouseDay : faHouseNight} size="lg" />,
        },
        {
          permission_key: "LOYALTY",
          // title: getTranslateString(t, SIDEBAR_MY_WORK_ORDERS, true, false),
          title: "Loyalty Program",
          path: routes.LOYALTY,
          // icon: <FontAwesomeIcon fixedWidth icon={faPersonCarryBox} size="lg" />,
        },
        {
          permission_key: "CONFIGURATION",
          // title: getTranslateString(t, SUBJECT_WORK_ORDER, true, false),
          title: "Configurations",
          path: routes.CONFIGURATION,
          // icon: <FontAwesomeIcon fixedWidth icon={faBriefcase} size="lg" />,
        },
        {
          permission_key: "MEMBER",
          // title: getTranslateString(t, SUBJECT_PROJECT, true, false),
          title: "Members",
          path: routes.MEMBER,
          // icon: <FontAwesomeIcon fixedWidth icon={faRectangleHistory} size="lg" />,
        },
      ],
    },
  ];

  const content = (
    <SimpleBar
    // sx={{
    //   height: "100%",
    //   "& .simplebar-content": {
    //     height: "100%",
    //   },
    // }}
    >
      <Stack justifyContent={"space-between"} sx={{ height: "100%" }}>
        {sections.map((section, index) => (
          <DashboardSidebarSection key={`dashboardSidebar__${index}`} path={location.pathname} {...section} />
        ))}
        {process.env.NEXT_PUBLIC_DEV === "true" && (
          <Box sx={{ px: 2 }}>
            <Typography color="white" variant="subtitle2" sx={{ wordBreak: "break-word" }}>
              Hostname: {window.location.hostname} (dev)
            </Typography>
          </Box>
        )}
      </Stack>
    </SimpleBar>
  );

  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */
  useEffect(() => {
    if (!lgUp) {
      dispatch(closeSidebar());
    } else {
      dispatch(openSidebar());
    }
  }, [lgUp]);
  /* ================================================== */
  /* ================================================== */

  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={() => dispatch(closeSidebar())}
      PaperProps={{
        sx: {
          backgroundImage: theme.palette.gradient.main,
          width: 280,
          py: 2,
          borderColor: darken(theme.palette.gradient_secondary.main, 0.1),
        },
      }}
      sx={{ zIndex: theme => theme.zIndex.appBar + 100 }}
      variant={lgUp ? "persistent" : "temporary"}
      // variant={"temporary"}
    >
      {content}
    </Drawer>
  );
};

export default DashboardSidebar;
