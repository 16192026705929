import React from "react";
/* components */
/* 3rd party lib */
import { Global } from "@emotion/react";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Dialog, DialogContent, IconButton, Stack, Typography, useTheme } from "@mui/material";
/* Util */
import qrCode from "src/images/qrCode.png";

interface ClaimDialogProps {
  title: string;
  logo: any;
  open: boolean;
  onClose: () => void;
}

type Props = ClaimDialogProps;

const ClaimDialog: React.FC<Props> = ({ title, logo, open, onClose }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const theme = useTheme();
  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <>
      <Global
        styles={{
          ".MuiPaper-root.MuiDialog-paper": {
            overflowY: "visible",
          },
        }}
      ></Global>
      <Dialog open={open} onClose={onClose} sx={{ overflow: "none" }}>
        <Box sx={{ position: "relative" }}>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              background: theme.palette.primary.main,
              borderRadius: "50%",
              position: "absolute",
              right: -16,
              top: -16,
              color: "white",
              "&:hover": {
                background: theme.palette.primary.dark,
              },
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent sx={{ py: 4 }}>
            <Stack className="center">
              <Typography variant="h5" color="primary.main" fontWeight="bolder">
                {title}
              </Typography>
              <Box sx={{ background: "white", borderRadius: "16px" }}>
                <img src={qrCode} alt="qrCode" width="200" />
              </Box>

              <Box sx={{ width: "80%", textAlign: "center", background: theme.palette.primary.light, borderRadius: "8px", padding: 1 }}>
                <Typography variant="caption">Show this QR Code to merchant / store to claim reward</Typography>
              </Box>

              <Button variant="contained" sx={{ px: 5, mt: 2 }} onClick={onClose}>
                Done
              </Button>
            </Stack>
          </DialogContent>
        </Box>
      </Dialog>
    </>
  );
};

export default ClaimDialog;
