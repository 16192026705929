import React from "react";
/* components */
/* 3rd party lib */
import useBreadcrumb from "src/hooks/use-breadcrumb";
import { Breadcrumbs as MuiBreadcrumbs, Link, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";

/* Util */
interface BreadcrumbsProps {}

type Props = BreadcrumbsProps;

const Breadcrumbs: React.FC<Props> = () => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const { paths } = useBreadcrumb();

  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <MuiBreadcrumbs aria-label="breadcrumb">
      {paths.map((path, index) =>
        index + 1 === paths.length ? (
          <Typography key={path.path} color="text.secondary">
            {path.title}
          </Typography>
        ) : (
          <NavLink key={path.path} to={path.url}>
            <Link underline="none" color="text.secondary">
              {path.title}
            </Link>
          </NavLink>
        )
      )}
    </MuiBreadcrumbs>
  );
};

export default Breadcrumbs;
