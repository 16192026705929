import { useEffect } from "react";
import PropTypes from "prop-types";
import routes from "src/routes";
import useAuth from "src/hooks/use-auth";
import { useNavigate } from "react-router-dom";

export const AuthGuard = ({ children, permissionKey }: { children: React.ReactNode; permissionKey: string }) => {
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(
    () => {
      if (auth === null) return;

      // if (!auth.isAuthenticated) {
      //   navigate(routes.LOGIN);
      // } else {
      //   if (auth.permissions && !auth.permissions[permissionKey]) {
      //     navigate(routes.DASHBOARD);
      //   }
      // }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [auth]
  );

  // If got here, it means that the redirect did not occur, and that tells us that the user is
  // authenticated / authorized.
  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};
