import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleSidebar, showNavbar /* setNavbarHeight */, setNavbarHeight } from "src/slices/layout";
import DashboardNavbarAccount from "src/components/dashboard/dashboard-navbar-account";
// import DashboardNavbarLocalization from "src/components/dashboard/dashboard-navbar-localization";
// import DashboardNavbarNotification from "src/components/dashboard/dashboard-navbar-notification";
import { AppBar, Box, IconButton, styled, Toolbar } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBarsSort } from "@fortawesome/pro-regular-svg-icons";
import _ from "lodash";
import { RootState } from "src/store";

interface RootProps {
  show?: string;
  navbarheight: number;
}

const DashboardNavbarRoot = styled(AppBar, { shouldForwardProp: props => props !== "show" && props !== "navbarheight" })<RootProps>(
  ({ theme, show, navbarheight }) => ({
    zIndex: theme.zIndex.appBar,
    backgroundImage: theme.palette.gradient.main,
    color: theme.palette.primary.contrastText,
    // transition: theme.transitions.create(["left", "width", "top"], {
    //   easing: theme.transitions.easing.easeInOut,
    //   duration: theme.transitions.duration.leavingScreen,
    // }),
    transition: `top 1s ease, left ${theme.transitions.duration.leavingScreen}ms ${theme.transitions.easing.easeInOut}, width ${theme.transitions.duration.leavingScreen}ms ${theme.transitions.easing.easeInOut}`,
    WebkitTransform: "translate3d(0, 0, 0)",
    [theme.breakpoints.down("lg")]: { top: show === "true" ? 0 : `-${navbarheight * 2}px` },
    [theme.breakpoints.up("lg")]: {
      backgroundColor: theme.palette.background.paper,
      // color: theme.palette.text.secondary,
      color: "white",
    },
    ...(theme.palette.mode === "light"
      ? {
          boxShadow: theme.shadows[3],
        }
      : {
          backgroundColor: theme.palette.background.paper,
          borderBottomColor: theme.palette.divider,
          borderBottomStyle: "solid",
          borderBottomWidth: 1,
          boxShadow: "none",
        }),
  })
);

const DashboardNavbar = ({ lgUp, sidebarOpen }: { lgUp: boolean; sidebarOpen: boolean }) => {
  const dispatch = useDispatch();
  const navbarRef = useRef<HTMLDivElement | null>(null);
  const { navbar } = useSelector((state: RootState) => state.layout);

  const [lastScrollY, setLastScrollY] = useState(0);

  const controlNavbar = () => {
    if (typeof window !== "undefined") {
      if (window.scrollY > lastScrollY && window.scrollY >= 120) {
        // if scroll down hide the navbar
        dispatch(showNavbar(false));
      } else {
        // if scroll up show the navbar
        dispatch(showNavbar(true));
      }
      // remember current page location to use in the next move
      setLastScrollY(window.scrollY);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", controlNavbar);

      // cleanup function
      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }
  }, [lastScrollY]);

  useEffect(() => {
    if (navbarRef.current) {
      dispatch(setNavbarHeight(navbarRef.current.clientHeight));
    }
  }, []);

  return (
    <DashboardNavbarRoot
      show={navbar.show.toString()}
      navbarheight={navbar.height}
      ref={navbarRef}
      {...(lgUp && {
        sx: {
          left: sidebarOpen ? 280 : 0,
          width: sidebarOpen ? "calc(100% - 280px)" : "100%",
        },
      })}
    >
      <Toolbar disableGutters sx={{ height: 64, gap: 1, left: 0, px: 2 }}>
        <IconButton onClick={() => dispatch(toggleSidebar())} color="inherit">
          <FontAwesomeIcon icon={faBarsSort} size="sm" />
        </IconButton>
        <Box id="page-title" sx={{ flexGrow: 1 }}>
          {/* {!lgUp && (
            <Typography color="common.white" variant="body2" fontWeight={500}>
              {navbar?.title || ""}
            </Typography>
          )} */}
        </Box>
        {/* <DashboardNavbarNotification />
        <DashboardNavbarLocalization lgUp={lgUp} /> */}
        <DashboardNavbarAccount />
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

export default DashboardNavbar;
