import { useCallback, useEffect, useMemo, useState } from "react";
import useData from "src/hooks/use-data";
import useMounted from "src/hooks/use-mounted";
import { DATE_DISPLAY_FORMAT } from "src/constants";
import { getSortParams } from "src/utils/sort";
import SearchBar from "../inputs/search-bar";
import FilterDialog from "src/components/common/feedback/filter-dialog";
import FilterDrawer from "src/components/common/navigation/filter-drawer";
import Table, { TRowAction, TRowActions } from "src/components/common/data-display/table";
import useSWR from "swr";
import _ from "lodash";
import { useFormik } from "formik";
import {
  Badge,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import List from "src/components/common/data-display/list";
import { BUTTON_FILTER } from "src/constants/translate-keys/common";
import { useTranslation } from "react-i18next";
import { getTranslateString } from "src/utils/translate";
import SpinnerComponent from "src/components/common/feedback/spinner";
import useMediaQueries from "src/hooks/use-mediaqueries";

/**
 * Fetches data and display in table and list view.
 */

interface DataTableListProps {
  url: string;
  search: { key: string; label: string };
  columns: any;
  rowAction: TRowAction;
  rowActions: TRowActions;
  customFilters?: any;
  listMetadata?: any;
  inputRef?: any;
  dataTableListRef?: any;
  rowClick: boolean;
  filters?: any;
  filterType?: "drawer" | "dialog";
}

type Props = DataTableListProps;

const DataTableList: React.FC<Props> = props => {
  const {
    url,
    search,
    columns,
    rowAction,
    rowActions,
    customFilters,
    listMetadata,
    dataTableListRef,
    rowClick = false,
    filters: tableFilters,
    filterType = "dialog",
  } = props;

  const { t } = useTranslation();
  const { count, data, dispatchData, filters, limit, page, sort, totalPage } = useData();

  const [filterOpen, setFilterOpen] = useState(false);
  const isMounted = useMounted();
  const config = useMemo(
    () => ({ params: { ...filters, ordering: getSortParams(sort), page, ...customFilters } }),
    [customFilters, filters, page, sort]
  );

  const { data: apiData, mutate } = useSWR(isMounted ? [url, config] : null);

  const { lgUp } = useMediaQueries();

  useEffect(() => {
    if (apiData) {
      const { count, limit, results, total_pages } = apiData;

      dispatchData({
        type: "SET",
        payload: { count, data: results, limit, totalPage: total_pages },
      });
    }
  }, [apiData]);

  // Reset page to 1 whenever there's view change
  useEffect(() => {
    dispatchData({ type: "SET_PAGE", payload: 1 });
  }, [lgUp]);

  if (dataTableListRef) {
    dataTableListRef.current = {
      refreshData: mutate,
    };
  }

  const handleSearch = useCallback((value: string) => dispatchData({ type: "SET_FILTERS", payload: { [search?.key]: value } }), [search?.key]);

  return (
    <>
      {data ? (
        <Paper>
          {(search || !_.isEmpty(tableFilters)) && (
            <>
              <Box padding={2}>
                <Grid alignItems="center" container spacing={2}>
                  {search && (
                    <Grid item xs>
                      <SearchBar onSearch={handleSearch} placeholder={search?.label || "Search"} />
                    </Grid>
                  )}
                  {!_.isEmpty(tableFilters) && (
                    <Grid item xs="auto">
                      <Badge color="error" invisible={!filters} variant="dot">
                        <Button size="small" startIcon={<TuneRoundedIcon />} onClick={() => setFilterOpen(true)}>
                          {getTranslateString(t, BUTTON_FILTER)}
                        </Button>
                      </Badge>
                    </Grid>
                  )}
                </Grid>
              </Box>
              <Divider />
            </>
          )}
          {lgUp ? (
            <Table
              columns={columns}
              count={count}
              data={data}
              onPageChange={(page: number) => dispatchData({ type: "SET_PAGE", payload: page })}
              onSort={(key: string) => dispatchData({ type: "SET_SORT", payload: key })}
              page={page}
              rowAction={rowAction}
              rowActions={rowActions}
              rowsPerPage={limit}
              sort={sort}
            />
          ) : (
            <List
              data={data}
              filters={filters}
              rowClick={rowClick}
              metadata={listMetadata}
              rowActions={rowActions}
              onLoadMore={(page: number) => dispatchData({ type: "SET_PAGE", payload: page })}
              onSort={(key: string) => dispatchData({ type: "SET_SORT_WITH_ORDER", payload: key })}
              page={page}
              sort={sort}
              totalPage={totalPage}
            />
          )}
        </Paper>
      ) : (
        <Box sx={{ height: `calc(100vh - ${lgUp ? "280px" : "140px"})`, display: "grid", placeItems: "center" }}>
          <Box sx={{ transform: "translateY(-32px)" }}>
            <SpinnerComponent />
          </Box>
        </Box>
      )}
      {tableFilters && (
        <FilterComponent
          filters={tableFilters}
          onClose={() => setFilterOpen(false)}
          onRemoveFilter={(key: string) => dispatchData({ type: "REMOVE_FILTER", payload: key })}
          onResetFilters={() => dispatchData({ type: "RESET_FILTERS", payload: null })}
          onSetFilters={(values: { [key: string]: string }) => dispatchData({ type: "SET_FILTERS", payload: values })}
          open={filterOpen}
          type={!lgUp ? "dialog" : filterType}
        />
      )}
    </>
  );
};

export default DataTableList;

const FilterComponent = (props: any) => {
  const { t } = useTranslation();
  const { filters, onClose, onSetFilters, onRemoveFilter, onResetFilters, open, type } = props;

  const getInitialValues = () => {
    const setDefaultValue = (filter: any) => {
      switch (filter.type) {
        case "checkbox":
          return filter.options.reduce((acc: any, option: any) => ({ ...acc, [option.key]: false }), {});

        case "date":
          return null;

        case "dateRange":
          return { [filter.start.key]: null, [filter.end.key]: null };

        case "radio":
        case "select":
        case "textfield":
          return "";

        case "switch":
          return false;

        default:
          throw new Error("Invalid filter type.");
      }
    };

    return filters.reduce((acc: any, filter: any) => ({ ...acc, [filter.key]: setDefaultValue(filter) }), {});
  };

  const formik = useFormik({
    initialValues: getInitialValues(),
    onReset: () => {
      onResetFilters();
      onClose();
    },
    onSubmit: values => {
      onSetFilters(values);
      onClose();
    },
  });

  const renderComponentType = (type: "dialog" | "drawer") => {
    const props = {
      open,
      onClose,
      onFilter: formik.handleSubmit,
      onReset: formik.handleReset,
      children: renderFilterContent(filters),
    };

    switch (type) {
      case "dialog":
        return <FilterDialog {...props} />;
      case "drawer":
        return <FilterDrawer {...props} />;

      default:
        throw new Error("Invalid render type.");
    }
  };

  const renderFilterContent = (filters: any) => {
    const renderField = (filter: any) => {
      switch (filter.type) {
        case "checkbox":
          return (
            <>
              <FormLabel component="legend">{filter.label}</FormLabel>
              <FormGroup>
                {filter.options.map((option: any) => (
                  <FormControlLabel
                    key={option.key}
                    control={
                      <Checkbox
                        checked={formik.values[filter.key][option.key]}
                        onChange={e => formik.setFieldValue(`${filter.key}.${option.key}`, e.target.value)}
                      />
                    }
                    label={option.label}
                  />
                ))}
              </FormGroup>
            </>
          );

        case "date":
          return (
            <DatePicker
              inputFormat={DATE_DISPLAY_FORMAT}
              label={filter.label}
              onChange={value => formik.setFieldValue(filter.key, value)}
              renderInput={params => <TextField size="small" variant="filled" {...params} />}
              value={formik.values[filter.key]}
            />
          );

        case "dateRange":
          return (
            <Grid container spacing={2}>
              <Grid item xs={12} sm>
                <DatePicker
                  inputFormat={DATE_DISPLAY_FORMAT}
                  label={filter.start.label}
                  onChange={value => formik.setFieldValue(filter.start.key, value)}
                  renderInput={params => <TextField size="small" variant="filled" {...params} />}
                  value={formik.values[filter.start.key]}
                />
              </Grid>
              <Grid item xs={12} sm="auto">
                <Typography variant="body2" color="textSecondary" textAlign="center">
                  to
                </Typography>
              </Grid>
              <Grid item xs={12} sm>
                <DatePicker
                  inputFormat={DATE_DISPLAY_FORMAT}
                  label={filter.end.label}
                  onChange={value => formik.setFieldValue(filter.end.key, value)}
                  renderInput={params => <TextField size="small" variant="filled" {...params} />}
                  value={formik.values[filter.end.key]}
                />
              </Grid>
            </Grid>
          );

        case "radio":
          return (
            <>
              <FormLabel>{filter.label}</FormLabel>
              <RadioGroup value={formik.values[filter.key]}>
                {filter.options.map((option: any) => (
                  <FormControlLabel key={option.value} value={option.value} control={<Radio />} label={option.label} />
                ))}
              </RadioGroup>
            </>
          );

        case "select":
          return (
            <TextField
              label={filter.label}
              onChange={e => formik.setFieldValue(filter.key, e.target.value)}
              select
              SelectProps={{
                displayEmpty: true,
                MenuProps: {
                  MenuListProps: {
                    dense: true,
                    disablePadding: true,
                  },
                },
                native: true,
              }}
              size="small"
              value={formik.values[filter.key]}
              variant="filled"
            >
              <option value="">All</option>
              {filter.options.map((option: any) => (
                <option key={option.key} value={option.key}>
                  {option.label}
                </option>
              ))}
            </TextField>
          );

        case "switch":
          return (
            <FormGroup row>
              <FormControlLabel
                control={<Switch color="secondary" />}
                checked={formik.values[filter.key]}
                onChange={(_event, checked) => formik.setFieldValue(filter.key, checked)}
                labelPlacement="start"
                label={filter.label}
              />
            </FormGroup>
          );

        case "textfield":
          return (
            <TextField
              variant="filled"
              name={filter.key}
              label={filter.label}
              value={formik.values[filter.key]}
              onChange={formik.handleChange}
              size="small"
              InputLabelProps={{ shrink: true }}
              {...filter.fieldProps}
            />
          );

        default:
          throw new Error("Invalid field type.");
      }
    };

    return (
      <Grid container spacing={2}>
        {filters.map((filter: any, index: number) => (
          <Grid key={index} item xs={12}>
            <FormControl fullWidth>{renderField(filter)}</FormControl>
          </Grid>
        ))}
      </Grid>
    );
  };

  return renderComponentType(type);
};
