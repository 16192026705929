import { FormEvent } from "react";
import { useTranslation } from "react-i18next";
import ClearIcon from "@mui/icons-material/Clear";
import { getTranslateString } from "src/utils/translate";
import { BUTTON_FILTER, BUTTON_RESET, FILTER } from "src/constants/translate-keys/common";
import { Box, Button, Divider, Drawer, IconButton, Stack, Typography, styled } from "@mui/material";
import useMediaQueries from "src/hooks/use-mediaqueries";

interface RootProps {
  smDown: boolean;
}

const StyledDrawer = styled(Drawer, { shouldForwardProp: props => props !== "smDown" })<RootProps>(({ smDown }) => ({
  "& .MuiDrawer-paperAnchorRight": {
    width: smDown ? "80%" : "auto",
  },
}));

interface FilterDrawerProps {
  open: boolean;
  onClose: () => void;
  onReset: (e: any) => void;
  onFilter: (e?: FormEvent<HTMLFormElement> | undefined) => void;
  children: React.ReactNode;
}

type Props = FilterDrawerProps;

const FilterDrawer: React.FC<Props> = ({ open, onClose, onReset, onFilter, children }) => {
  const { smDown } = useMediaQueries();
  const { t } = useTranslation();
  return (
    <StyledDrawer anchor="right" open={open} onClose={onClose} smDown={smDown}>
      <form onSubmit={onFilter} style={{ height: "100%" }}>
        <Box
          sx={{
            minWidth: smDown ? "100%" : 400,
            maxWidth: 500,
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", px: 3, py: 2 }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h6">{getTranslateString(t, FILTER)}</Typography>
            </Box>
            <IconButton onClick={onClose}>
              <ClearIcon fontSize="small" />
            </IconButton>
          </Box>
          <Divider />
          <Box sx={{ p: 3, flexGrow: 1, overflow: "auto" }}>{children}</Box>
          <Box
            sx={{
              backgroundColor: "#ffffff",
              width: "100%",
              right: 0,
              bottom: 0,
              zIndex: 2,
            }}
          >
            <Divider />
            <Stack direction="column" spacing={2} sx={{ px: 3, py: 2 }}>
              <Button variant="outlined" fullWidth onClick={onReset}>
                {getTranslateString(t, BUTTON_RESET)}
              </Button>
              <Button type="submit" variant="contained" fullWidth>
                {getTranslateString(t, BUTTON_FILTER)}
              </Button>
            </Stack>
          </Box>
        </Box>
      </form>
    </StyledDrawer>
  );
};

export default FilterDrawer;
