import { useState } from "react";
import { Scrollbar } from "src/components/common/layout/scrollbar";
import NoDataFound from "src/components/common/feedback/no-data-found";
import _ from "lodash";
import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  styled,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/pro-regular-svg-icons";
import { NavLink } from "react-router-dom";

const StyledTableHeadCell = styled(TableCell)(() => ({
  "&:hover": {
    ".MuiIconButton-root": {
      visibility: "visible",
    },
  },
}));

const LoadingRow = ({ colSpan }: { colSpan: any }) => {
  return (
    <>
      {[...Array(10)].map((_, rowIndex) => (
        <TableRow key={rowIndex}>
          {[...Array(colSpan)].map((_, cellIndex) => (
            <TableCell key={cellIndex}>
              <Skeleton variant="text" />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

const EmptyRow = ({ colSpan }: { colSpan: any }) => {
  return (
    <TableRow sx={{ height: "calc(100vh - 300px)" }}>
      <TableCell colSpan={colSpan}>
        <NoDataFound image />
      </TableCell>
    </TableRow>
  );
};

export type TRowAction = (rowData: any) => void;
export type TRowActions = {
  icon: IconDefinition;
  label: string;
  tooltip: () => string;
  onClick?: (rowData: any) => void;
  navigation?: { url: (rowData: any) => string };
}[];

interface TableProps {
  columns: any;
  count: number;
  data: any;
  onSort: any;
  page: number;
  sort: any;
  onSelect?: any;
  rowAction: any;
  selected?: any[];
  loading?: any;
  onPageChange: any;
  rowsPerPage?: number;
  tableBodyProps?: any;
  tableHeadProps?: any;
  checkboxSelection?: boolean;
  dataIndicator?: string;
  rowActions?: TRowActions;
}

type Props = TableProps;

const Table: React.FC<Props> = props => {
  const {
    checkboxSelection,
    columns,
    count,
    data,
    dataIndicator = "id",
    loading,
    onPageChange,
    onSelect,
    onSort,
    page,
    rowActions = [],
    rowsPerPage = 25,
    selected = [],
    sort,
    tableBodyProps,
    tableHeadProps,
    rowAction,
  } = props;

  const [selectedRows, setSelectedRows] = useState(selected);
  const totalColumns = columns.length + (checkboxSelection ? 1 : 0);

  const handleSelectRow = (data: any) => {
    if (checkboxSelection) {
      const clonedSelectedRows = [...selectedRows];
      const index = clonedSelectedRows.findIndex(row => row[dataIndicator] === data[dataIndicator]);

      if (index !== -1) {
        clonedSelectedRows.splice(index, 1);
      } else {
        clonedSelectedRows.push(data);
      }

      setSelectedRows(clonedSelectedRows);
      onSelect(clonedSelectedRows);
    } else {
      onSelect(data);
    }
  };

  return (
    <div>
      <TableContainer>
        <Scrollbar>
          <MuiTable>
            <TableHead>
              <TableRow {...tableHeadProps?.row}>
                {checkboxSelection && <TableCell />}
                {columns.map((cell: any, index: number) => {
                  const props = _.merge({}, tableHeadProps?.cell, cell?.props);

                  return (
                    <StyledTableHeadCell key={index} {...props}>
                      {onSort && cell?.sortKey ? (
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography variant="inherit">{cell.label}</Typography>
                          <IconButton
                            size="small"
                            onClick={() => onSort(cell.sortKey)}
                            sx={{
                              visibility: sort?.[cell.sortKey] ? "visible" : "hidden",
                            }}
                          >
                            {sort?.[cell.sortKey] ? (
                              sort[cell.sortKey] === "asc" ? (
                                <ArrowUpwardRoundedIcon fontSize="small" />
                              ) : (
                                <ArrowDownwardRoundedIcon fontSize="small" />
                              )
                            ) : (
                              <ArrowUpwardRoundedIcon fontSize="small" />
                            )}
                          </IconButton>
                        </Stack>
                      ) : (
                        cell.label
                      )}
                    </StyledTableHeadCell>
                  );
                })}
                {rowActions && !_.isEmpty(rowActions) && <TableCell align="right" />}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <LoadingRow colSpan={totalColumns} />
              ) : data === null ? (
                <LoadingRow colSpan={totalColumns} />
              ) : (
                <>
                  {data.length === 0 ? (
                    <EmptyRow colSpan={totalColumns + (!_.isEmpty(rowActions) ? 1 : 0)} />
                  ) : (
                    data.map((d: any, rowIndex: number) => {
                      const selected = selectedRows.findIndex(row => row[dataIndicator] === d[dataIndicator]) !== -1;
                      const rowProps = tableBodyProps?.row(d);
                      const rowSxProps = _.merge(rowProps?.sx, {
                        cursor: checkboxSelection || onSelect ? "pointer" : "default",
                      });

                      return (
                        <TableRow
                          hover
                          key={rowIndex}
                          selected={selected}
                          onClick={() => {
                            if (checkboxSelection || onSelect) {
                              handleSelectRow(d);
                            }
                            if (rowAction) {
                              rowAction(d);
                            }
                          }}
                          sx={{ cursor: rowAction ? "pointer" : "default" }}
                          {...rowProps}
                          {...rowSxProps}
                        >
                          {checkboxSelection && (
                            <TableCell padding="checkbox">
                              <Checkbox size="small" checked={selected} />
                            </TableCell>
                          )}
                          {columns.map((cell: any, cellIndex: number) => (
                            <TableCell
                              key={cell.path ? `${cell.path}-${cellIndex}` : `${rowIndex}-${cellIndex}`}
                              {...tableBodyProps?.cell}
                              {...cell.props}
                              sx={_.merge(tableBodyProps?.cell?.sx, cell.props?.sx)}
                            >
                              {cell.content ? cell.content(d) : _.get(d, cell.path)}
                            </TableCell>
                          ))}
                          {rowActions && !_.isEmpty(rowActions) && (
                            <TableCell align="right">
                              <Grid container flexWrap="nowrap" justifyContent="flex-end" spacing={1}>
                                {rowActions.map((action, index) => {
                                  const content = action.navigation ? (
                                    <NavLink to={action.navigation.url(d)}>
                                      <IconButton component="a" onClick={e => e.stopPropagation()}>
                                        <FontAwesomeIcon icon={action.icon} size="xs" />
                                      </IconButton>
                                    </NavLink>
                                  ) : (
                                    <IconButton
                                      onClick={e => {
                                        e.stopPropagation();
                                        if (!!action.onClick) {
                                          action.onClick(d);
                                        }
                                      }}
                                    >
                                      <FontAwesomeIcon icon={action.icon} size="xs" />
                                    </IconButton>
                                  );

                                  return (
                                    <Grid key={index} item xs="auto">
                                      {action.tooltip ? (
                                        <Tooltip arrow title={!!action.tooltip && action.tooltip()}>
                                          <span>{content}</span>
                                        </Tooltip>
                                      ) : (
                                        content
                                      )}
                                    </Grid>
                                  );
                                })}
                              </Grid>
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })
                  )}
                </>
              )}
            </TableBody>
          </MuiTable>
        </Scrollbar>
      </TableContainer>
      {data && data.length > 0 && onPageChange && (
        <TablePagination
          component="div"
          count={count}
          page={page - 1}
          labelDisplayedRows={({ from, to, count }) => {
            if (from === count) {
              // if its the last page with last single item, then show "no of no"
              return `${from} of ${count !== -1 ? count : `more than ${to}`}`;
            }
            return `${from} – ${to} of ${count !== -1 ? count : `more than ${to}`}`;
          }}
          rowsPerPageOptions={[]}
          rowsPerPage={rowsPerPage}
          onPageChange={(e, page) => onPageChange(page + 1)}
        />
      )}
    </div>
  );
};

export default Table;
