// import "simplebar/dist/simplebar.min.css";
import { forwardRef } from "react";
import SimpleBar from "simplebar-react";
import { styled } from "@mui/material/styles";

const ScrollbarRoot = styled(SimpleBar)``;

interface ScrollbarProps {
  ref?: any;
  children: React.ReactNode;
}

type Props = ScrollbarProps;

export const Scrollbar: React.FC<Props> = forwardRef(props => {
  return <ScrollbarRoot ref={props.ref}>{props.children}</ScrollbarRoot>;
});

Scrollbar.displayName = "Scrollbar";
