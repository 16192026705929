import { useEffect, useState } from "react";
import MobileViewListSortDialog from "src/components/common/feedback/mobile-view-list-sort-dialog";
import NoDataFound from "src/components/common/feedback/no-data-found";
import { useInView } from "react-intersection-observer";
import _ from "lodash";
import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  List as MuiList,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis } from "@fortawesome/pro-regular-svg-icons";
import { END_OF_LIST, SCROLL_MORE } from "src/constants/translate-keys/common";
import { getTranslateString } from "src/utils/translate";
import { useTranslation } from "react-i18next";
import useMediaQueries from "src/hooks/use-mediaqueries";
import { TRowActions } from "./table";
import { NavLink, useNavigate } from "react-router-dom";

export type TSortFields = { key: string; label: string }[];
export type TMetaDataFields = { path: string; label: string }[];

export interface IMetaData {
  content: (data: any) => React.ReactNode;
  primary?: { path: string; content: (data: any) => React.ReactNode };
  secondary?: { path: string; content: (data: any) => React.ReactNode };
  fields: TMetaDataFields;
  sortFields: TSortFields;
  rowActions: TRowActions;
}

interface ListProps {
  checkboxSelection?: boolean;
  data: any;
  dataIndicator?: string;
  metadata: IMetaData;
  onLoadMore: any;
  onSelect?: any;
  onSort: any;
  page: number;
  rowActions: TRowActions;
  selected?: any[];
  sort?: any;
  filters?: any;
  rowClick: boolean;
  totalPage: number;
  searchTerm?: string | null;
}

type Props = ListProps;

const List: React.FC<Props> = props => {
  const {
    checkboxSelection = false,
    data,
    dataIndicator = "id",
    metadata,
    onLoadMore,
    onSelect,
    onSort,
    page,
    rowActions = [],
    selected = [],
    sort,
    filters,
    rowClick,
    totalPage,
    searchTerm,
  } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { smDown, mdDown } = useMediaQueries();
  const { content: metaDataContent, fields = [], primary, secondary, sortFields } = metadata;
  const [selectedRows, setSelectedRows] = useState(selected);
  const [sortDialog, setSortDialog] = useState(false);
  const [localData, setLocalData] = useState<any[] | null>(null);
  const [moreActionsMenu, setMoreActionsMenu] = useState<{ anchorEl: (EventTarget & HTMLButtonElement) | null; rowData: any | null }>({
    anchorEl: null,
    rowData: null,
  });
  const { ref, inView } = useInView({ initialInView: false, threshold: 0 });
  const [previousFilter, setPreviousFilter] = useState(null);
  const [previousSearchTerm, setPreviousSearchTerm] = useState<string | null>(null);

  useEffect(() => {
    if (inView) {
      onLoadMore(page + 1);
    }
  }, [inView]);

  useEffect(() => {
    // when sort or filter, reset the lsit
    setLocalData(null);
  }, [sort]);

  useEffect(() => {
    if (data) {
      setLocalData(prevState => {
        if (!_.isEqual(filters, previousFilter) || !_.isEqual(searchTerm, previousSearchTerm)) {
          // return new array if filters is different from previous state
          setPreviousFilter(filters);
          if (searchTerm) {
            setPreviousSearchTerm(searchTerm);
          }
          return data;
        } else {
          if (prevState) {
            let uniqueArray = [...new Map([...prevState, ...data].map(item => [item["id"], item])).values()];
            return uniqueArray;
          } else {
            return data;
          }
        }
      });
    }
  }, [data]);

  const handleSelectRow = (data: any) => {
    if (checkboxSelection) {
      const clonedSelectedRows = [...selectedRows];
      const index = clonedSelectedRows.findIndex(row => row[dataIndicator] === data[dataIndicator]);

      if (index !== -1) {
        clonedSelectedRows.splice(index, 1);
      } else {
        clonedSelectedRows.push(data);
      }

      setSelectedRows(clonedSelectedRows);
      onSelect(clonedSelectedRows);
    } else {
      onSelect(data);
    }
  };

  const renderActions = (rowData: any) => {
    const renderSingleAction = (action: any) => {
      const content = action.navigation ? (
        <NavLink to={action.navigation.url(rowData)}>
          <IconButton component="a">
            <FontAwesomeIcon icon={action.icon} size="xs" />
          </IconButton>
        </NavLink>
      ) : (
        <IconButton onClick={() => action.onClick(rowData)} {...action.buttonProps}>
          <FontAwesomeIcon icon={action.icon} size="xs" />
        </IconButton>
      );

      return action.tooltip ? (
        <Tooltip arrow title={action.tooltip(rowData)}>
          <span>{content}</span>
        </Tooltip>
      ) : (
        content
      );
    };

    return rowActions.length > 1 ? (
      <IconButton onClick={e => setMoreActionsMenu({ anchorEl: e.currentTarget, rowData })}>
        <FontAwesomeIcon icon={faEllipsis} />
      </IconButton>
    ) : (
      renderSingleAction(rowActions[0])
    );
  };

  return (
    <>
      <MuiList disablePadding>
        {localData &&
          localData.map((d: any, index: number) => {
            const isSelected = selectedRows ? selectedRows.findIndex(row => row[dataIndicator] === d[dataIndicator]) !== -1 : false;
            const props: any = {
              key: d[dataIndicator] || `listitem_${index}`,
              divider: index + 1 !== localData.length,
              secondaryAction: !_.isEmpty(rowActions) && renderActions(d),
              selected: isSelected,
              sx: { minHeight: 64 },
            };
            const listContent = metaDataContent ? (
              metaDataContent(d)
            ) : (
              <>
                <Box sx={{ mr: 1 }}>
                  {primary && (
                    <Typography component="div" fontWeight={600} variant={smDown ? "body1" : "h6"}>
                      {primary.content ? primary.content(d) : _.get(d, primary.path)}
                    </Typography>
                  )}
                  {secondary && (
                    <Typography color="textSecondary" component="div" variant="caption" fontStyle="italic">
                      {secondary.content ? secondary.content(d) : _.get(d, secondary.path)}
                    </Typography>
                  )}
                </Box>
                {!_.isEmpty(fields) && (
                  <Paper
                    variant={mdDown ? "elevation" : "outlined"}
                    elevation={0}
                    sx={{ mt: mdDown ? 0 : 1, mr: 2 }}
                    onClick={() => {
                      if (rowClick) {
                        // allow user to click on the row in the list to redirect
                        if (rowActions && rowActions.length > 0) {
                          rowActions.forEach(child => {
                            if (Object.keys(child).includes("navigation")) {
                              if (!!child.navigation) {
                                navigate(child.navigation.url(d));
                              }
                            } else {
                              if (!!child.onClick) {
                                child.onClick(d);
                              }
                            }
                          });
                        }
                      }
                    }}
                  >
                    <Box pl={mdDown ? 0 : 2} pr={2} py={1}>
                      <Grid container spacing={mdDown ? 0.5 : 2}>
                        {fields.map((field: any, fieldIndex: number) => {
                          let fieldContent = null;
                          if (field.content) {
                            if (typeof field.content(d) === "string") {
                              fieldContent = field.content(d) !== "" ? field.content(d) : "-";
                            } else {
                              fieldContent = field.content(d);
                            }
                          } else {
                            fieldContent = _.get(d, field.path);
                          }
                          return (
                            <Grid key={field.id || fieldIndex} item xs={12} sm={12} md={4} {...field.gridProps}>
                              <Stack alignItems={mdDown ? "flex-start" : ""} direction={mdDown ? "row" : "column"} spacing={mdDown ? 1 : 0}>
                                <Typography
                                  color="textSecondary"
                                  component="div"
                                  fontWeight={600}
                                  variant={smDown ? "caption" : "body2"}
                                  sx={{ whiteSpace: "nowrap" }}
                                >
                                  {field.label} {mdDown && ":"}
                                </Typography>
                                <Typography component="div" fontWeight={500} variant={smDown ? "caption" : "body2"}>
                                  {fieldContent || "-"}
                                </Typography>
                              </Stack>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Box>
                  </Paper>
                )}
              </>
            );

            return checkboxSelection || onSelect ? (
              <ListItemButton onClick={() => handleSelectRow(d)} {...props}>
                {checkboxSelection && (
                  <ListItemIcon>
                    <Checkbox edge="start" checked={isSelected} disableRipple size="small" />
                  </ListItemIcon>
                )}
                {listContent}
              </ListItemButton>
            ) : (
              <ListItem {...props}>
                <ListItemText>{listContent}</ListItemText>
              </ListItem>
            );
          })}
      </MuiList>

      {data && (
        <>
          {data.length > 0 && page < totalPage ? (
            <Box pb={2} ref={ref}>
              <Typography variant="subtitle2" color="neutral.300" textAlign="center">
                {getTranslateString(t, SCROLL_MORE)}
              </Typography>
            </Box>
          ) : data.length === 0 ? (
            <Box padding={2}>
              <NoDataFound image />
            </Box>
          ) : (
            <Box padding={2}>
              <Typography variant="subtitle2" color="neutral.300" textAlign="center">
                {getTranslateString(t, END_OF_LIST)}
              </Typography>
            </Box>
          )}
        </>
      )}

      {sort !== undefined && !!sortFields && (
        <MobileViewListSortDialog open={sortDialog} onClose={() => setSortDialog(false)} sort={sort} sortFields={sortFields} onSort={onSort} />
      )}
      <MoreActionsMenu
        anchorEl={moreActionsMenu.anchorEl}
        onClose={() => setMoreActionsMenu({ anchorEl: null, rowData: null })}
        rowActions={rowActions}
        rowData={moreActionsMenu.rowData}
      />
    </>
  );
};

export default List;

const MoreActionsMenu = (props: any) => {
  const { onClose, anchorEl, rowActions, rowData } = props;
  const navigate = useNavigate();
  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      onClose={onClose}
      open={anchorEl !== null}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <MenuList disablePadding>
        {rowActions.map((action: any, index: number) => (
          <MenuItem
            divider={rowActions.length !== index + 1}
            key={index}
            onClick={() => {
              if (action.navigation) {
                navigate(action.navigation.url(rowData));
              } else {
                action.onClick(rowData);
              }
            }}
          >
            <ListItemIcon>
              <FontAwesomeIcon icon={action.icon} />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ fontWeight: 600, variant: "body2" }}>{action.label}</ListItemText>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};
