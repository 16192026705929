import React from "react";
/* components */
/* 3rd party lib */
import { Route } from "react-router-dom";
/* Util */

interface AdminRouteProps {
  component: any;
}

type Props = AdminRouteProps;

const AdminRoute: React.FC<Props> = ({ component: Component, ...rest }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  // if (isLoading) return <div>Loading...</div>;
  // if (!user) return <div>Not Authenticated</div>;
  let routeComponent = null;
  if (process.env.REACT_APP_IS_ADMIN !== "true") {
    routeComponent = <div>Need to be an admin to access this route</div>;
  } else {
    routeComponent = <Component {...rest} />;
  }

  return routeComponent;
};

export default AdminRoute;
