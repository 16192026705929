import React, { useState } from "react";
/* components */
import LoyaltyCard from "src/components/loyalty/loyalty-card";
import CustomContainer from "src/components/common/data-display/custom-container";
import TopNavbar from "src/components/common/navigation/top-navbar";
/* 3rd party lib */
import { Box, Container, Grid, Stack, Tab, Tabs, Typography, useTheme, Button } from "@mui/material";
/* Util */
import WataaLogo from "src/images/wataa.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/pro-thin-svg-icons";
import useMediaQueries from "src/hooks/use-mediaqueries";
import { Global } from "@emotion/react";
import { totalCards } from "src/components/common/data-display/loyalty-cards-list";
import { faLocationDot } from "@fortawesome/pro-regular-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import routes from "src/routes";

const TabPanel = ({ value, index, children }: { value: number; index: number; children: React.ReactNode }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`workorder-tabpanel-${index}`}
      aria-labelledby={`workorder-tab-${index}`}
      style={{ flexGrow: "1" }}
    >
      {value === index && children}
    </div>
  );
};

const tabProps = (index: number) => ({
  id: `workorder-tab-${index}`,
  "aria-controls": `workorder-tabpanel-${index}`,
});

interface StoreDetailsProps {}

type Props = StoreDetailsProps;

const StoreDetails: React.FC<Props> = () => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const theme = useTheme();
  const { smDown } = useMediaQueries();
  const [tab, setTab] = useState(0);
  const navigate = useNavigate();
  const { id } = useParams();
  const textGrey = theme.palette.text.primary;

  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <>
      <Global
        styles={{
          ".ellipsis": {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          },
        }}
      ></Global>
      <CustomContainer>
        <Box sx={{ position: "relative", background: theme.palette.primary.light, height: "52px", width: "100%" }}>
          <TopNavbar back />
        </Box>
        <Box sx={{ position: "relative" }}>
          <Stack
            sx={{
              minWidth: "300px",
              width: smDown ? "100%" : "420px",
              // height: "400px",
              background: theme.palette.primary.light,
              clipPath: "circle(82% at 50% 0)",
            }}
            spacing={2}
            alignItems="center"
            justifyContent={"flex-start"}
          >
            <Stack alignItems="center" spacing={1} justifyContent={"center"} sx={{ pb: 15 }}>
              <img src={WataaLogo} width="120px" alt="wataaLogo" />
              <Typography variant="h6" fontWeight="bolder" color="text.primary">
                WATAA BAKERY
              </Typography>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Stack sx={{ pl: 6 }}>
                    <FontAwesomeIcon
                      size="xl"
                      color={textGrey}
                      icon={faGlobe}
                      style={{
                        WebkitTextStroke: "0.5px white",
                      }}
                    />
                    <Typography variant="body2" className="ellipsis">
                      wataabakery.sg
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack sx={{ pr: 6 }}>
                    <Box>
                      <i className="fak fa-phone fa-xl" style={{ color: textGrey }}></i>
                    </Box>
                    <Typography variant="body2" className="ellipsis">
                      (+65) 6733 3387
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack sx={{ pl: 6 }}>
                    <Box>
                      <i className="fak fa-facebook fa-xl" style={{ color: textGrey }}></i>
                    </Box>
                    <Typography variant="body2" className="ellipsis">
                      wataabakery
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack sx={{ pr: 6 }}>
                    <Box>
                      <i className="fak fa-insta fa-xl" style={{ color: textGrey }}></i>
                    </Box>
                    <Typography variant="body2" className="ellipsis">
                      wataabakery
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    onClick={() => navigate(`${routes.STORES}/${id}/${routes.LOCATIONS}`)}
                    variant="contained"
                    sx={{ minWidth: "auto", borderRadius: "50%", width: "50px", height: "50px", marginBottom: "-30px" }}
                  >
                    <FontAwesomeIcon
                      size="xl"
                      icon={faLocationDot}
                      style={{
                        WebkitTextStroke: "0.5px white",
                      }}
                    />
                  </Button>
                </Grid>
              </Grid>
            </Stack>
          </Stack>
        </Box>
        <Container sx={{ marginTop: "-80px", mb: 2 }}>
          <Tabs centered value={tab} onChange={(event: React.SyntheticEvent<Element, Event>, tabValue: number) => setTab(tabValue)}>
            <Tab
              label={
                <Stack spacing={1} direction="row" alignItems={"center"}>
                  All
                </Stack>
              }
              {...tabProps(0)}
            />
            <Tab
              label={
                <Stack spacing={1} direction="row" alignItems={"center"}>
                  My Cards
                </Stack>
              }
              {...tabProps(1)}
            />
          </Tabs>
          <TabPanel value={tab} index={0}>
            <Stack mt={2} spacing={2}>
              {totalCards.map((child, index) => (
                <LoyaltyCard key={index} cardObj={child} />
              ))}
              {/* <LoyaltyCard image={chocolateCake} />
              <LoyaltyCard image={croissant} />
              <LoyaltyCard image={eyebrow} />
              <LoyaltyCard image={manicure} /> */}
            </Stack>
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <Stack mt={2} spacing={2}>
              <LoyaltyCard cardObj={totalCards[1]} />
            </Stack>
          </TabPanel>
        </Container>
      </CustomContainer>
    </>
  );
};

export default StoreDetails;
