import React from "react";
/* components */
/* 3rd party lib */
import { Box, useTheme } from "@mui/material";
/* Util */
import InactiveStar from "src/images/placeholder_star.png";
import InactiveGift from "src/images/placeholder_gift.png";
import InactiveTrophy from "src/images/placeholder_trophy.png";
import ActiveStar from "src/images/star.png";
import ActiveGift from "src/images/gift.png";
import ActiveTrophy from "src/images/trophy.png";

export type IStampStatus = "inactive_star" | "inactive_gift" | "inactive_trophy" | "active_star" | "active_gift" | "active_trophy";

interface StampProps {
  status: IStampStatus;
  size: number;
  newStamp?: boolean;
  onClick?: () => void;
}

type Props = StampProps;

const Stamp: React.FC<Props> = ({ status, size = 1, onClick, newStamp = false }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const theme = useTheme();
  const activeStarBg = "#FFF7DA";
  const activeGiftBg = "#FFF0F0";

  /* ================================================== */
  /*  method */
  /* ================================================== */

  const allStamps = [
    {
      active: false,
      multiplier: 35,
      status_key: "inactive_star",
      image: InactiveStar,
      background: (theme.palette.neutral as any)[300],
    },
    {
      active: false,
      multiplier: 30,
      status_key: "inactive_gift",
      image: InactiveGift,
      background: (theme.palette.neutral as any)[300],
    },
    {
      active: false,
      multiplier: 35,
      status_key: "inactive_trophy",
      image: InactiveTrophy,
      background: (theme.palette.neutral as any)[300],
    },
    {
      active: true,
      multiplier: 40,
      status_key: "active_star",
      image: ActiveStar,
      background: activeStarBg,
    },
    {
      active: true,
      multiplier: 35,
      status_key: "active_gift",
      image: ActiveGift,
      background: activeGiftBg,
    },
    {
      active: true,
      multiplier: 35,
      status_key: "active_trophy",
      image: ActiveTrophy,
      background: activeStarBg,
    },
  ];

  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <>
      {allStamps.map(child => (
        <React.Fragment key={child.status_key}>
          {status === child.status_key && (
            <StampIcon
              size={size}
              status={status}
              img={child.image}
              onClick={onClick}
              newStamp={newStamp}
              active={child.active}
              multiplier={child.multiplier}
              status_key={child.status_key}
              backgroundColor={child.background}
            />
          )}
        </React.Fragment>
      ))}
    </>
  );
};

export default Stamp;

interface StampIconProps {
  active: boolean;
  status: IStampStatus;
  size: number;
  newStamp?: boolean;
  status_key: string;
  img: any;
  backgroundColor: string;
  onClick?: () => void;
  multiplier: number;
}

const StampIcon: React.FC<StampIconProps> = ({ active, size = 1, multiplier, newStamp = false, img, status_key, backgroundColor, onClick }) => {
  const redDot = (
    <Box
      sx={{
        width: "5px",
        height: "5px",
        background: "#ff6b6b",
        borderRadius: "50%",
        top: "0px",
        right: "10px",
        position: "absolute",
      }}
    />
  );

  return (
    <>
      <Box
        className="center"
        onClick={() => {
          // only the new stamp can be clicked
          if (newStamp && !!onClick) {
            onClick();
          }
        }}
        sx={{
          position: "relative",
          // border: newStamp && active ? "2px solid #ff6b6b" : "2px solid transparent",
          height: `${50 * size}px`,
          width: `${50 * size}px`,
          background: backgroundColor,
          borderRadius: "50%",
        }}
      >
        {newStamp && active && redDot}
        <img src={img} width={`${multiplier * size}px`} alt={status_key} />
      </Box>
    </>
  );
};
