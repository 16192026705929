import React from "react";
/* components */
/* 3rd party lib */ import {
  Grid,
  Select,
  Stack,
  Button,
  Dialog,
  useTheme,
  IconButton,
  TextField,
  Typography,
  MenuItem,
  DialogTitle,
  FormControl,
  DialogActions,
  ListItemText,
  Checkbox,
  DialogContent,
  OutlinedInput,
  SelectChangeEvent,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk } from "@fortawesome/pro-light-svg-icons";
import { getTranslateString, getTranslateStringWithCustomSubject, getTranslateStringWithSubject } from "src/utils/translate";
import useMediaQueries from "src/hooks/use-mediaqueries";
import {
  ACTION_CREATE,
  ACTION_EDIT,
  BUTTON_CANCEL,
  BUTTON_SAVE,
  CREATE,
  FORM_DESCRIPTION,
  FORM_NAMING,
  FORM_WARNING_MIN_NUM,
  FORM_WARNING_REQUIRED,
  SUBJECT_LOYALTY,
} from "src/constants/translate-keys/common";
import { DatePicker } from "@mui/x-date-pickers";
import { DATE_SERVER_FORMAT } from "src/constants";
import moment from "moment";
import { faArrowRight, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { Box } from "@mui/system";
import Chip from "@mui/material/Chip";
import { ILoyaltyProgram } from "src/pages/admin/loyalty";
import useHttp from "src/hooks/use-http";
import useSWR from "swr";
import { IOutlet } from "src/pages/admin/configuration";
/* Util */
interface LoyaltyCrudDialogProps {
  open: boolean;
  onClose: () => void;
  crud: "create" | "update";
  loyalty?: ILoyaltyProgram | null;
}

type Props = LoyaltyCrudDialogProps;

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

interface ILoyaltyForm {
  name: string;
  description: string;
  slot: number;
  valid_start_date?: string;
  valid_end_date?: string;
  redeem_start_date?: string;
  redeem_end_date?: string;
  tns?: string;
  participating_outlets: string[];
}

const schema = (t: any) =>
  Yup.object().shape({
    name: Yup.string().required(getTranslateString(t, FORM_WARNING_REQUIRED)),
    slot: Yup.number()
      .min(0, getTranslateStringWithCustomSubject(t, FORM_WARNING_MIN_NUM, "0"))
      .required(getTranslateString(t, FORM_WARNING_REQUIRED)),
  });

const LoyaltyCrudDialog: React.FC<Props> = ({ crud, loyalty, open, onClose }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const { t } = useTranslation();
  const { smDown } = useMediaQueries();
  const { apiEndpoint, http } = useHttp();
  const { data: outlets } = useSWR(apiEndpoint.OUTLETS("2a903b85-0c17-4468-b9c5-fbaeacf1f401"));

  const formik = useFormik({
    validateOnChange: false,
    validationSchema: schema(t),
    enableReinitialize: true,
    initialValues:
      crud === "create"
        ? {
            name: "",
            description: "",
            slot: 1,
            valid_start_date: "",
            valid_end_date: "",
            redeem_start_date: "",
            redeem_end_date: "",
            participating_outlets: [],
          }
        : {
            name: loyalty ? loyalty.name : "",
            description: loyalty ? loyalty.description : "",
            slot: loyalty ? loyalty.slot : 1,
            valid_start_date: "",
            valid_end_date: "",
            redeem_start_date: "",
            redeem_end_date: "",
            tns: "",
            participating_outlets: [],
          },
    onSubmit: values => {
      const handleCreateLoyalty = async (formikValues: ILoyaltyForm) => {
        // remove unused var for now
        delete formikValues["valid_start_date"];
        delete formikValues["valid_end_date"];
        delete formikValues["redeem_start_date"];
        delete formikValues["redeem_end_date"];
        delete formikValues["tns"];

        const { data } = await http.post(apiEndpoint.LOYALTY_PROGRAMS("2a903b85-0c17-4468-b9c5-fbaeacf1f401"), JSON.stringify(formikValues));
      };
      const handleUpdateLoyalty = async (formikValues: typeof values) => {
        const { data } = await http.put(apiEndpoint.LOYALTY_PROGRAMS("2a903b85-0c17-4468-b9c5-fbaeacf1f401"), JSON.stringify(formikValues));
      };

      if (crud === "create") {
        handleCreateLoyalty(values);
      } else {
        handleUpdateLoyalty(values);
      }
    },
  });
  const { values } = formik;
  const theme = useTheme();
  /* ================================================== */
  /*  method */
  /* ================================================== */

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    formik.setFieldValue("participating_outlets", typeof value === "string" ? value.split(",") : value);
  };

  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */

  const DataInput = ({
    label,
    input,
    formikObj,
    alignCenter = true,
  }: {
    label: string;
    input: (formikObj: typeof formik) => React.ReactNode;
    alignCenter?: boolean;
    formikObj: typeof formik;
  }) => (
    <Grid container>
      <Grid item xs={4} sm={3} md={1} lg={3} sx={{ whiteSpace: "pre-line", display: "flex", alignItems: alignCenter ? "center" : "flex-start" }}>
        {typeof label === "string" ? (
          <Typography variant="body2" color={(theme.palette.neutral as any)[500]}>
            {label}
          </Typography>
        ) : (
          label
        )}
      </Grid>
      <Grid
        item
        xs={8}
        sm={9}
        md={11}
        lg={9}
        sx={{
          display: "flex",
          alignItems: alignCenter ? "center" : "flex-start",
        }}
      >
        {input(formikObj)}
      </Grid>
    </Grid>
  );

  return (
    <Dialog maxWidth="md" fullWidth open={open} onClose={onClose} fullScreen={smDown}>
      <DialogTitle>
        <Stack direction="row" spacing={3} alignItems="center">
          <Typography variant="inherit" sx={{ flexGrow: 1, pl: 2 }}>
            {crud === "create"
              ? getTranslateStringWithSubject(t, ACTION_CREATE, SUBJECT_LOYALTY, false, false)
              : getTranslateStringWithSubject(t, ACTION_EDIT, SUBJECT_LOYALTY, false, false)}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseRoundedIcon fontSize="small" />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <form id="loyaltyForm" onSubmit={formik.handleSubmit}>
          <Grid container spacing={1} sx={{ px: 2 }}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={4} sm={3} md={1} lg={3} sx={{ whiteSpace: "pre-line", display: "flex", alignItems: "center" }}>
                  <Typography variant="body2" color={(theme.palette.neutral as any)[500]}>
                    {getTranslateString(t, FORM_NAMING)}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={8}
                  sm={9}
                  md={11}
                  lg={9}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <FormControl fullWidth>
                    <TextField
                      hiddenLabel
                      variant="outlined"
                      name="name"
                      size="small"
                      placeholder={getTranslateString(t, FORM_NAMING)}
                      value={values.name}
                      onChange={formik.handleChange}
                      error={!!formik.errors.name}
                      helperText={formik.errors.name?.toString()}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={4} sm={3} md={1} lg={3} sx={{ whiteSpace: "pre-line", display: "flex", alignItems: "center" }}>
                  <Typography variant="body2" color={(theme.palette.neutral as any)[500]}>
                    {getTranslateString(t, FORM_DESCRIPTION)}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={8}
                  sm={9}
                  md={11}
                  lg={9}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <FormControl fullWidth>
                    <TextField
                      hiddenLabel
                      multiline
                      variant="outlined"
                      name="description"
                      size="small"
                      minRows={2}
                      placeholder={getTranslateString(t, FORM_DESCRIPTION)}
                      value={values.description}
                      onChange={formik.handleChange}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={4} sm={3} md={1} lg={3} sx={{ whiteSpace: "pre-line", display: "flex", alignItems: "center" }}>
                  <Typography variant="body2" color={(theme.palette.neutral as any)[500]}>
                    Program Banner
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={8}
                  sm={9}
                  md={11}
                  lg={9}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <FormControl fullWidth>
                    <TextField
                      hiddenLabel
                      multiline
                      variant="outlined"
                      name="description"
                      size="small"
                      placeholder={"Program Banner"}
                      value={values.description}
                      onChange={formik.handleChange}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={4} sm={3} md={1} lg={3} sx={{ whiteSpace: "pre-line", display: "flex", alignItems: "center" }}>
                  <Typography variant="body2" color={(theme.palette.neutral as any)[500]}>
                    Valid Date
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={8}
                  sm={9}
                  md={11}
                  lg={9}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Stack direction="row" spacing={1} alignItems="center" width="100%">
                    <FormControl fullWidth>
                      <DatePicker
                        mask="____-__-__"
                        value={formik.values.valid_start_date}
                        inputFormat={DATE_SERVER_FORMAT}
                        onChange={date => formik.setFieldValue("valid_start_date", date)}
                        renderInput={params => {
                          return (
                            <TextField
                              {...params}
                              sx={{
                                "& .MuiOutlinedInput-input": {
                                  py: "10px",
                                },
                              }}
                              hiddenLabel
                              error={false}
                              variant="outlined"
                              name="valid_start_date"
                              inputProps={{ placeholder: "Start" }}
                              value={formik.values.valid_start_date ? moment(formik.values.valid_start_date).format(DATE_SERVER_FORMAT) : null}
                            />
                          );
                        }}
                      />
                    </FormControl>
                    <FontAwesomeIcon icon={faArrowRight} />
                    <FormControl fullWidth>
                      <DatePicker
                        mask="____-__-__"
                        value={formik.values.valid_end_date}
                        inputFormat={DATE_SERVER_FORMAT}
                        onChange={date => formik.setFieldValue("valid_end_date", date)}
                        renderInput={params => {
                          return (
                            <TextField
                              {...params}
                              sx={{
                                "& .MuiOutlinedInput-input": {
                                  py: "10px",
                                },
                              }}
                              hiddenLabel
                              error={false}
                              variant="outlined"
                              name="valid_end_date"
                              inputProps={{ placeholder: "End" }}
                              value={formik.values.valid_end_date ? moment(formik.values.valid_start_date).format(DATE_SERVER_FORMAT) : null}
                            />
                          );
                        }}
                      />
                    </FormControl>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={4} sm={3} md={1} lg={3} sx={{ whiteSpace: "pre-line", display: "flex", alignItems: "center" }}>
                  <Typography variant="body2" color={(theme.palette.neutral as any)[500]}>
                    Redeem Date
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={8}
                  sm={9}
                  md={11}
                  lg={9}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Stack direction="row" spacing={1} alignItems="center" width="100%">
                    <FormControl fullWidth>
                      <DatePicker
                        mask="____-__-__"
                        value={formik.values.valid_start_date}
                        inputFormat={DATE_SERVER_FORMAT}
                        onChange={date => formik.setFieldValue("valid_start_date", date)}
                        renderInput={params => {
                          return (
                            <TextField
                              {...params}
                              sx={{
                                "& .MuiOutlinedInput-input": {
                                  py: "10px",
                                },
                              }}
                              hiddenLabel
                              error={false}
                              variant="outlined"
                              name="valid_start_date"
                              inputProps={{ placeholder: "Start" }}
                              value={formik.values.valid_start_date ? moment(formik.values.valid_start_date).format(DATE_SERVER_FORMAT) : null}
                            />
                          );
                        }}
                      />
                    </FormControl>
                    <FontAwesomeIcon icon={faArrowRight} />
                    <FormControl fullWidth>
                      <DatePicker
                        mask="____-__-__"
                        value={formik.values.valid_end_date}
                        inputFormat={DATE_SERVER_FORMAT}
                        onChange={date => formik.setFieldValue("valid_end_date", date)}
                        renderInput={params => {
                          return (
                            <TextField
                              {...params}
                              sx={{
                                "& .MuiOutlinedInput-input": {
                                  py: "10px",
                                },
                              }}
                              hiddenLabel
                              error={false}
                              variant="outlined"
                              name="valid_end_date"
                              inputProps={{ placeholder: "End" }}
                              value={formik.values.valid_end_date ? moment(formik.values.valid_start_date).format(DATE_SERVER_FORMAT) : null}
                            />
                          );
                        }}
                      />
                    </FormControl>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={4} sm={3} md={1} lg={3} sx={{ whiteSpace: "pre-line", display: "flex", alignItems: "center" }}>
                  <Typography variant="body2" color={(theme.palette.neutral as any)[500]}>
                    Outlet
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={8}
                  sm={9}
                  md={11}
                  lg={9}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-multiple-chip-label"
                      id="demo-multiple-chip"
                      multiple
                      value={formik.values.participating_outlets}
                      onChange={handleChange}
                      sx={{
                        "& .MuiSelect-select": {
                          py: "6px",
                        },
                      }}
                      input={<OutlinedInput id="select-multiple-chip" />}
                      renderValue={selected => {
                        return (
                          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                            {selected.map(value => (
                              <Chip key={value} label={value} />
                            ))}
                          </Box>
                        );
                      }}
                    >
                      {outlets &&
                        outlets.results.map((outlet: IOutlet) => (
                          <MenuItem key={outlet.uuid} value={outlet.uuid} sx={{ minHeight: "auto" }}>
                            <Checkbox sx={{ py: 0 }} checked={(formik.values.participating_outlets as string[]).indexOf(outlet.uuid) > -1} />
                            <ListItemText secondary={outlet.outlet_name} />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={4} sm={3} md={1} lg={3} sx={{ whiteSpace: "pre-line", display: "flex", alignItems: "center" }}>
                  <Typography variant="body2" color={(theme.palette.neutral as any)[500]}>
                    Stamp Slot
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={8}
                  sm={9}
                  md={11}
                  lg={9}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <FormControl fullWidth>
                    <TextField
                      variant="outlined"
                      name="slot"
                      size="small"
                      inputProps={{ min: 1 }}
                      type="number"
                      placeholder={"Slot"}
                      value={values.slot}
                      onChange={formik.handleChange}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={4} sm={3} md={1} lg={3} sx={{ whiteSpace: "pre-line", display: "flex", alignItems: "center" }}>
                  <Typography variant="body2" color={(theme.palette.neutral as any)[500]}>
                    Terms & Conditions
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={8}
                  sm={9}
                  md={11}
                  lg={9}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <FormControl fullWidth>
                    <TextField
                      multiline
                      minRows={2}
                      variant="outlined"
                      name="description"
                      size="small"
                      placeholder={"Terms and Conditions"}
                      value={values.description}
                      onChange={formik.handleChange}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={4} sm={3} md={1} lg={3} sx={{ whiteSpace: "pre-line", display: "flex", alignItems: "center" }}>
                  <Typography variant="body2" color={(theme.palette.neutral as any)[500]}>
                    Redeemables
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={8}
                  sm={9}
                  md={11}
                  lg={9}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <FormControl fullWidth>
                    <TextField
                      multiline
                      variant="outlined"
                      name="description"
                      size="small"
                      placeholder={getTranslateString(t, FORM_DESCRIPTION)}
                      value={values.description}
                      onChange={formik.handleChange}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={4} sm={3} md={1} lg={3} sx={{ whiteSpace: "pre-line", display: "flex", alignItems: "center" }}>
                  <Typography variant="body2" color={(theme.palette.neutral as any)[500]}>
                    Preview
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={8}
                  sm={9}
                  md={11}
                  lg={9}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <FormControl fullWidth>
                    <TextField
                      hiddenLabel
                      multiline
                      variant="outlined"
                      name="description"
                      size="small"
                      placeholder={getTranslateString(t, FORM_DESCRIPTION)}
                      value={values.description}
                      onChange={formik.handleChange}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2}>
          <Grid item xs>
            <Stack direction="row" spacing={2} justifyContent="flex-end">
              <Button variant="outlined" onClick={onClose} size="small">
                {getTranslateString(t, BUTTON_CANCEL)}
              </Button>

              {crud === "create" ? (
                <Button
                  form="loyaltyForm"
                  variant="contained"
                  color="primary"
                  type="submit"
                  size="small"
                  startIcon={<FontAwesomeIcon icon={faPlus} />}
                >
                  {getTranslateString(t, CREATE)}
                </Button>
              ) : (
                <Button
                  form="loyaltyForm"
                  variant="contained"
                  color="primary"
                  type="submit"
                  size="small"
                  startIcon={<FontAwesomeIcon icon={faFloppyDisk} />}
                >
                  {getTranslateString(t, BUTTON_SAVE)}
                </Button>
              )}
            </Stack>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default LoyaltyCrudDialog;
