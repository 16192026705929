import React from "react";
/* components */
import CustomContainer from "src/components/common/data-display/custom-container";
import MerchantBottomNavbar from "src/components/common/navigation/merchant-bottom-navbar";
import MerchantTopNavbar from "src/components/common/navigation/merchant-top-navbar";
import MerchantLoyaltyList from "src/components/merchant/merchant-loyalty-list";
import useMediaQueries from "src/hooks/use-mediaqueries";
/* 3rd party lib */
import { Box, Stack, Typography, useTheme } from "@mui/material";
/* Util */

import WataaLogo from "src/images/wataa.png";
import MerchantActivitiesCarousel from "src/components/merchant/merchant-activities-carousel";
interface MerchantHomePageProps {}

type Props = MerchantHomePageProps;

const MerchantHomePage: React.FC<Props> = () => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const { smDown } = useMediaQueries();
  const theme = useTheme();
  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <CustomContainer>
      <MerchantTopNavbar />
      <Stack
        sx={{
          width: smDown ? "100vw" : "420px",
          background: theme.palette.secondary.light,
          // borderRadius: "50%",
          pt: 8,
          height: "240px",
          clipPath: "circle(80% at 50% 0)",
        }}
        spacing={2}
        alignItems="center"
        justifyContent={"flex-start"}
      >
        <Stack direction="row">
          <Typography variant="h5" color="text.primary" fontWeight={"light"}>
            Welcome,&nbsp;
          </Typography>
          <Typography variant="h5" color="text.primary" fontWeight={"bolder"}>
            Henry
          </Typography>
        </Stack>
        <img src={WataaLogo} width="120px" alt="wataaLogo" />
      </Stack>
      <MerchantActivitiesCarousel />
      <Box sx={{ width: "100%", minHeight: "50px" }}>
        <MerchantLoyaltyList />
      </Box>
      <MerchantBottomNavbar />
    </CustomContainer>
  );
};

export default MerchantHomePage;
