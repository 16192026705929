import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import store from "src/store";
import "./i18n";
import { SWRConfig } from "swr";
import HttpProvider, { HttpConsumer } from "src/contexts/http-context";
import AuthProvider, { AuthConsumer } from "src/contexts/auth-context";
import { SplashScreen } from "./components/common/splashscreen";
import { BrowserRouter } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <BrowserRouter>
          <HttpProvider>
            <AuthProvider>
              <AuthConsumer>
                {auth => {
                  return !auth.auth.isInitialized ? (
                    <SplashScreen />
                  ) : (
                    <HttpConsumer>
                      {({ http }) => (
                        <SWRConfig
                          value={{
                            refreshInterval: 60000,
                            fetcher: (url, config) => {
                              return http.get(url, config).then(res => res.data);
                            },
                          }}
                        >
                          <App />
                        </SWRConfig>
                      )}
                    </HttpConsumer>
                  );
                }}
              </AuthConsumer>
            </AuthProvider>
          </HttpProvider>
        </BrowserRouter>
      </LocalizationProvider>
    </Provider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
