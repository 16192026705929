import React, { useState } from "react";
/* components */
import CustomContainer from "src/components/common/data-display/custom-container";
import MerchantTopNavbar from "src/components/common/navigation/merchant-top-navbar";
/* 3rd party lib */
import moment from "moment";
import { Stack, useTheme, Typography, Grid, styled, LinearProgress, Card, CardContent, Box, Drawer, Button } from "@mui/material";
import useMediaQueries from "src/hooks/use-mediaqueries";
import { merchantLoyaltyListArr } from "src/components/merchant/merchant-loyalty-list";
import { totalCards } from "src/components/common/data-display/loyalty-cards-list";
import Stamp, { IStampStatus } from "src/components/loyalty/stamp";
import { getTranslateString } from "src/utils/translate";
import { BUTTON_CONFIRM } from "src/constants/translate-keys/common";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import IssueSuccessDialog from "src/components/common/feedback/issue-success-dialog";
/* Util */

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: "10px",
  borderRadius: "50px",
  background: theme.palette.secondary.light,
  "& .MuiLinearProgress-bar": {
    borderRadius: "50px",
    background: theme.palette.secondary.main,
  },
}));

interface MerchantScanConfirmProps {}

type Props = MerchantScanConfirmProps;

const MerchantScanConfirm: React.FC<Props> = () => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const theme = useTheme();
  const { t } = useTranslation();
  const { smDown } = useMediaQueries();
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const { state } = useLocation();
  const { stamp } = state;
  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  let cardObj = { ...totalCards[0] };

  let issuingNumber = stamp;

  if (stamp + cardObj.stampedAt > cardObj.totalStars) {
    issuingNumber = totalCards[0].totalStars - totalCards[0].stampedAt; // set to max - current
    cardObj.stampedAt = totalCards[0].totalStars; // set to max
  } else {
    cardObj.stampedAt += stamp; // add stamp
    issuingNumber = stamp;
  }

  /* ================================================== */
  /* ================================================== */
  return (
    <>
      <CustomContainer>
        <MerchantTopNavbar back version="curve" />
        <Stack
          sx={{
            paddingTop: "52px",
            width: smDown ? "100vw" : "420px",
            height: "180px",
            background: theme.palette.primary.light,
            // borderRadius: "50%",,
            clipPath: `circle(90% at 50% ${smDown ? "-12vw" : "-80px"})`,
            backgroundImage: `url(${merchantLoyaltyListArr[0].image})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          spacing={2}
          alignItems="center"
          justifyContent={"flex-start"}
        ></Stack>
        <Card
          elevation={0}
          sx={{
            background: "transparent",
            py: 2,
          }}
        >
          <Box>
            <CardContent>
              <Box mb={2}>
                <Typography variant="h5" fontWeight={"bolder"} color="secondary.main">
                  {merchantLoyaltyListArr[0].title}
                </Typography>
                <Typography variant="body2" color="text.primary">
                  Expired by {moment(merchantLoyaltyListArr[0].expiredDate).format("DD MMM YYYY")}
                </Typography>
              </Box>
              <Typography color="secondary" variant="subtitle1" mb={1}>
                + <b>{issuingNumber}</b> stamp{stamp > 1 ? "s" : ""}
              </Typography>
              <Box sx={{ width: smDown ? "80vw" : "300px", height: "auto" }}>
                <Grid container rowSpacing={1} sx={{ width: "100%", height: "100%" }}>
                  {Array(totalCards[0].totalStars)
                    .fill(0)
                    .map((_child, index) => {
                      let stampType: IStampStatus = "inactive_star";

                      // inactive star when index is greater than currently stamped position, and not last stamp, and also not gifts
                      if (index > cardObj.stampedAt - 1 && index !== cardObj.totalStars - 1 && !cardObj.gift.includes(index + 1)) {
                        stampType = "inactive_star";
                      }
                      // active star when index is less than currently stamped position, and not last stamp, and also not gifts
                      if (index <= cardObj.stampedAt - 1 && index !== cardObj.totalStars - 1 && !cardObj.gift.includes(index + 1)) {
                        stampType = "active_star";
                      }
                      // inactive gift when index is greater than currently stamped position, and the array includes the index
                      if (index > cardObj.stampedAt - 1 && cardObj.gift.includes(index + 1)) {
                        stampType = "inactive_gift";
                      }
                      // active gift when index is less than currently stamped position, and the array includes the index
                      if (index <= cardObj.stampedAt - 1 && cardObj.gift.includes(index + 1)) {
                        stampType = "active_gift";
                      }
                      if (cardObj.stampedAt - 1 < cardObj.totalStars - 1 && index === cardObj.totalStars - 1) {
                        stampType = "inactive_trophy";
                      }
                      if (index === cardObj.stampedAt - 1 && index === cardObj.totalStars - 1) {
                        stampType = "active_trophy";
                      }

                      return (
                        <Grid key={index} item xs={12 / 5} className="center" sx={{ paddingLeft: 0 }}>
                          <Stamp size={1} status={stampType} newStamp={index + 1 > totalCards[0].stampedAt} />
                        </Grid>
                      );
                    })}
                </Grid>
                <Stack mt={2} direction="row" alignItems={"center"} justifyContent={"space-between"}>
                  {<StyledLinearProgress variant="determinate" value={(cardObj.stampedAt / totalCards[0].totalStars) * 100} sx={{ width: "80%" }} />}
                  <Stack>
                    <Typography variant="h6" fontWeight="light" lineHeight={0.8}>
                      <b>{cardObj.stampedAt}</b> / {totalCards[0].totalStars}
                    </Typography>
                    <Typography variant="caption">Stamped</Typography>
                  </Stack>
                </Stack>
              </Box>
            </CardContent>
          </Box>
        </Card>

        <Drawer variant="permanent" anchor="bottom">
          <Button onClick={() => setShowSuccessDialog(true)} color="secondary" variant="contained" sx={{ margin: 1 }}>
            Click to Confirm
          </Button>
        </Drawer>
      </CustomContainer>
      <IssueSuccessDialog
        stamp={issuingNumber}
        open={showSuccessDialog}
        onClose={() => setShowSuccessDialog(false)}
        title={totalCards[0].title}
        logo={totalCards[0].image}
      />
    </>
  );
};

export default MerchantScanConfirm;
