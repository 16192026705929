import React from "react";
/* components */
/* 3rd party lib */
import { Box, Stack, Typography } from "@mui/material";
/* Util */
interface MerchantLoyaltyDailyProps {}

type Props = MerchantLoyaltyDailyProps;

const MerchantLoyaltyDaily: React.FC<Props> = () => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <Stack spacing={2}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Stack alignItems={"flex-start"} sx={{ pl: 1 }}>
          <Box sx={{ textAlign: "left" }}>
            <Typography sx={{ display: "inline-block" }} variant="body1" color="text.primary" fontWeight={"bolder"}>
              X
            </Typography>
            &nbsp;stamps issued&nbsp;
          </Box>
          <Typography variant="caption" color="text.primary">
            05 Feb 2023, 09:00AM
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default MerchantLoyaltyDaily;
