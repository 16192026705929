import { AuthGuard } from "src/components/authentication/auth-guard";
import DashboardSidebarLayout from "src/components/dashboard/dashboard-sidebar-layout";

const Member = () => {
  // const [muiTab, setMuiTab] = useState(WORK_ORDER_TAB.OVERVIEW);
  return (
    <>
      <AuthGuard permissionKey="WORK_ORDERS">
        <DashboardSidebarLayout>MEMBER</DashboardSidebarLayout>
      </AuthGuard>
    </>
  );
};

export default Member;
