import React from "react";
/* components */
/* 3rd party lib */
import { Stack, Box, Typography } from "@mui/material";
import { redGift, starYellow } from "src/utils/constants";
/* Util */
interface LoyaltyCardHistoryProps {}

type Props = LoyaltyCardHistoryProps;

const LoyaltyCardHistory: React.FC<Props> = () => {
  /* ================================================== */
  /*  state */
  /* ================================================== */

  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <>
      <Stack direction="row" justifyContent={"space-between"} alignItems="center">
        <Stack direction="row" alignItems="center">
          <Box width="52px">
            <span style={{ color: starYellow }}>
              <i className="fak fa-star fa-xl"></i>
            </span>
          </Box>
          <Stack alignItems={"flex-start"}>
            <Typography variant="body1" color="text.primary" fontWeight={"bolder"}>
              Received Stamp
            </Typography>
            <Typography variant="caption" color="text.primary">
              05 Feb 2023, 09:00AM
            </Typography>
          </Stack>
        </Stack>
        <Box>
          <Typography variant="body1" color="text.primary" fontWeight={"bolder"}>
            +1
          </Typography>
        </Box>
      </Stack>

      <Stack direction="row" justifyContent={"space-between"} alignItems="center">
        <Stack direction="row" alignItems="center">
          <Box width="52px">
            <span style={{ color: redGift }}>
              <i className="fak fa-gift fa-xl"></i>
            </span>
          </Box>
          <Stack alignItems={"flex-start"}>
            <Typography variant="body1" color="text.primary" fontWeight={"bolder"}>
              Get 1 bread
            </Typography>
            <Typography variant="caption" color="text.primary">
              05 Feb 2023, 09:00AM
            </Typography>
          </Stack>
        </Stack>
        <Box>
          <Typography variant="body1" color="text.primary" fontWeight={"bolder"}>
            Claimed
          </Typography>
        </Box>
      </Stack>

      <Stack direction="row" justifyContent={"space-between"} alignItems="center">
        <Stack direction="row" alignItems="center">
          <Box width="52px">
            <span style={{ color: starYellow }}>
              <i className="fak fa-star fa-xl"></i>
            </span>
          </Box>
          <Stack alignItems={"flex-start"}>
            <Typography variant="body1" color="text.primary" fontWeight={"bolder"}>
              Received Stamp
            </Typography>
            <Typography variant="caption" color="text.primary">
              05 Feb 2023, 09:00AM
            </Typography>
          </Stack>
        </Stack>
        <Box>
          <Typography variant="body1" color="text.primary" fontWeight={"bolder"}>
            +1
          </Typography>
        </Box>
      </Stack>
      <Stack direction="row" justifyContent={"space-between"} alignItems="center">
        <Stack direction="row" alignItems="center">
          <Box width="52px">
            <span style={{ color: starYellow }}>
              <i className="fak fa-star fa-xl"></i>
            </span>
          </Box>
          <Stack alignItems={"flex-start"}>
            <Typography variant="body1" color="text.primary" fontWeight={"bolder"}>
              Received Stamp
            </Typography>
            <Typography variant="caption" color="text.primary">
              05 Feb 2023, 09:00AM
            </Typography>
          </Stack>
        </Stack>
        <Box>
          <Typography variant="body1" color="text.primary" fontWeight={"bolder"}>
            +1
          </Typography>
        </Box>
      </Stack>
    </>
  );
};

export default LoyaltyCardHistory;
