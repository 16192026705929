import { Box, useTheme, alpha } from "@mui/material";
import { Logo } from "src/components/common/logo";
import "./splashscreen.scss";

export const SplashScreen = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        alignItems: "center",
        backgroundColor: alpha(theme.palette.primary.light, 0.1),
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        justifyContent: "center",
        left: 0,
        top: 0,
        width: "100vw",
        zIndex: 2000,
        position: "fixed",
      }}
    >
      <Box
        sx={{
          zIndex: 3,
          position: "absolute",
          animation: `logoBounce 2s ease infinite`,
          "@keyframes logoBounce": {
            "0%": {
              transform: "translate3d(0, -40px, 0) rotate(-15deg)",
            },
            "20%": {
              transform: "translate3d(0, -40px, 0) rotate(0deg)",
            },
            "50%": {
              transform: "translate3d(-40px, -80px, 0) rotate(-15deg)",
            },
            "100%": {
              transform: "translate3d(0, -40px, 0) rotate(-15deg)",
            },
          },
        }}
      >
        <Logo
          sx={{
            height: 80,
            width: 80,
          }}
        />
      </Box>
      <Box sx={{ position: "absolute", top: "25%" }}>
        <div id="clouds">
          <div className="cloud1"></div>
          <div className="cloud3"></div>
          <div className="cloud2"></div>
        </div>
        <div id="clouds2">
          <div className="cloud2"></div>
          <div className="cloud3"></div>
          <div className="cloud1"></div>
        </div>
      </Box>
    </Box>
  );
};
