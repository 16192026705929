import React, { useEffect } from "react";
// import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import { AuthRootState, initialize as authInit, login as authLogin, logout as authLogout } from "src/slices/auth";
import useHttp from "src/hooks/use-http";
import toast from "react-hot-toast";
import { getToastSuccess } from "src/utils/translate";
import { BUTTON_LOGIN, TOAST_SUCCESS_ACTION_SUCCESSFULLY } from "src/constants/translate-keys/common";
import { useTranslation } from "react-i18next";
import { RootState } from "src/store";
import { permissions } from "src/constants/auth";
import { useNavigate } from "react-router-dom";
import routes from "src/routes";

export type AuthContextContent = {
  login: (username: string, password: string) => void;
  logout: () => void;
  auth: AuthRootState;
};

export const AuthContext = React.createContext<AuthContextContent>({} as AuthContextContent);

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state: RootState) => state.auth);

  const { http, apiEndpoint } = useHttp();
  // const router = useRouter();

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = globalThis.localStorage.getItem("accessToken");
        if (accessToken) {
          http.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

          const user = await getCurrentUser();

          // const permissions = await getPermissions();
          // const merchants = await getAllMerchants();
          const permissions = null;
          const configs = null;
          // const configs = await getConfigs();

          dispatch(authInit({ isAuthenticated: true, user, permissions, configs }));
        } else {
          dispatch(
            authInit({
              isAuthenticated: false,
              user: null,
              permissions: null,
              configs: null,
            })
          );
        }
      } catch (error) {
        console.error(error);
        dispatch(authLogout());
      }
    };

    if (auth.configs === undefined || auth.configs === null) {
      initialize();
    }
  }, [auth.configs]);

  const getCurrentUser = async () => {
    const { data: user } = await http.get(apiEndpoint.CURRENT_USER);

    return user;
  };

  const getAllMerchants = async () => {
    const { data } = await http.get(apiEndpoint.MERCHANTS);
    return data;
  };

  const getPermissions = async () => {
    const result: { [key: string]: boolean } = {};
    const { data } = await http.get(apiEndpoint.PERMISSIONS);

    result["OVERVIEW"] = true;
    result["ACCOUNT"] = true;

    Object.keys(permissions).forEach(key => {
      result[key] = data[(permissions as any)[key]];
    });

    return result;
  };

  const getConfigs = async () => {
    const { data } = await http.get(apiEndpoint.CONFIGS);
    return data;
  };

  const login = async (username: string, password: string) => {
    try {
      const { data: token } = await http.post(apiEndpoint.TOKEN, JSON.stringify({ username, password }), { withCredentials: true });

      localStorage.setItem("accessToken", token.access);
      localStorage.setItem("refreshToken", token.refresh);

      http.defaults.headers.common["Authorization"] = `Bearer ${token.access}`;

      const user = await getCurrentUser();
      const merchants = await getAllMerchants();

      // const permissions = await getPermissions();
      const permissions = null;

      dispatch(authLogin({ user, permissions }));

      toast.success(getToastSuccess(t, TOAST_SUCCESS_ACTION_SUCCESSFULLY, BUTTON_LOGIN, "", false, false));
    } catch (error: any) {
      toast.error(error.response.data.detail);
    }
  };

  const logout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("remember_me");

    // Removes default Authorization in request header
    delete http.defaults.headers.common["Authorization"];

    navigate(routes.LOGIN);

    dispatch(authLogout());
  };

  return <AuthContext.Provider value={{ auth, login, logout }}>{children}</AuthContext.Provider>;
};

export default AuthProvider;

export const AuthConsumer: any = AuthContext.Consumer;
