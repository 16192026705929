import { Box, Stack, Typography, useTheme } from "@mui/material";
import React from "react";
/* components */
/* 3rd party lib */
import moment from "moment";
import { useNavigate } from "react-router-dom";
import routes from "src/routes";
/* Util */
interface MerchantLoyaltyProps {
  cardObj: { id: number; title: string; expiredDate: string };
}

type Props = MerchantLoyaltyProps;

const MerchantLoyalty: React.FC<Props> = ({ cardObj }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const theme = useTheme();
  const navigate = useNavigate();
  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <Box
      sx={{
        cursor: "pointer",
        borderRadius: "8px",
        border: `1px solid ${theme.palette.secondary.main}`,
      }}
      onClick={() => navigate(`${routes.MERCHANT_LOYALTY_PROGRAMS}/${cardObj.id}`)}
    >
      <Stack direction="row" alignItems={"center"}>
        <Box sx={{ width: "70%" }}>
          <Typography variant="body1" fontWeight="bolder">
            {cardObj.title}
          </Typography>
        </Box>
        <Box sx={{ background: theme.palette.secondary.main, width: "30%", borderRadius: "0px 8px 8px 0px", py: 1 }}>
          <Stack spacing={0}>
            <Typography variant="caption" fontWeight="bolder" color="secondary.contrastText">
              Expired by
            </Typography>
            <Typography variant="h5" fontWeight="bolder" color="secondary.contrastText">
              {moment(cardObj.expiredDate).format("DD")}
            </Typography>
            <Typography variant="caption" fontWeight="bolder" color="secondary.contrastText">
              {moment(cardObj.expiredDate).format("MMM")}&nbsp;
              {moment(cardObj.expiredDate).format("YYYY")}
            </Typography>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

export default MerchantLoyalty;
