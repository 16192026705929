import React from "react";
/* components */
/* 3rd party lib */
import { Box, Typography, Stack, Grid, useTheme } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/pro-regular-svg-icons";
import { Global } from "@emotion/react";

/* Util */
interface MerchantLoyaltyDetailsProps {}

type Props = MerchantLoyaltyDetailsProps;

const MerchantLoyaltyDetails: React.FC<Props> = () => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const theme = useTheme();
  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <>
      <Global
        styles={{
          ".details__list": {
            margin: 0,
          },
        }}
      ></Global>
      {/* how to */}
      <Box textAlign={"left"}>
        <Typography variant="body1" fontWeight={"bolder"} color="secondary.main">
          How to Redeem
        </Typography>
        <ul className="details__list">
          <li>
            <Typography variant="body2" lineHeight={1.2}>
              Purchase any bread above 10$ to obtain 1 stamp per transaction from now until 20 Jun 2023.
            </Typography>
          </li>

          <li>
            <Typography variant="body2" lineHeight={1.2}>
              Coupon is limited to first 1000 redemptions only.
            </Typography>
          </li>
        </ul>
      </Box>
      {/* rewards */}
      <Box textAlign={"left"}>
        <Typography variant="body1" fontWeight={"bolder"} color="secondary.main">
          Rewards
        </Typography>
        <ul className="details__list">
          <li>
            <Typography variant="body2" lineHeight={1.2}>
              3 Stamps - Get 1 bread
            </Typography>
          </li>

          <li>
            <Typography variant="body2" lineHeight={1.2}>
              7 Stamps - Get 2 breads
            </Typography>
          </li>

          <li>
            <Typography variant="body2" lineHeight={1.2}>
              12 Stamps - Get 1 chocolate cake
            </Typography>
          </li>
        </ul>
      </Box>
      {/* outlets */}
      <Box textAlign={"left"}>
        <Typography variant="body1" fontWeight={"bolder"} color="secondary.main">
          Participating Outlets
        </Typography>
        <Stack>
          <Grid container>
            <Grid item xs={1} pl={1}>
              <FontAwesomeIcon icon={faLocationDot} color={theme.palette.text.primary} />
            </Grid>
            <Grid item xs={11}>
              <Stack>
                <Typography variant="body2" fontWeight={"bolder"}>
                  Wataa Bakery Woodlands
                </Typography>
                <Typography variant="caption"> 303 Woodlands St 31 #01-199 S(730303)</Typography>
              </Stack>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={1} pl={1}>
              <FontAwesomeIcon icon={faLocationDot} color={theme.palette.text.primary} />
            </Grid>
            <Grid item xs={11}>
              <Stack>
                <Typography variant="body2" fontWeight={"bolder"}>
                  Wataa Bakery Marsiling
                </Typography>
                <Typography variant="caption"> 19 Marsiling Lane, #01-299 S(730019)</Typography>
              </Stack>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={1} pl={1}>
              <FontAwesomeIcon icon={faLocationDot} color={theme.palette.text.primary} />
            </Grid>
            <Grid item xs={11}>
              <Stack>
                <Typography variant="body2" fontWeight={"bolder"}>
                  Wataa Bakery Tampines
                </Typography>
                <Typography variant="caption"> Blk 826 Tampines Street 81, #01-98 Singapore 520826</Typography>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Box>
      {/* terms and conditions */}
      <Box textAlign={"left"}>
        <Typography variant="body1" fontWeight={"bolder"} color="secondary.main">
          Terms and Conditions
        </Typography>
        <ul className="details__list">
          <li>
            <Typography variant="body2" lineHeight={1.2}>
              One stamp is awarded to user for every transaction above 10$ from now until 20 May 2023.
            </Typography>
          </li>

          <li>
            <Typography variant="body2" lineHeight={1.2}>
              Collect all 12 stamps for a chocolate cake when use this coupon.
            </Typography>
          </li>

          <li>
            <Typography variant="body2" lineHeight={1.2}>
              This coupon is limited to only 1000 redemptions.
            </Typography>
          </li>

          <li>
            <Typography variant="body2" lineHeight={1.2}>
              Other terms and conditions may apply.
            </Typography>
          </li>
        </ul>
      </Box>
    </>
  );
};

export default MerchantLoyaltyDetails;
