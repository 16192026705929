import React from "react";
/* components */
/* 3rd party lib */
import { Card, Box, Stack, alpha, useTheme, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
/* Util */
import ActiveGift from "src/images/gift.png";

interface RewardProps {
  claimed?: boolean;
  claimable?: boolean;
  final?: boolean;
}

type Props = RewardProps;

const Reward: React.FC<Props> = ({ claimed = false, claimable = false, final }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const theme = useTheme();
  const activeGiftBg = "#FFF0F0";

  /* ================================================== */
  /*  method */
  /* ================================================== */

  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <>
      <Card
        sx={{
          height: "100%",
          background: claimed ? alpha(theme.palette.primary.light, 0.6) : alpha((theme.palette.neutral as any)[200], 0.7),
          animation: !claimed && claimable && !final ? `pulsating 1.5s linear infinite` : "none",
          "@keyframes pulsating": {
            "0%": {
              background: theme.palette.primary.main,
            },
            "50%": {
              background: alpha(theme.palette.primary.main, 0.8),
            },
            "100%": {
              background: theme.palette.primary.main,
            },
          },
        }}
      >
        {claimed && (
          <Stack px={2} direction="row" spacing={1} height="100%" alignItems="center" justifyContent={"space-between"}>
            <Box className="center">
              <Typography variant="body2" color="text.primary">
                Get 1 bread
              </Typography>
            </Box>
            <Box className="center" sx={{ height: "60px" }}>
              <Box className="center" sx={{ background: "white", borderRadius: "50%", width: "50px", height: "50px" }}>
                <FontAwesomeIcon size="xl" icon={faCheck} color={theme.palette.success.main} />
              </Box>
            </Box>
          </Stack>
        )}
        {!claimed && claimable && !final && (
          <Stack px={2} direction="row" spacing={1} height="100%" alignItems="center" justifyContent={"space-between"}>
            <Box>
              <Typography lineHeight={1} textAlign="left" variant="body2" color={!claimed && claimable && !final ? "white" : "text.primary"}>
                Get 2 breads
              </Typography>
              <Typography
                lineHeight={1}
                fontStyle="italic"
                textAlign="left"
                variant="caption"
                color={!claimed && claimable && !final ? "white" : "text.primary"}
              >
                Click to claim reward
              </Typography>
            </Box>
            <Box className="center" sx={{ height: "60px" }}>
              <Box
                className="center"
                sx={{ background: !claimed && claimable && !final ? activeGiftBg : "white", borderRadius: "50%", width: "50px", height: "50px" }}
              >
                {!claimed && claimable && !final ? (
                  <img src={ActiveGift} width={`30px`} alt="active_gift" />
                ) : (
                  <span style={{ color: (theme.palette.neutral as any)[300] }}>
                    <i className="fak fa-gift fa-xl"></i>
                  </span>
                )}
              </Box>
            </Box>
          </Stack>
        )}
        {final && (
          <Stack px={2} direction="row" spacing={1} height="100%" alignItems="center" justifyContent={"space-between"}>
            <Box className="center">
              <Typography variant="body2" color="text.primary">
                Get 1 chocolate cake
              </Typography>
            </Box>
            <Box className="center" sx={{ height: "60px" }}>
              <Box className="center" sx={{ background: "white", borderRadius: "50%", width: "50px", height: "50px" }}>
                <span style={{ color: (theme.palette.neutral as any)[300] }}>
                  <i className="fak fa-trophy fa-xl"></i>
                </span>
              </Box>
            </Box>
          </Stack>
        )}
      </Card>
    </>
  );
};

export default Reward;
