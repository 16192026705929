import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { AuthGuard } from "src/components/authentication/auth-guard";
import DashboardSidebarLayout from "src/components/dashboard/dashboard-sidebar-layout";
import routes from "src/routes";

interface DashboardProps {}

type Props = DashboardProps;

const Dashboard: React.FC<Props> = () => {
  const navigate = useNavigate();
  // const [muiTab, setMuiTab] = useState(WORK_ORDER_TAB.OVERVIEW);
  return (
    <>
      <AuthGuard permissionKey="WORK_ORDERS">
        <DashboardSidebarLayout>DASHBOARD</DashboardSidebarLayout>
      </AuthGuard>
    </>
  );
};

export default Dashboard;
