import React, { useState } from "react";
/* components */
import LoyaltyCard from "src/components/loyalty/loyalty-card";
import CustomContainer from "src/components/common/data-display/custom-container";
import TopNavbar from "src/components/common/navigation/top-navbar";
/* 3rd party lib */
import { Link, Box, Container, Grid, Stack, Tab, Tabs, Typography, useTheme, Button } from "@mui/material";
/* Util */
import WataaLogo from "src/images/wataa.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/pro-thin-svg-icons";
import useMediaQueries from "src/hooks/use-mediaqueries";
import { Global } from "@emotion/react";
import { totalCards } from "src/components/common/data-display/loyalty-cards-list";
import { faLocation, faLocationDot } from "@fortawesome/pro-regular-svg-icons";

interface StoreLocationsProps {}

type Props = StoreLocationsProps;

const StoreLocations: React.FC<Props> = () => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const theme = useTheme();
  const { smDown } = useMediaQueries();
  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <CustomContainer>
      <TopNavbar back />
      <Stack
        sx={{
          width: smDown ? "100vw" : "420px",
          background: theme.palette.primary.light,
          pt: 8,
          height: "240px",
          clipPath: smDown ? "circle(100% at 50% -50vw)" : " circle(100% at 50% -210px)",
        }}
        spacing={2}
      >
        <Typography variant="h5" fontWeight={"bolder"} color="primary.main">
          Store Locations
        </Typography>
      </Stack>
      <Container sx={{ marginTop: 3, transform: "translateY(-140px)" }}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6" pl={3} textAlign={"left"} fontWeight="bolder" color="primary.main">
              All Outlets
            </Typography>
            <Stack spacing={1}>
              <ol style={{ margin: 0 }}>
                <li>
                  <Stack alignItems={"flex-start"}>
                    <Typography variant="body1" fontWeight="bolder" color="text.primary">
                      Wataa Bakery Woodlands
                    </Typography>
                    <Typography variant="body2" color="text.primary" textAlign={"left"}>
                      303 Woodlands St 31 #01-199 S(730303)
                    </Typography>
                  </Stack>
                </li>
                <li>
                  <Stack alignItems={"flex-start"}>
                    <Typography variant="body1" fontWeight="bolder" color="text.primary">
                      Wataa Bakery Marsiling
                    </Typography>
                    <Typography variant="body2" color="text.primary" textAlign={"left"}>
                      19 Marsiling Lane #01-299 S(730019)
                    </Typography>
                  </Stack>
                </li>
                <li>
                  <Stack alignItems={"flex-start"}>
                    <Typography variant="body1" fontWeight="bolder" color="text.primary">
                      Wataa Bakery Tampines
                    </Typography>
                    <Typography variant="body2" color="text.primary" textAlign={"left"}>
                      Blk 826 Tampines St 81 #01-98 Singapore 520826
                    </Typography>
                  </Stack>
                </li>
              </ol>
            </Stack>
          </Grid>
        </Grid>{" "}
      </Container>
    </CustomContainer>
  );
};

export default StoreLocations;
