import { createSlice } from "@reduxjs/toolkit";

export interface GeneralRootState {
  scannedQR: string | null;
}

const initialState = {
  scannedQR: null,
};

const slice = createSlice({
  name: "general",
  initialState,
  reducers: {
    setQR: (state, action) => {
      state.scannedQR = action.payload;
    },
    resetQR: state => {
      state.scannedQR = null;
    },
  },
});

export default slice.reducer;

export const { setQR, resetQR } = slice.actions;
