import { useTheme, ListItem, ListItemButton, ListItemIcon, ListItemText, alpha } from "@mui/material";
import React from "react";
/* components */
/* 3rd party lib */
/* Util */
interface SidebarItemProps {
  icon: React.ReactNode;
  title: string;
  toggleDrawer: (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => void;
}

type Props = SidebarItemProps;

const SidebarItem: React.FC<Props> = ({ icon, title, toggleDrawer }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const theme = useTheme();
  const darkBlue = theme.palette.primary.dark;
  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <ListItem
      disablePadding
      sx={{
        px: 1,
        transition: "all 0.5s ease",
        color: "#ffffff",
        justifyContent: "flex-start",
        textAlign: "left",
        textTransform: "none",
        width: "100%",
        "&:hover": {
          backgroundColor: alpha(theme.palette.primary.main, 0.1),
        },
      }}
      onClick={toggleDrawer(false)}
    >
      <ListItemButton component="a">
        <ListItemIcon>
          <span style={{ color: darkBlue }}>{icon}</span>
        </ListItemIcon>
        <ListItemText primary={title} primaryTypographyProps={{ variant: "body2", fontWeight: 600, color: "text.primary" }} />
      </ListItemButton>
    </ListItem>
  );
};

export default SidebarItem;
