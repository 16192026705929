import React from "react";
/* components */
import CustomContainer from "src/components/common/data-display/custom-container";
import MerchantTopNavbar from "src/components/common/navigation/merchant-top-navbar";
/* 3rd party lib */
import {
  styled,
  Container,
  Stack,
  Typography,
  useTheme,
  FormControl,
  InputAdornment,
  TextField,
  Link,
  Box,
  Grid,
  Drawer,
  Button,
} from "@mui/material";
import useMediaQueries from "src/hooks/use-mediaqueries";
/* Util */
import { merchantLoyaltyListArr } from "src/components/merchant/merchant-loyalty-list";
import { BUTTON_NEXT } from "src/constants/translate-keys/common";
import { getTranslateString } from "src/utils/translate";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { useFormik } from "formik";
import routes from "src/routes";
import { useNavigate } from "react-router-dom";

const StyledTextField = styled(TextField)(() => ({
  input: {
    textAlign: "center",
    width: "100px",
    "&::-webkit-outer-spin-button": {
      display: "none",
    },
    "&::-webkit-inner-spin-button": {
      display: "none",
    },
    fontSize: "1.5rem",
  },

  "& .MuiOutlinedInput-root": {
    background: "white",
  },
}));

interface MerchantStampProps {}

type Props = MerchantStampProps;

const MerchantStamp: React.FC<Props> = () => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { smDown } = useMediaQueries();

  const formik = useFormik({
    validateOnChange: false,
    initialValues: {
      stamp: 1,
      transaction: "",
    },
    onSubmit: async values => {},
  });

  /* ================================================== */
  /*  method */
  /* ================================================== */
  const handleIncrementStamp = () => {
    if (typeof formik.values.stamp === "string") {
      if (parseInt(formik.values.stamp) === merchantLoyaltyListArr[0].totalStars) return;
      formik.setFieldValue("stamp", parseInt(formik.values.stamp) + 1);
    } else {
      if (formik.values.stamp === merchantLoyaltyListArr[0].totalStars) return;
      formik.setFieldValue("stamp", formik.values.stamp + 1);
    }
  };
  const handleDecrementStamp = () => {
    if (typeof formik.values.stamp === "string") {
      if (parseInt(formik.values.stamp) === 1) return;
      formik.setFieldValue("stamp", parseInt(formik.values.stamp) - 1);
    } else {
      if (formik.values.stamp === 1) return;
      formik.setFieldValue("stamp", formik.values.stamp - 1);
    }
  };
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <CustomContainer>
      <MerchantTopNavbar back url={routes.MERCHANT_HOME} version="curve" />
      <Stack
        sx={{
          paddingTop: "52px",
          width: smDown ? "100vw" : "420px",
          height: "180px",
          background: theme.palette.primary.light,
          // borderRadius: "50%",,
          clipPath: `circle(90% at 50% ${smDown ? "-12vw" : "-80px"})`,
          backgroundImage: `url(${merchantLoyaltyListArr[0].image})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        spacing={2}
        alignItems="center"
        justifyContent={"flex-start"}
      ></Stack>
      <Container sx={{ mt: 0 }}>
        <Grid container spacing={1} mt={1}>
          <Grid item xs={12}>
            <Typography variant="h5" fontWeight={"bolder"} color="secondary.main">
              {merchantLoyaltyListArr[0].title}
            </Typography>
            <Typography variant="body2" color="text.primary">
              Expired by {moment(merchantLoyaltyListArr[0].expiredDate).format("DD MMM YYYY")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography fontWeight={"bolder"} variant="body1" textAlign="left" color="text.primary">
              Preassign Stamps
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Stack
              direction="row"
              alignItems="stretch"
              justifyContent={"space-around"}
              sx={{ background: theme.palette.secondary.main, py: 1, borderRadius: "16px" }}
            >
              <Box onClick={() => handleDecrementStamp()} sx={{ width: "30%", color: "white", display: "grid", placeItems: "center" }}>
                <FontAwesomeIcon icon={faMinus} size="xl" />
              </Box>
              <Box sx={{ width: "40%" }}>
                <FormControl>
                  <StyledTextField
                    InputProps={{ inputProps: { min: 1, max: merchantLoyaltyListArr[0].totalStars } }}
                    type="number"
                    hiddenLabel
                    variant="outlined"
                    name="stamp"
                    onChange={e => formik.setFieldValue("stamp", e.target.value)}
                    value={formik.values.stamp}
                  />
                </FormControl>
              </Box>
              <Box
                onClick={() => {
                  handleIncrementStamp();
                }}
                sx={{ width: "30%", color: "white", display: "grid", placeItems: "center" }}
              >
                <FontAwesomeIcon icon={faPlus} size="xl" />
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Typography pt={2} fontWeight={"bolder"} variant="body1" textAlign="left" color="text.primary">
              Optional
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                name="transaction"
                hiddenLabel
                variant="outlined"
                value={formik.values.transaction}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
              <Link
                // href={routes.SIGNUP}
                variant="body2"
                color="text.primary"
                underline="hover"
                sx={{ cursor: "pointer", whiteSpace: "nowwrap", textDecoration: "underline", fontStyle: "italic" }}
                onClick={() => console.log("trigger upload receipt")}
              >
                Upload Receipt
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Drawer variant="permanent" anchor="bottom">
        <Button
          onClick={() => navigate(routes.MERCHANT_SCAN, { state: { stamp: formik.values.stamp, scanFirst: false } })}
          color="secondary"
          variant="contained"
          sx={{ margin: 1 }}
        >
          {getTranslateString(t, BUTTON_NEXT)}
        </Button>
      </Drawer>
    </CustomContainer>
  );
};

export default MerchantStamp;
