import React from "react";
/* components */
/* 3rd party lib */
import { Box, useTheme } from "@mui/material";
import useMediaQueries from "src/hooks/use-mediaqueries";
import { Global } from "@emotion/react";
/* Util */
interface CustomContainerProps {
  children: React.ReactNode;
}

type Props = CustomContainerProps;

const CustomContainer: React.FC<Props> = ({ children }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const { smDown } = useMediaQueries();
  const theme = useTheme();
  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <>
      <Global
        styles={
          !smDown && {
            "&::-webkit-scrollbar": {
              width: "2px",
              height: "2px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: theme.palette.primary.main,
              borderRadius: "1rem",
            },

            "&::-webkit-scrollbar-thumb:hover": {
              background: theme.palette.primary.light,
            },
          }
        }
      />

      <Box
        component="main"
        sx={{
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            overflowX: "hidden",
            flexDirection: "column",
            position: "relative",
            // justifyContent: "center",
            alignItems: "center",
            width: smDown ? "100vw" : "400px",
            minHeight: "100vh",
            // height: "100%",
            boxShadow: "0 0 5px 0.1px rgba(0, 0, 0, 0.2)",
            // backdropFilter: "blur(15px)",
            // WebkitBackdropFilter: "blur(15px)",
            borderRadius: 0,
          }}
        >
          {children}
        </Box>
      </Box>
    </>
  );
};

export default CustomContainer;
