import React from "react";
/* components */
/* 3rd party lib */
import { Global } from "@emotion/react";
import { Box, Button, Dialog, DialogContent, Stack, Typography, useTheme } from "@mui/material";
/* Util */
import "./index.scss";
import { useNavigate } from "react-router-dom";
import routes from "src/routes";

interface IssueSuccessDialogProps {
  title: string;
  logo: any;
  open: boolean;
  stamp: number;
  scanFirst?: boolean;
  onClose: () => void;
}

type Props = IssueSuccessDialogProps;

const IssueSuccessDialog: React.FC<Props> = ({ stamp, open, scanFirst = false, onClose }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const theme = useTheme();
  const navigate = useNavigate();
  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <>
      <Global
        styles={{
          ".MuiPaper-root.MuiDialog-paper": {
            overflowY: "visible",
          },
        }}
      ></Global>
      <Dialog open={open} sx={{ overflow: "none" }}>
        <Box sx={{ position: "relative" }}>
          <DialogContent sx={{ py: 4 }}>
            <Stack className="center" spacing={1}>
              <Stack direction="row" alignItems={"center"} spacing={1}>
                <Typography variant="h5" color="secondary.main" fontWeight="bolder">
                  Awesome!
                  {/* <FontAwesomeIcon icon={faCheckCircle} color={theme.palette.secondary.main} /> */}
                </Typography>
                <svg className="issue_success" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                  <circle
                    className="path circle"
                    fill="none"
                    stroke={theme.palette.secondary.main}
                    strokeWidth="6"
                    strokeMiterlimit="10"
                    cx="65.1"
                    cy="65.1"
                    r="62.1"
                  />
                  <polyline
                    className="path check"
                    fill="none"
                    stroke={theme.palette.secondary.main}
                    strokeWidth="15"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    points="100.2,40.2 51.5,88.8 29.8,67.5 "
                  />
                </svg>
              </Stack>
              <Typography variant="body1" color="text.primary" fontWeight="bolder" pb={1} textAlign="center">
                {stamp} stamp{stamp > 1 ? "s" : ""} has been issued successfully!
              </Typography>

              <Stack direction="row" spacing={1}>
                <Button color="secondary" variant="outlined" onClick={() => navigate(routes.MERCHANT_HOME)}>
                  Home
                </Button>
                {scanFirst ? (
                  <Button color="secondary" variant="contained" onClick={() => navigate(routes.MERCHANT_SCAN, { state: { scanFirst: scanFirst } })}>
                    New Scan
                  </Button>
                ) : (
                  <Button color="secondary" variant="contained" onClick={() => navigate(routes.MERCHANT_STAMP)}>
                    New Stamp
                  </Button>
                )}
              </Stack>
            </Stack>
          </DialogContent>
        </Box>
      </Dialog>
    </>
  );
};

export default IssueSuccessDialog;
