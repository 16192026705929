import "./App.css";
// import { useTranslation } from "react-i18next";
import routes from "src/routes";
import Scan from "src/pages/scan";
import SignUp from "src/pages/sign-up";
import { Global } from "@emotion/react";
import Login from "src/pages/admin/login";
import { useCreateTheme } from "src/theme";
import HomePage from "src/pages/home-page";
import Stores from "src/pages/stores/index";
import Member from "src/pages/admin/members";
import { ThemeProvider } from "@mui/material";
import Activities from "src/pages/activities";
import Loyalty from "src/pages/admin/loyalty";
import GetStarted from "src/pages/get-started";
import AdminRoute from "src/routes/admin-route";
import Dashboard from "src/pages/admin/dashboard";
import CardDetails from "src/pages/cards/card-details";
import StoreDetails from "src/pages/stores/store-details";
import Configuration from "src/pages/admin/configuration";
import { Routes, Navigate, Route } from "react-router-dom";
import PersonalCardsList from "src/pages/cards/personal-cards-list";
import MerchantLogin from "src/pages/merchant/login";
import MerchantHomePage from "src/pages/merchant/home-page";
import MerchantLoyaltyPrograms from "src/pages/merchant/loyalty-programs";
import MerchantActivties from "src/pages/merchant/activties";
import MerchantLoyaltyProgramDetails from "src/pages/merchant/loyalty-program-details";
import MerchantStamp from "src/pages/merchant/merchant-stamp";
import MerchantScan from "src/pages/merchant/merchant-scan";
import MerchantScanConfirm from "src/pages/merchant/merchant-scan-confirm";
import MerchantUserRedeem from "src/pages/merchant/merchant-user-redeem";
import StoreLocations from "src/pages/stores/store-locations";

function App(props: any) {
  // const { t } = useTranslation();

  return (
    <ThemeProvider theme={useCreateTheme({ mode: "light" })}>
      <Global
        styles={{
          ".center": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
        }}
      />
      <div className="App">
        <Routes>
          <Route path={routes.ROOT} element={<GetStarted />} />
          <Route path={routes.LOGIN} element={<Login />} />
          <Route path={routes.HOME} element={<HomePage />} />
          <Route path={routes.SCAN} element={<Scan />} />
          <Route path={routes.STORES} element={<Stores />} />
          <Route path={`${routes.STORES}/:id`} element={<StoreDetails />} />
          <Route path={`${routes.STORES}/:id/${routes.LOCATIONS}`} element={<StoreLocations />} />
          <Route path={`${routes.CARDS}/:id`} element={<CardDetails />} />
          <Route path={routes.PERSONAL_CARDS} element={<PersonalCardsList />} />
          <Route path={routes.ACTIVITIES} element={<Activities />} />
          <Route path={routes.SIGNUP} element={<SignUp />} />
          <Route path={routes.MERCHANT_LOGIN} element={<MerchantLogin />} />
          <Route path={routes.MERCHANT_SCAN} element={<MerchantScan />} />
          <Route path={routes.MERCHANT_SCAN_CONFIRM} element={<MerchantScanConfirm />} />
          <Route path={routes.MERCHANT_HOME} element={<MerchantHomePage />} />
          <Route path={routes.MERCHANT_STAMP} element={<MerchantStamp />} />
          <Route path={routes.MERCHANT_USER_REDEEM} element={<MerchantUserRedeem />} />
          <Route path={routes.MERCHANT_ACTIVITIES} element={<MerchantActivties />} />
          <Route path={routes.MERCHANT_LOYALTY_PROGRAMS} element={<MerchantLoyaltyPrograms />} />
          <Route path={`${routes.MERCHANT_LOYALTY_PROGRAMS}/:id`} element={<MerchantLoyaltyProgramDetails />} />
          <Route path={routes.DASHBOARD} element={<AdminRoute component={Dashboard} />} />\
          <Route path={routes.LOYALTY} element={<AdminRoute component={Loyalty} />} />
          <Route path={routes.CONFIGURATION} element={<AdminRoute component={Configuration} />} />
          <Route path={routes.MEMBER} element={<AdminRoute component={Member} />} />
          <Route path={"*"} element={<Navigate to={routes.ROOT} replace />} />
        </Routes>
      </div>
    </ThemeProvider>
  );
}

export default App;
