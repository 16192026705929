import React from "react";
/* components */
import TopNavbar from "src/components/common/navigation/top-navbar";
import BottomNavbar from "src/components/common/navigation/bottom-navbar";
import CustomContainer from "src/components/common/data-display/custom-container";
/* 3rd party lib */
import { Box, Stack, Container, Typography, useTheme } from "@mui/material";
import { redGift, starYellow } from "src/utils/constants";
import useMediaQueries from "src/hooks/use-mediaqueries";
/* Util */
interface ActivitiesProps {}

type Props = ActivitiesProps;

const Activities: React.FC<Props> = () => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const theme = useTheme();
  const { smDown } = useMediaQueries();
  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <CustomContainer>
      <TopNavbar />
      <Stack
        sx={{
          width: smDown ? "100vw" : "420px",
          background: theme.palette.primary.light,
          // borderRadius: "50%",
          pt: 8,
          height: "240px",
          clipPath: smDown ? "circle(100% at 50% -50vw)" : " circle(100% at 50% -210px)",
        }}
        spacing={2}
      >
        <Typography variant="h5" fontWeight={"bolder"} color="primary.main">
          My Activity
        </Typography>
      </Stack>

      <Container sx={{ marginTop: 3, transform: "translateY(-140px)" }}>
        <Typography textAlign={"start"} variant="body1" fontWeight={"bolder"} color="primary.main">
          Transaction History
        </Typography>

        <Stack spacing={2} mt={3}>
          <Stack direction="row" justifyContent={"space-between"} alignItems="center">
            <Stack direction="row" alignItems="center">
              <Box width="52px">
                <span style={{ color: starYellow }}>
                  <i className="fak fa-star fa-xl"></i>
                </span>
              </Box>
              <Stack alignItems={"flex-start"}>
                <Typography variant="body1" color="text.primary" fontWeight={"bolder"}>
                  Received Stamp
                </Typography>
                <Typography variant="caption" color="text.primary">
                  05 Feb 2023, 09:00AM
                </Typography>
              </Stack>
            </Stack>
            <Box>
              <Typography variant="body1" color="text.primary" fontWeight={"bolder"}>
                +1
              </Typography>
            </Box>
          </Stack>

          <Stack direction="row" justifyContent={"space-between"} alignItems="center">
            <Stack direction="row" alignItems="center">
              <Box width="52px">
                <span style={{ color: redGift }}>
                  <i className="fak fa-gift fa-xl"></i>
                </span>
              </Box>
              <Stack alignItems={"flex-start"}>
                <Typography variant="body1" color="text.primary" fontWeight={"bolder"}>
                  Get 1 bread
                </Typography>
                <Typography variant="caption" color="text.primary">
                  05 Feb 2023, 09:00AM
                </Typography>
              </Stack>
            </Stack>
            <Box>
              <Typography variant="body1" color="text.primary" fontWeight={"bolder"}>
                Claimed
              </Typography>
            </Box>
          </Stack>

          <Stack direction="row" justifyContent={"space-between"} alignItems="center">
            <Stack direction="row" alignItems="center">
              <Box width="52px">
                <span style={{ color: starYellow }}>
                  <i className="fak fa-star fa-xl"></i>
                </span>
              </Box>
              <Stack alignItems={"flex-start"}>
                <Typography variant="body1" color="text.primary" fontWeight={"bolder"}>
                  Received Stamp
                </Typography>
                <Typography variant="caption" color="text.primary">
                  05 Feb 2023, 09:00AM
                </Typography>
              </Stack>
            </Stack>
            <Box>
              <Typography variant="body1" color="text.primary" fontWeight={"bolder"}>
                +1
              </Typography>
            </Box>
          </Stack>
          <Stack direction="row" justifyContent={"space-between"} alignItems="center">
            <Stack direction="row" alignItems="center">
              <Box width="52px">
                <span style={{ color: starYellow }}>
                  <i className="fak fa-star fa-xl"></i>
                </span>
              </Box>
              <Stack alignItems={"flex-start"}>
                <Typography variant="body1" color="text.primary" fontWeight={"bolder"}>
                  Received Stamp
                </Typography>
                <Typography variant="caption" color="text.primary">
                  05 Feb 2023, 09:00AM
                </Typography>
              </Stack>
            </Stack>
            <Box>
              <Typography variant="body1" color="text.primary" fontWeight={"bolder"}>
                +1
              </Typography>
            </Box>
          </Stack>
        </Stack>
      </Container>

      <BottomNavbar />
    </CustomContainer>
  );
};

export default Activities;
