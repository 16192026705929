import _ from "lodash";
import { FormEvent } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography, useMediaQuery } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import useMediaQueries from "src/hooks/use-mediaqueries";
import { getTranslateString } from "src/utils/translate";
import { BUTTON_RESET, FILTER } from "src/constants/translate-keys/common";
import { useTranslation } from "react-i18next";

interface FilterDialogProps {
  open: boolean;
  onClose: () => void;
  onReset: (e: any) => void;
  onFilter: (e?: FormEvent<HTMLFormElement> | undefined) => void;
  children: React.ReactNode;
}

type Props = FilterDialogProps;

const FilterDialog: React.FC<Props> = ({ open, onClose, onReset, onFilter, children }) => {
  const { lgUp } = useMediaQueries();
  const { t } = useTranslation();
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth fullScreen={!lgUp}>
      <form onSubmit={onFilter} style={{ height: "100%" }}>
        <DialogTitle>
          <Grid container spacing={2}>
            <Grid item xs>
              <Typography variant="inherit" sx={{ flexGrow: 1 }}>
                {getTranslateString(t, FILTER)}
              </Typography>
            </Grid>
            <Grid item xs="auto">
              <IconButton onClick={onClose}>
                <CloseRoundedIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onReset} size="small">
            {getTranslateString(t, BUTTON_RESET)}
          </Button>
          <Button fullWidth type="submit" color="primary" variant="contained" size="small">
            Apply filters
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default FilterDialog;
