import React, { useState } from "react";
/* components */
import routes from "src/routes";
import Store1 from "src/images/store1.png";
import Store2 from "src/images/store2.png";
import Store3 from "src/images/store3.png";
import Store4 from "src/images/store4.png";
import Store5 from "src/images/store5.png";
import Store6 from "src/images/store6.png";
import Store from "src/images/store.png";
import Blue from "src/images/blue.png";
import TopNavbar from "src/components/common/navigation/top-navbar";
import BottomNavbar from "src/components/common/navigation/bottom-navbar";
import CustomContainer from "src/components/common/data-display/custom-container";
import Pink from "src/images/pink.png";

/* 3rd party lib */
import {
  Box,
  useTheme,
  Stack,
  Typography,
  TextField,
  styled,
  Container,
  Grid,
  Card,
  CardMedia,
  ToggleButtonGroup,
  ToggleButton,
  CardContent,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment/InputAdornment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/pro-regular-svg-icons";
import { faList } from "@fortawesome/pro-solid-svg-icons";
import { faGrid2 } from "@fortawesome/pro-solid-svg-icons";
import { useNavigate } from "react-router-dom";
/* Util */

const StyledTextField = styled(TextField)(() => ({
  width: "100%",
  ".MuiInputBase-input": {
    padding: "8px 16px",
    width: "100%",
  },
}));

interface StoresProps {}

type Props = StoresProps;

const Stores: React.FC<Props> = () => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const theme = useTheme();
  const navigate = useNavigate();
  const [view, setView] = useState<"grid" | "list">("grid");
  const images = [
    { name: "Wataa Bakery", img: Store1, id: 1 },
    { name: "Bread History", img: Store2, id: 2 },
    { name: "Frucci", img: Store3, id: 3 },
    { name: "Yole", img: Store4, id: 4 },
    { name: "Purpur", img: Store5, id: 5 },
    { name: "Kimage", img: Store6, id: 6 },
  ];

  /* ================================================== */
  /*  method */
  /* ================================================== */

  const expandStars = (delay: number) => {
    return {
      position: "absolute",
      animation: `starExpands 1.5s linear infinite ${delay}s`,
      "@keyframes starExpands": {
        "0%": {
          transform: "scale(0.5)",
        },
        "50%": {
          transform: "scale(1)",
        },
        "100%": {
          transform: "scale(0.5)",
        },
      },
    };
  };

  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <CustomContainer>
      <TopNavbar />
      <Box sx={{ position: "relative" }}>
        <Stack
          sx={{
            width: "420px",
            height: "350px",
            background: theme.palette.primary.light,
            // borderRadius: "50%",
            padding: "60px 50px 0px 50px",
            clipPath: "circle(75% at 50% 0)",
          }}
          spacing={2}
          alignItems="center"
          justifyContent={"flex-start"}
        >
          <Stack>
            <Typography lineHeight="24px" variant="h5" color="primary.main" fontWeight={"bolder"}>
              PARTICIPATING <br />
              PARTNERS
            </Typography>
          </Stack>
        </Stack>
        <Box sx={{ position: "absolute", bottom: "10px", left: "50%", transform: "translateX(-50%)" }}>
          <Box sx={{ position: "relative" }}>
            <Box sx={{ ...expandStars(0.5), left: "-10px", top: "70px" }}>
              <i className="fak fa-diamond" style={{ color: "white", width: "8px", height: "8px" }}></i>
            </Box>
            <Box sx={{ ...expandStars(1), left: "30px", top: "20px" }}>
              <i className="fak fa-diamond" style={{ color: "white", width: "8px", height: "8px" }}></i>
            </Box>
            <Box sx={{ ...expandStars(0.2), right: "-30px", top: "50px" }}>
              <i className="fak fa-diamond" style={{ color: "white", width: "8px", height: "8px" }}></i>
            </Box>
            <Box sx={{ ...expandStars(0.5), left: "10px", top: "120px" }}>
              <i className="fak fa-blink" style={{ color: "white", width: "30px", height: "30px" }}></i>
            </Box>
            <Box sx={{ ...expandStars(1.5), right: "0px", top: "150px" }}>
              <i className="fak fa-diamond" style={{ color: "white", width: "8px", height: "8px" }}></i>
            </Box>
            <Box sx={{ ...expandStars(2), right: "100px", top: "30px" }}>
              <i className="fak fa-blink" style={{ color: "white", width: "20px", height: "20px" }}></i>
            </Box>
            <Box
              sx={{
                ...expandStars(0),
                right: "10px",
                top: "80px",
              }}
            >
              <i className="fak fa-blink" style={{ color: "white", width: "15px", height: "15px" }}></i>
            </Box>

            <img src={Store} alt="whitediamond" width="320px" />
            <Box
              sx={{
                position: "absolute",
                left: "50px",
                bottom: "52px",
                transformOrigin: "center",
                animation: `blueLeft 2s ease infinite`,
                "@keyframes blueLeft": {
                  "0%": {
                    transform: "rotate(-15deg)",
                  },
                  "50%": {
                    transform: "rotate(-12deg)",
                  },
                  "100%": {
                    transform: "rotate(-15deg)",
                  },
                },
              }}
            >
              <img src={Blue} alt="blue" width="70px" />
            </Box>
            <Box
              sx={{
                position: "absolute",
                right: "56px",
                top: "-10px",
                transform: "rotate(15deg)",
                transformOrigin: "center",
                animationDelay: 1,
                animation: `rightPink 2s ease infinite`,
                "@keyframes rightPink": {
                  "0%": {
                    transform: "rotate(15deg)",
                  },
                  "50%": {
                    transform: "rotate(12deg)",
                  },
                  "100%": {
                    transform: "rotate(15deg)",
                  },
                },
              }}
            >
              <img src={Pink} alt="pink" width="50px" />
            </Box>
          </Box>
        </Box>
      </Box>
      <Container sx={{ pb: 10 }}>
        <Stack direction="row" spacing={1} mt={4} mb={1} sx={{ width: "100%" }}>
          <StyledTextField
            hiddenLabel
            variant="outlined"
            name="search"
            placeholder="Search"
            value={""}
            // onChange={formik.handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <FontAwesomeIcon icon={faMagnifyingGlass} />
                </InputAdornment>
              ),
              style: {
                borderRadius: "50px",
              },
            }}
          />
          <ToggleButtonGroup
            value={view}
            exclusive
            onChange={() => {
              if (view === "list") {
                setView("grid");
              } else {
                setView("list");
              }
            }}
            aria-label="view"
          >
            {view === "grid" && (
              <ToggleButton value="list" aria-label="list view" sx={{ border: 0 }}>
                <FontAwesomeIcon icon={faList} />
              </ToggleButton>
            )}
            {view === "list" && (
              <ToggleButton value="grid" aria-label="calendar view" sx={{ border: 0 }}>
                <FontAwesomeIcon icon={faGrid2} />
              </ToggleButton>
            )}
          </ToggleButtonGroup>
        </Stack>
        <Box mt={2}>
          {view === "grid" ? (
            <Grid container spacing={2}>
              {images.map((child, index) => (
                <Grid
                  item
                  xs={6}
                  key={index}
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(`${routes.STORES}/${child.id}`);
                  }}
                >
                  <Card
                    sx={{
                      border: `1px solid transparent`,
                      "&:hover": {
                        border: `1px solid ${theme.palette.primary.light}`,
                      },
                    }}
                  >
                    <CardMedia sx={{ height: 112 }} image={child.img} />
                  </Card>
                  <Typography variant="caption" color="text.primary">
                    {child.name}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Stack spacing={2}>
              {images.map((child, index) => (
                <Card
                  sx={{
                    display: "flex",
                    height: 70,
                    cursor: "pointer",
                    border: `1px solid transparent`,
                    "&:hover": {
                      border: `1px solid ${theme.palette.primary.light}`,
                    },
                  }}
                  key={index}
                  onClick={() => {
                    navigate(`${routes.STORES}/${child.id}`);
                  }}
                >
                  <CardMedia sx={{ height: "100%", width: "30%" }} image={child.img} />
                  <CardContent
                    sx={{ display: "flex", width: "70%", alignItems: "center", justifyContent: "center", "&:last-child": { paddingBottom: "16px" } }}
                  >
                    <Typography component="div" variant="h6">
                      {child.name}
                    </Typography>
                  </CardContent>
                </Card>
              ))}
            </Stack>
          )}
        </Box>
      </Container>
      <BottomNavbar />
    </CustomContainer>
  );
};

export default Stores;
