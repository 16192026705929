const ROOT_PATH = "/";
const ADMIN_PATH = "/admin";
const LOGIN_PATH = "/login";
const DASHBOARD_PATH = "/dashboard";
const LOYALTY_PATH = `/loyalty`;
const CONFIGURATION_PATH = `/configuration`;
const MEMBER_PATH = `/member`;
const CARDS_PATH = `/cards`;
const MERCHANT_PATH = `/merchant`;

const routes = {
  ROOT: ROOT_PATH,
  LOGIN: LOGIN_PATH,
  CARDS: CARDS_PATH,
  SCAN: "/scan",
  HOME: "/home",
  SIGNUP: "/signup",
  STORES: "/stores",
  LOCATIONS: "/locations",
  FORGOT: "/forget-password",
  ACTIVITIES: "/activities",
  MEMBER: `${ADMIN_PATH}${MEMBER_PATH}`,
  LOYALTY: `${ADMIN_PATH}${LOYALTY_PATH}`,
  PERSONAL_CARDS: `${CARDS_PATH}/personal`,
  DASHBOARD: `${ADMIN_PATH}${DASHBOARD_PATH}`,
  CONFIGURATION: `${ADMIN_PATH}${CONFIGURATION_PATH}`,
  MERCHANT_LOGIN: `${MERCHANT_PATH}/login`,
  MERCHANT_HOME: `${MERCHANT_PATH}/home`,
  MERCHANT_SCAN: `${MERCHANT_PATH}/scan`,
  MERCHANT_SCAN_CONFIRM: `${MERCHANT_PATH}/scan/confirm`,
  MERCHANT_USER_REDEEM: `${MERCHANT_PATH}/redeem`,
  MERCHANT_STAMP: `${MERCHANT_PATH}/stamp`,
  MERCHANT_ACTIVITIES: `${MERCHANT_PATH}/activities`,
  MERCHANT_LOYALTY_PROGRAMS: `${MERCHANT_PATH}/loyalty-programs`,
};

export default routes;
