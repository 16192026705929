import React from "react";
/* components */
/* 3rd party lib */
import { Global } from "@emotion/react";
import { Box, Button, Dialog, DialogContent, Stack, Typography } from "@mui/material";
/* Util */
import "./index.scss";
import { useNavigate } from "react-router-dom";
import routes from "src/routes";

interface IssueConfirmDialogProps {
  title: string;
  logo: any;
  open: boolean;
  stamp: number;
  scanFirst?: boolean;
  onClose: () => void;
  onClick: () => void;
}

type Props = IssueConfirmDialogProps;

const IssueConfirmDialog: React.FC<Props> = ({ stamp, open, onClose, onClick }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */

  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <>
      <Global
        styles={{
          ".MuiPaper-root.MuiDialog-paper": {
            overflowY: "visible",
          },
        }}
      ></Global>
      <Dialog open={open} sx={{ overflow: "none" }}>
        <Box sx={{ position: "relative" }}>
          <DialogContent sx={{ py: 4 }}>
            <Stack className="center" spacing={1}>
              <Stack direction="row" alignItems={"center"} spacing={1}>
                <Typography variant="h5" color="secondary.main" fontWeight="bolder">
                  Attention!
                </Typography>
              </Stack>
              <Typography variant="body1" color="text.primary" fontWeight="bolder" pb={1} textAlign="center">
                {stamp} stamp{stamp > 1 ? "s" : ""} will be issued to USER X, are you sure?
              </Typography>

              <Stack direction="row" spacing={1}>
                <Button sx={{ px: 5 }} color="secondary" variant="outlined" onClick={onClose}>
                  No
                </Button>
                <Button sx={{ px: 5 }} color="secondary" variant="contained" onClick={onClick}>
                  Yes
                </Button>
              </Stack>
            </Stack>
          </DialogContent>
        </Box>
      </Dialog>
    </>
  );
};

export default IssueConfirmDialog;
