import React, { useEffect, useRef, useState } from "react";
/* components */
/* 3rd party lib */
import { RootState } from "src/store";
import { useDispatch, useSelector } from "react-redux";
import { setPageHeaderHeight } from "src/slices/layout";
import useMediaQueries from "src/hooks/use-mediaqueries";
import Breadcrumbs from "src/components/common/navigation/breadcrumbs";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import { Box, Divider, Grid, IconButton, Paper, styled, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

/* Util */

interface RootProps {
  show?: string;
  contentpadding: number;
  navbarheight: number;
}

const StyledPaper = styled(Paper, {
  shouldForwardProp: props => props !== "show" && props !== "contentpadding" && props !== "navbarheight",
})<RootProps>(({ theme, show, contentpadding, navbarheight }) => ({
  zIndex: theme.zIndex.appBar - 1,
  transition: "all 1s ease",
  marginBottom: 16,
  WebkitTransform: "translate3d(0, 0, 0)",
  [theme.breakpoints.down("lg")]: {
    borderRadius: 0,
    left: 0,
    margin: "-24px 0 0",
    position: "fixed",
    width: "100%",
    top: show === "true" ? `${navbarheight + 24}px` : `-${contentpadding - 24}px`,
  },
  [theme.breakpoints.down("sm")]: {
    left: 0,
    margin: "-16px 0 0",
    top: show === "true" ? `${navbarheight + 16}px` : `-${contentpadding - 16}px`,
  },
}));

interface PageHeaderLayoutProps {
  title: string;
  showBreadCrumb?: boolean;
  children: React.ReactNode;
  actions?: React.ReactNode;
  overline?: React.ReactNode;
  subheader?: React.ReactNode;
}

type Props = PageHeaderLayoutProps;

const PageHeaderLayout: React.FC<Props> = ({ actions, showBreadCrumb = true, children, overline, subheader, title }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const [contentPadding, setContentPadding] = useState(0);
  const pageHeaderRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const theme = useTheme();
  const { navbar } = useSelector((state: RootState) => state.layout);
  const { lgUp, smDown } = useMediaQueries();
  const dispatch = useDispatch();

  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */
  useEffect(() => {
    if (pageHeaderRef.current) {
      setContentPadding(pageHeaderRef.current.clientHeight);
      dispatch(setPageHeaderHeight(pageHeaderRef.current.clientHeight));
    }
  }, [lgUp, smDown]);

  /* ================================================== */
  /* ================================================== */
  return (
    <>
      <StyledPaper show={navbar.show.toString()} navbarheight={navbar.height} contentpadding={contentPadding} ref={pageHeaderRef}>
        {lgUp && showBreadCrumb && (
          <>
            <Box px={2} py={1}>
              <Breadcrumbs />
            </Box>
            <Divider />
          </>
        )}
        <Box padding={smDown ? 1 : 2}>
          <Grid alignItems="center" container spacing={3}>
            <Grid item xs>
              <Grid alignItems="center" container spacing={2}>
                {!lgUp && (
                  <Grid item xs="auto">
                    <Box sx={{ position: "relative" }}>
                      <Box
                        onClick={() => navigate(-1)}
                        sx={{
                          position: "absolute",
                          left: "-20px",
                          bottom: "-20px",
                          width: smDown ? "60px" : "140px",
                          height: "100px",
                        }}
                      />
                      <IconButton onClick={() => navigate(-1)} sx={{ padding: smDown ? 0 : 1 }}>
                        <ArrowBackIosNewRoundedIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  </Grid>
                )}
                <Grid item xs>
                  {overline && overline}
                  {typeof title === "string" ? (
                    <Typography textAlign="left" fontSize={lgUp ? 20 : smDown ? 14 : 16} fontWeight={700}>
                      {title}
                    </Typography>
                  ) : (
                    title
                  )}
                  {subheader && <Box marginTop={smDown ? 0.2 : 1}>{subheader}</Box>}
                </Grid>
              </Grid>
            </Grid>
            {actions && (
              <Grid item xs="auto">
                {actions}
              </Grid>
            )}
          </Grid>
        </Box>
      </StyledPaper>
      <Box
        sx={{
          [theme.breakpoints.down("lg")]: {
            paddingTop: `${contentPadding}px`,
          },
        }}
      >
        {children}
      </Box>
    </>
  );
};

export default PageHeaderLayout;
