import { Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box/Box";
import React from "react";
/* components */
/* 3rd party lib */
/* Util */
interface MerchantLoyaltyActivityCardProps {}

type Props = MerchantLoyaltyActivityCardProps;

const MerchantLoyaltyActivityCard: React.FC<Props> = () => {
  /* ================================================== */
  /*  state */
  /* ================================================== */

  /* ================================================== */
  /*  method */
  /* ================================================== */

  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Stack alignItems={"flex-start"} sx={{ pl: 1 }}>
        <Box sx={{ textAlign: "left" }}>
          <Typography sx={{ display: "inline-block" }} variant="body1" color="text.primary" fontWeight={"bolder"}>
            X
          </Typography>
          &nbsp;stamps issued to User B&nbsp;
        </Box>
        <Typography variant="caption" color="text.primary">
          05 Feb 2023, 09:00AM - Employee A
        </Typography>
      </Stack>
    </Stack>
  );
};

export default MerchantLoyaltyActivityCard;
