import React, { useState } from "react";
/* components */
import CustomContainer from "src/components/common/data-display/custom-container";
import MerchantTopNavbar from "src/components/common/navigation/merchant-top-navbar";
/* 3rd party lib */
import {
  styled,
  Container,
  Stack,
  Typography,
  useTheme,
  FormControl,
  InputAdornment,
  TextField,
  Link,
  Box,
  Grid,
  Drawer,
  Button,
  LinearProgress,
} from "@mui/material";
import useMediaQueries from "src/hooks/use-mediaqueries";
/* Util */
import { BUTTON_NEXT } from "src/constants/translate-keys/common";
import { getTranslateString } from "src/utils/translate";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { useFormik } from "formik";
import Stamp, { IStampStatus } from "src/components/loyalty/stamp";
import { totalCards } from "src/components/common/data-display/loyalty-cards-list";
import IssueSuccessDialog from "src/components/common/feedback/issue-success-dialog";
import IssueConfirmDialog from "src/components/common/feedback/issue-confirm-dialog";

const StyledTextField = styled(TextField)(() => ({
  input: {
    textAlign: "center",
    width: "100px",
    "&::-webkit-outer-spin-button": {
      display: "none",
    },
    "&::-webkit-inner-spin-button": {
      display: "none",
    },
    fontSize: "1.5rem",
  },

  "& .MuiOutlinedInput-root": {
    background: "white",
  },
}));

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: "10px",
  borderRadius: "50px",
  background: theme.palette.secondary.light,
  "& .MuiLinearProgress-bar": {
    borderRadius: "50px",
    background: theme.palette.secondary.main,
  },
}));

interface MerchantUserRedeemProps {}

type Props = MerchantUserRedeemProps;

const MerchantUserRedeem: React.FC<Props> = () => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const theme = useTheme();
  const { t } = useTranslation();
  const { smDown } = useMediaQueries();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);

  const formik = useFormik({
    validateOnChange: false,
    initialValues: {
      stamp: 1,
      transaction: "",
    },
    onSubmit: async values => {},
  });

  /* ================================================== */
  /*  method */
  /* ================================================== */
  const handleIncrementStamp = () => {
    if (isNaN(formik.values.stamp)) {
      // if not number
      formik.setFieldValue("stamp", 1);
      return;
    }

    if (typeof formik.values.stamp === "string") {
      if (parseInt(formik.values.stamp) + totalCards[0].stampedAt === totalCards[0].totalStars) return;
      formik.setFieldValue("stamp", parseInt(formik.values.stamp) + 1);
    } else {
      if (formik.values.stamp + totalCards[0].stampedAt === totalCards[0].totalStars) return;
      formik.setFieldValue("stamp", formik.values.stamp + 1);
    }
  };
  const handleDecrementStamp = () => {
    if (isNaN(formik.values.stamp)) {
      // if not number
      formik.setFieldValue("stamp", 1);
      return;
    }
    if (typeof formik.values.stamp === "string") {
      if (parseInt(formik.values.stamp) === 1) return;
      formik.setFieldValue("stamp", parseInt(formik.values.stamp) - 1);
    } else {
      if (formik.values.stamp === 1) return;
      formik.setFieldValue("stamp", formik.values.stamp - 1);
    }
  };
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  let cardObj = { ...totalCards[0] };

  let issuingNumber = formik.values.stamp ? formik.values.stamp : 0;

  if (!isNaN(formik.values.stamp)) {
    if (formik.values.stamp + cardObj.stampedAt > cardObj.totalStars) {
      issuingNumber = totalCards[0].totalStars - totalCards[0].stampedAt; // set to max - current
      cardObj.stampedAt = totalCards[0].totalStars; // set to max
    } else {
      cardObj.stampedAt += formik.values.stamp; // add stamp
      issuingNumber = formik.values.stamp;
    }
  }

  /* ================================================== */
  /* ================================================== */
  return (
    <>
      <CustomContainer>
        <MerchantTopNavbar back version="curve" />
        <Stack
          sx={{
            paddingTop: "52px",
            width: smDown ? "100vw" : "420px",
            height: "180px",
            background: theme.palette.primary.light,
            // borderRadius: "50%",,
            clipPath: `circle(90% at 50% ${smDown ? "-12vw" : "-80px"})`,
            backgroundImage: `url(${totalCards[0].image})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          spacing={2}
          alignItems="center"
          justifyContent={"flex-start"}
        ></Stack>
        <Container sx={{ mt: 0, pb: 10 }}>
          <Grid container spacing={1} mt={1}>
            <Grid item xs={12}>
              <Typography variant="h5" fontWeight={"bolder"} color="secondary.main">
                {totalCards[0].title}
              </Typography>
              <Typography variant="body2" color="text.primary">
                Expired by {moment(totalCards[0].expiredDate).format("DD MMM YYYY")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography fontWeight={"bolder"} variant="body1" textAlign="left" color="text.primary">
                Assign Stamps
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Stack
                direction="row"
                alignItems="stretch"
                justifyContent={"space-around"}
                sx={{ background: theme.palette.secondary.main, py: 1, borderRadius: "16px" }}
              >
                <Box onClick={() => handleDecrementStamp()} sx={{ width: "30%", color: "white", display: "grid", placeItems: "center" }}>
                  <FontAwesomeIcon
                    icon={faMinus}
                    size="xl"
                    style={{
                      opacity:
                        typeof formik.values.stamp === "string"
                          ? parseInt(formik.values.stamp) === 1
                            ? 0.3
                            : 1
                          : formik.values.stamp === 1
                          ? 0.3
                          : 1,
                    }}
                  />
                </Box>
                <Box sx={{ width: "40%" }}>
                  <FormControl>
                    <StyledTextField
                      InputProps={{ inputProps: { min: 1, max: totalCards[0].totalStars } }}
                      type="number"
                      hiddenLabel
                      variant="outlined"
                      name="stamp"
                      onChange={e => formik.setFieldValue("stamp", parseInt(e.target.value))}
                      value={formik.values.stamp}
                    />
                  </FormControl>
                </Box>
                <Box
                  onClick={() => {
                    handleIncrementStamp();
                  }}
                  sx={{ width: "30%", color: "white", display: "grid", placeItems: "center" }}
                >
                  <FontAwesomeIcon
                    icon={faPlus}
                    size="xl"
                    style={{
                      opacity:
                        typeof formik.values.stamp === "string"
                          ? parseInt(formik.values.stamp) + totalCards[0].stampedAt === totalCards[0].totalStars
                            ? 0.3
                            : 1
                          : formik.values.stamp + totalCards[0].stampedAt === totalCards[0].totalStars
                          ? 0.3
                          : 1,
                    }}
                  />
                </Box>
              </Stack>
            </Grid>

            {/* <Grid item xs={12}>
            <Typography color="secondary" variant="subtitle1" mb={1}>
              Issuing <b>{issuingNumber}</b> stamp{formik.values.stamp > 1 ? "s" : ""}
            </Typography>
          </Grid> */}

            <Grid item xs={12} mt={3} sx={{ display: "grid", placeItems: "center", userSelect: "none" }}>
              <Box sx={{ width: smDown ? "80vw" : "300px", height: "auto" }}>
                <Grid container rowSpacing={1} sx={{ width: "100%", height: "100%" }}>
                  {Array(totalCards[0].totalStars)
                    .fill(0)
                    .map((_child, index) => {
                      let stampType: IStampStatus = "inactive_star";

                      let stampAt = !isNaN(cardObj.stampedAt) ? cardObj.stampedAt : totalCards[0].stampedAt;

                      // inactive star when index is greater than currently stamped position, and not last stamp, and also not gifts
                      if (index > stampAt - 1 && index !== cardObj.totalStars - 1 && !cardObj.gift.includes(index + 1)) {
                        stampType = "inactive_star";
                      }
                      // active star when index is less than currently stamped position, and not last stamp, and also not gifts
                      if (index <= stampAt - 1 && index !== cardObj.totalStars - 1 && !cardObj.gift.includes(index + 1)) {
                        stampType = "active_star";
                      }
                      // inactive gift when index is greater than currently stamped position, and the array includes the index
                      if (index > stampAt - 1 && cardObj.gift.includes(index + 1)) {
                        stampType = "inactive_gift";
                      }
                      // active gift when index is less than currently stamped position, and the array includes the index
                      if (index <= stampAt - 1 && cardObj.gift.includes(index + 1)) {
                        stampType = "active_gift";
                      }
                      if (stampAt - 1 < cardObj.totalStars - 1 && index === cardObj.totalStars - 1) {
                        stampType = "inactive_trophy";
                      }
                      if (index === stampAt - 1 && index === cardObj.totalStars - 1) {
                        stampType = "active_trophy";
                      }

                      return (
                        <Grid key={index} item xs={12 / 5} className="center" sx={{ paddingLeft: 0 }}>
                          <Stamp
                            size={1}
                            status={stampType}
                            newStamp={index + 1 > totalCards[0].stampedAt}
                            onClick={() => {
                              // click on index 5,
                              // old stampAt is at 3,
                              // so stamp is 5 - 3 = 2
                              formik.setFieldValue("stamp", index + 1 - totalCards[0].stampedAt);
                            }}
                          />
                        </Grid>
                      );
                    })}
                </Grid>
                <Stack mt={2} direction="row" alignItems={"center"} justifyContent={"space-between"}>
                  {
                    <StyledLinearProgress
                      variant="determinate"
                      value={((!isNaN(cardObj.stampedAt) ? cardObj.stampedAt : totalCards[0].stampedAt) / totalCards[0].totalStars) * 100}
                      sx={{ width: "80%" }}
                    />
                  }
                  <Stack>
                    <Typography variant="h6" fontWeight="light" lineHeight={0.8}>
                      <b>{!isNaN(cardObj.stampedAt) ? cardObj.stampedAt : totalCards[0].stampedAt}</b> / {totalCards[0].totalStars}
                    </Typography>
                    <Typography variant="caption">Stamped</Typography>
                  </Stack>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={12} sx={{ userSelect: "none" }}>
              <Typography pt={2} fontWeight={"bolder"} variant="body1" textAlign="left" color="text.primary">
                Optional
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ userSelect: "none" }}>
              <FormControl fullWidth>
                <TextField
                  placeholder="Transaction Amount"
                  name="transaction"
                  hiddenLabel
                  variant="outlined"
                  value={formik.values.transaction}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={{ userSelect: "none" }}>
              <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                <Link
                  // href={routes.SIGNUP}
                  variant="body2"
                  color="text.primary"
                  underline="hover"
                  sx={{ cursor: "pointer", whiteSpace: "nowwrap", textDecoration: "underline", fontStyle: "italic" }}
                  onClick={() => console.log("trigger upload receipt")}
                >
                  Upload Receipt
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Container>

        <Drawer variant="permanent" anchor="bottom">
          <Button onClick={() => setShowConfirmDialog(true)} color="secondary" variant="contained" sx={{ margin: 1 }}>
            {getTranslateString(t, BUTTON_NEXT)}
          </Button>
        </Drawer>
      </CustomContainer>
      <IssueConfirmDialog
        stamp={issuingNumber}
        open={showConfirmDialog}
        onClick={() => {
          setShowConfirmDialog(false); // close confirm dialog
          setShowSuccessDialog(true); // open success dialog
        }}
        onClose={() => setShowConfirmDialog(false)}
        title={totalCards[0].title}
        logo={totalCards[0].image}
      />
      <IssueSuccessDialog
        scanFirst={true}
        stamp={issuingNumber}
        open={showSuccessDialog}
        onClose={() => setShowSuccessDialog(false)}
        title={totalCards[0].title}
        logo={totalCards[0].image}
      />
    </>
  );
};

export default MerchantUserRedeem;
