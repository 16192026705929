import { useMediaQuery } from "@mui/material";

const useMediaQueries = () => {
  const smDown = useMediaQuery((theme: any) => theme.breakpoints.down("sm"), { noSsr: true });
  const smUp = useMediaQuery((theme: any) => theme.breakpoints.up("sm"), { noSsr: true });
  const mdUp = useMediaQuery((theme: any) => theme.breakpoints.up("md"), { noSsr: true });
  const mdDown = useMediaQuery((theme: any) => theme.breakpoints.down("md"), { noSsr: true });
  const lgUp = useMediaQuery((theme: any) => theme.breakpoints.up("lg"), { noSsr: true });
  const lgDown = useMediaQuery((theme: any) => theme.breakpoints.down("lg"), { noSsr: true });
  const xlUp = useMediaQuery((theme: any) => theme.breakpoints.up("xl"), { noSsr: true });
  return { smDown, smUp, mdDown, mdUp, lgDown, lgUp, xlUp };
};
export default useMediaQueries;
