import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getTranslateString, getTranslateStringWithSubject } from "src/utils/translate";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { AuthGuard } from "src/components/authentication/auth-guard";
import { ACTION_ADD, EDIT, FORM_DESCRIPTION, FORM_NAMING, SEARCH_PLACEHOLDER, SUBJECT_LOYALTY } from "src/constants/translate-keys/common";
import PageHeaderLayout from "src/components/common/layout/page-header-layout";
import DashboardSidebarLayout from "src/components/dashboard/dashboard-sidebar-layout";
import { faPenToSquare } from "@fortawesome/pro-regular-svg-icons";
import DataTableList from "src/components/common/data-display/data-table-list";
import useHttp from "src/hooks/use-http";
import { useState } from "react";
import LoyaltyCrudDialog from "src/components/common/feedback/loyalty-crud-dialog";

export interface ILoyaltyProgram {
  description: string;
  name: string;
  participating_outlets: string[];
  slot: number;
  uom: string;
  uuid: string;
}

const Loyalty = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState<{ open: boolean; loyalty: ILoyaltyProgram | null }>({ open: false, loyalty: null });
  // const [muiTab, setMuiTab] = useState(WORK_ORDER_TAB.OVERVIEW);
  const columns = [
    { path: "name", label: getTranslateString(t, FORM_NAMING), sortKey: "name" },
    { path: "description", label: getTranslateString(t, FORM_DESCRIPTION), sortKey: "category__name" },
    { path: "slot", label: "Slot", sortKey: "slot" },
    { path: "uom", label: "UOM", sortKey: "uom" },
    { label: "Outlets", sortKey: "uom", content: (data: ILoyaltyProgram) => data.participating_outlets.length },
  ];
  const { apiEndpoint } = useHttp();

  const listMetadata = {
    primary: { path: "name" },
    fields: [
      { path: "slot", label: "Slot", sortKey: "slot" },
      { path: "uom", label: "UOM", sortKey: "uom" },
      { label: "Outlets", sortKey: "uom", content: (data: ILoyaltyProgram) => data.participating_outlets.length },
      { path: "description", label: getTranslateString(t, FORM_DESCRIPTION) },
    ],
  };

  return (
    <AuthGuard permissionKey="WORK_ORDERS">
      <DashboardSidebarLayout>
        <PageHeaderLayout
          title={getTranslateString(t, SUBJECT_LOYALTY, true, false)}
          actions={
            <>
              <Button component="a" variant="contained" onClick={() => setOpen(true)} size="small" startIcon={<AddRoundedIcon fontSize="small" />}>
                {getTranslateStringWithSubject(t, ACTION_ADD, SUBJECT_LOYALTY, false, false)}
              </Button>
            </>
          }
        >
          <DataTableList
            rowClick
            // filters={filters}
            filterType="drawer"
            columns={columns}
            listMetadata={listMetadata}
            rowAction={(rowData: ILoyaltyProgram) => setEditOpen({ open: true, loyalty: rowData })}
            rowActions={[
              {
                icon: faPenToSquare,
                label: getTranslateString(t, EDIT),
                onClick: (rowData: ILoyaltyProgram) => setEditOpen({ open: true, loyalty: rowData }),
                tooltip: () => getTranslateString(t, EDIT),
              },
            ]}
            search={{
              key: "search",
              label: getTranslateStringWithSubject(t, SEARCH_PLACEHOLDER, SUBJECT_LOYALTY, false, false),
            }}
            url={apiEndpoint.LOYALTY_PROGRAMS("2a903b85-0c17-4468-b9c5-fbaeacf1f401")}
          />
        </PageHeaderLayout>
        <LoyaltyCrudDialog crud="create" open={open} onClose={() => setOpen(false)} />
        <LoyaltyCrudDialog
          crud="update"
          open={editOpen.open}
          loyalty={editOpen.loyalty}
          onClose={() => setEditOpen({ open: false, loyalty: null })}
        />
      </DashboardSidebarLayout>
    </AuthGuard>
  );
};

export default Loyalty;
