import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "src/slices/auth";
import layoutReducer from "src/slices/layout";
import generalReducer from "src/slices/general";
// import workOrderReducer from "src/slices/work-order";
const rootReducer = combineReducers({
  auth: authReducer,
  layout: layoutReducer,
  general: generalReducer,
  // workOrder: workOrderReducer,
});
export default rootReducer;
