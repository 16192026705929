import React from "react";
/* components */
import Reward from "src/components/loyalty/reward";
/* 3rd party lib */
import { Typography, Stack } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGift } from "@fortawesome/pro-solid-svg-icons";
/* Util */
interface LoyaltyCardRewardsProps {}

type Props = LoyaltyCardRewardsProps;

const LoyaltyCardRewards: React.FC<Props> = () => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <>
      <Stack spacing={2} mb={1}>
        <Reward claimed />
        <Reward claimable />
        <Reward final />
      </Stack>

      <Typography sx={{ mb: "8px" }} variant="caption" color="text.primary">
        * CLICK TO CLAIM REWARD &nbsp;
        <FontAwesomeIcon icon={faGift} size="lg" /> *
      </Typography>
    </>
  );
};

export default LoyaltyCardRewards;
