import React, { useState } from "react";
/* components */
import useMediaQueries from "src/hooks/use-mediaqueries";
import TopNavbar from "src/components/common/navigation/top-navbar";
import CustomContainer from "src/components/common/data-display/custom-container";
/* 3rd party lib */
import { Box, Container, Stack, Tab, Tabs, Typography, useTheme } from "@mui/material";
/* Util */
import chocolateCake from "src/images/chococake.jpg";
import croissant from "src/images/croissant.jpg";
import eyebrow from "src/images/eyebrow.jpg";
import manicure from "src/images/manicure.jpg";
import LoyaltyCard from "src/components/loyalty/loyalty-card";
import { Global } from "@emotion/react";
import BottomNavbar from "src/components/common/navigation/bottom-navbar";

const activeCards = [
  {
    id: 0,
    title: "Get 1 Chocolate Cake",
    totalStars: 15,
    gift: [7, 14],
    image: chocolateCake,
    stampedAt: 4,
  },
  {
    id: 0,
    title: "Get 1 Chocolate Cake",
    totalStars: 15,
    gift: [7, 14],
    image: chocolateCake,
    stampedAt: 4,
  },
  {
    id: 1,
    title: "Get 10 Croissant",
    totalStars: 10,
    gift: [3, 8],
    image: croissant,
    stampedAt: 8,
  },
  {
    id: 1,
    title: "Get 10 Croissant",
    totalStars: 10,
    gift: [3, 8],
    image: croissant,
    stampedAt: 8,
  },
];
const inactiveCards = [
  {
    id: 2,
    title: "Free Eyebrow Session",
    totalStars: 5,
    gift: [2],
    image: eyebrow,
    stampedAt: 5,
  },
  {
    id: 3,
    title: "Free Manicure Session",
    totalStars: 15,
    gift: [9, 15],
    image: manicure,
    stampedAt: 15,
  },
];

const TabPanel = ({ value, index, children }: { value: number; index: number; children: React.ReactNode }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`workorder-tabpanel-${index}`}
      aria-labelledby={`workorder-tab-${index}`}
      style={{ flexGrow: "1" }}
    >
      {value === index && children}
    </div>
  );
};

const tabProps = (index: number) => ({
  id: `workorder-tab-${index}`,
  "aria-controls": `workorder-tabpanel-${index}`,
});

interface PersonalCardsListProps {}

type Props = PersonalCardsListProps;

const PersonalCardsList: React.FC<Props> = () => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const theme = useTheme();
  const [tab, setTab] = useState(0);
  const { smDown } = useMediaQueries();

  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <>
      <CustomContainer>
        <TopNavbar back />
        <Stack
          sx={{
            mb: 2,
            pt: 8,
            height: "240px",
            width: smDown ? "100vw" : "420px",
            background: theme.palette.primary.light,
            // borderRadius: "50%",
            clipPath: smDown ? "circle(100% at 50% -50vw)" : " circle(100% at 50% -210px)",
          }}
          spacing={2}
          alignItems="center"
          justifyContent={"flex-start"}
        >
          <Typography variant="h5" fontWeight={"bolder"} color="primary.main">
            My Cards
          </Typography>
        </Stack>

        <Container sx={{ transform: "translateY(-160px)" }}>
          <Tabs centered value={tab} onChange={(event: React.SyntheticEvent<Element, Event>, tabValue: number) => setTab(tabValue)}>
            <Tab
              label={
                <Stack spacing={1} direction="row" alignItems={"center"}>
                  Active
                </Stack>
              }
              {...tabProps(0)}
            />
            <Tab
              label={
                <Stack spacing={1} direction="row" alignItems={"center"}>
                  Inactive
                </Stack>
              }
              {...tabProps(1)}
            />
          </Tabs>
          <TabPanel value={tab} index={0}>
            <Stack mt={2} spacing={2}>
              {activeCards.map((child, index) => (
                <LoyaltyCard key={index} cardObj={child} />
              ))}
            </Stack>
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <Stack mt={2} spacing={2}>
              {inactiveCards.map((child, index) => (
                <LoyaltyCard key={index} cardObj={child} />
              ))}
            </Stack>
          </TabPanel>
        </Container>
        <BottomNavbar />
      </CustomContainer>
    </>
  );
};

export default PersonalCardsList;
