export const baseThemeOptions = (language: any) => {
  return {
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1000,
        lg: 1200,
        xl: 1920,
      },
    },
    components: {
      MuiAlertTitle: {
        styleOverrides: {
          root: {
            fontWeight: 600,
          },
        },
      },
      MuiAvatar: {
        styleOverrides: {
          root: {
            fontSize: 14,
            fontWeight: 600,
            letterSpacing: 0,
          },
        },
      },
      MuiBreadcrumbs: {
        styleOverrides: {
          li: {
            "& .MuiTypography-root": {
              fontSize: 14,
              fontWeight: 500,
            },
          },
        },
      },
      MuiButton: {
        defaultProps: {
          disableElevation: true,
        },
        styleOverrides: {
          root: {
            textTransform: "none",
            fontSize: "16px",
            borderRadius: "50px",
          },
          sizeSmall: {
            padding: "6px 16px",
          },
          sizeMedium: {
            padding: "8px 20px",
          },
          sizeLarge: {
            padding: "11px 24px",
          },
          textSizeSmall: {
            padding: "7px 12px",
          },
          textSizeMedium: {
            padding: "9px 16px",
          },
          textSizeLarge: {
            padding: "12px 16px",
          },
        },
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiCardActions: {
        styleOverrides: {
          root: {
            padding: "8px 16px",
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            "&:last-child": {
              paddingBottom: "24px",
            },
          },
        },
      },
      MuiCardHeader: {
        defaultProps: {
          titleTypographyProps: {
            fontSize: 16,
            fontWeight: 700,
            variant: "h6",
          },
          subheaderTypographyProps: {
            variant: "body2",
          },
        },
        styleOverrides: {
          action: {
            marginRight: 0,
          },
        },
      },
      MuiCheckbox: {
        defaultProps: {
          color: "primary",
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            fontWeight: 500,
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          "*": {
            boxSizing: "border-box",
          },
          html: {
            MozOsxFontSmoothing: "grayscale",
            WebkitFontSmoothing: "antialiased",
            display: "flex",
            flexDirection: "column",
            minHeight: "100%",
            width: "100%",
          },
          body: {
            display: "flex",
            flex: "1 1 auto",
            flexDirection: "column",
            minHeight: "100%",
            width: "100%",
          },
          "#__next": {
            display: "flex",
            flex: "1 1 auto",
            flexDirection: "column",
            height: "100%",
            width: "100%",
          },
          "#nprogress": {
            pointerEvents: "none",
          },
          "#nprogress .bar": {
            backgroundColor: "#E84A5F",
            height: 3,
            left: 0,
            position: "fixed",
            top: 0,
            width: "100%",
            zIndex: 2000,
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: "8px 16px 16px",
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            padding: 16,
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            fontSize: 16,
            fontWeight: 700,
            padding: 16,
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontWeight: 600,
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            borderRadius: 8,
            padding: 8,
          },
          sizeSmall: {
            padding: 4,
          },
        },
      },
      MuiLinearProgress: {
        styleOverrides: {
          root: {
            borderRadius: 3,
            overflow: "hidden",
          },
        },
      },
      MuiLink: {
        defaultProps: {
          underline: "hover",
        },
      },
      MuiMenu: {
        defaultProps: {
          elevation: 16,
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          input: {
            fontSize: 14,
            fontWeight: 500,
          },
          root: {
            borderRadius: 8,
            overflow: "hidden",
            "&:hover:not(.Mui-disabled)": {
              "&:before": {
                borderBottom: 0,
              },
            },
            "&:before": {
              borderBottom: 0,
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          input: {
            fontSize: 14,
            fontWeight: 500,
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundImage: "none",
          },
        },
      },
      MuiPopover: {
        defaultProps: {
          elevation: 16,
        },
      },
      MuiRadio: {
        defaultProps: {
          color: "primary",
        },
      },
      MuiSwitch: {
        defaultProps: {
          color: "primary",
        },
        styleOverrides: {
          root: {
            "& +.MuiFormControlLabel-label": {
              fontSize: 14,
              fontWeight: 600,
            },
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: "none",
            "& .Mui-selected": {
              background: "red",
            },
          },
        },
      },
      MuiTable: {
        styleOverrides: {
          root: {
            minWidth: 800,
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            fontWeight: 500,
            minHeight: 32,
            padding: "8px 16px",
          },
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            borderBottom: "none",
            "& .MuiTableCell-root": {
              borderBottom: "none",
              fontSize: "12px",
              fontWeight: 600,
              lineHeight: 1,
              letterSpacing: 0.5,
              textTransform: "uppercase",
            },
            "& .MuiTableCell-paddingCheckbox": {
              paddingTop: 4,
              paddingBottom: 4,
            },
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            transition: `background-color 150ms ease-in-out`,
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          InputLabelProps: {
            shrink: true,
          },
        },
        styleOverrides: {
          root: {
            "& .MuiInput-root": {
              fontWeight: 500,
            },
          },
        },
      },
    },
    direction: "ltr",
    shape: {
      borderRadius: 8,
    },
    typography: {
      button: {
        fontWeight: 600,
      },
      fontFamily: '"Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
      body1: {
        fontSize: "1rem",
        fontWeight: 400,
        lineHeight: 1.5,
      },
      body2: {
        fontSize: "0.875rem",
        fontWeight: 400,
        lineHeight: 1.57,
      },
      subtitle1: {
        fontSize: "1rem",
        fontWeight: 500,
        lineHeight: 1.75,
      },
      subtitle2: {
        fontSize: "0.875rem",
        fontWeight: 500,
        lineHeight: 1.57,
      },
      overline: {
        fontSize: "0.75rem",
        fontWeight: 600,
        letterSpacing: "0.5px",
        lineHeight: 2.5,
        textTransform: "uppercase",
      },
      caption: {
        fontSize: "0.75rem",
        fontWeight: 400,
        lineHeight: 1.66,
      },
      h1: {
        fontWeight: 700,
        fontSize: "3.5rem",
        lineHeight: 1.375,
      },
      h2: {
        fontWeight: 700,
        fontSize: "3rem",
        lineHeight: 1.375,
      },
      h3: {
        fontWeight: 700,
        fontSize: "2.25rem",
        lineHeight: 1.375,
      },
      h4: {
        fontWeight: 700,
        fontSize: "2rem",
        lineHeight: 1.375,
      },
      h5: {
        fontWeight: 600,
        fontSize: "1.5rem",
        lineHeight: 1.375,
      },
      h6: {
        fontWeight: 600,
        fontSize: "1.125rem",
        lineHeight: 1.375,
      },
      h7: {
        fontWeight: 600,
        fontSize: "1.1rem",
        lineHeight: 1.375,
      },
    },
    zIndex: {
      appBar: 1100,
      drawer: 1200,
      fab: 1000,
    },
  };
};
