import React from "react";
import { useSelector } from "react-redux";
import DashboardSidebarItem from "./dashboard-sidebar-item";
import { List, ListSubheader, darken, useTheme } from "@mui/material";
import { RootState } from "src/store";
import { IPermissions } from "src/slices/auth";

export interface IDashboardItem {
  permission_key: string;
  title: string;
  path: string;
  icon?: React.ReactNode;
  children?: IDashboardItem[];
}

const renderNavItems = ({ items, path, permissions }: { items: IDashboardItem[]; path: string; permissions: IPermissions | null }) => {
  return items.reduce((acc: React.ReactNode[], item, index) => {
    const partialMatch = path.includes(item.path);
    const exactMatch = path === item.path;
    const theme = useTheme();

    // @TODO - enable back when permission exist
    // if (!permissions || !permissions[item.permission_key]) return acc;

    if (item.children) {
      acc.push(
        <DashboardSidebarItem key={index} active={partialMatch} open={partialMatch} title={item.title} icon={item.icon} path={item.path}>
          <List sx={{ background: darken(theme.palette.gradient.main, 0.08) }} disablePadding>
            {renderNavItems({ items: item.children, path, permissions })}
          </List>
        </DashboardSidebarItem>
      );
    } else acc.push(<DashboardSidebarItem key={index} active={exactMatch} title={item.title} icon={item.icon} path={item.path} />);

    return acc;
  }, []);
};

interface DashboardSidebarSectionProps {
  title: React.ReactNode;
  items: IDashboardItem[];
  path: string;
}

type Props = DashboardSidebarSectionProps;

const DashboardSidebarSection: React.FC<Props> = ({ title, items, path }) => {
  const { permissions } = useSelector((state: RootState) => state.auth);

  return (
    <List
      disablePadding
      subheader={
        <ListSubheader
          disableGutters
          disableSticky
          sx={{
            display: "grid",
            placeItems: "center",
            fontWeight: 700,
            mb: 2,
          }}
        >
          {title}
        </ListSubheader>
      }
    >
      {renderNavItems({ items, path, permissions })}
    </List>
  );
};

export default DashboardSidebarSection;
