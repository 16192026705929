import React, { useCallback, useEffect } from "react";
// import { useRouter } from "next/router";
import { useDispatch } from "react-redux";
import { logout } from "src/slices/auth";
import routes from "src/routes";
import { isTokenExpired } from "src/utils/jwt";
import axios from "axios";
import toast from "react-hot-toast";
import { getTranslateString } from "src/utils/translate";
import { NETWORK_ERROR } from "src/constants/translate-keys/common";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";

let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach(prom => {
    if (error) prom.reject(error);
    else prom.resolve(token);
  });

  failedQueue = [];
};

export type HttpContextContent = {
  http: any;
  apiEndpoint: typeof apiEndpoint;
};

// do this to ignore null error
export const HttpContext = React.createContext<HttpContextContent>({} as HttpContextContent);

const HttpProvider = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const accessToken = typeof window !== "undefined" ? localStorage.getItem("accessToken") : null;

  let baseURL =
    process.env.REACT_APP_IS_DEV === "true"
      ? process.env.REACT_APP_BASE_URL
      : typeof window !== "undefined"
      ? "/api/"
      : process.env.REACT_APP_BASE_URL;

  const http = axios.create({
    baseURL: baseURL,
    headers: {
      "Content-Type": "application/json",
      common: {
        ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
      },
    },
  });

  const handleClearTokens = useCallback(() => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("remember_me");

    delete http.defaults.headers.common["Authorization"];

    dispatch(logout());

    // navigate(routes.ROOT, { replace: true });
  }, [navigate, dispatch]);

  useEffect(() => {
    const refreshToken = localStorage.getItem("refreshToken");
    // if path is submit, submit_result and public, do not move forward, if not user will keep getting redirected to login
    if (pathname === routes.ROOT || pathname === routes.SIGNUP || pathname === routes.LOGIN || pathname === routes.MERCHANT_LOGIN) return;

    if (!refreshToken || isTokenExpired(refreshToken)) {
      handleClearTokens();
    }
  }, [handleClearTokens, pathname]);

  http.interceptors.response.use(
    response => {
      return response;
    },
    async error => {
      if (error.message === "Network Error") {
        toast.error(getTranslateString(t, NETWORK_ERROR));

        return Promise.reject(error);
      }

      const originalRequest = error.config;

      if (
        error.response &&
        Object.values(error.response).includes("status") &&
        error.response.status === 401 &&
        !originalRequest._retry &&
        !originalRequest.withCredentials
      ) {
        if (isRefreshing) {
          return new Promise((resolve, reject) => failedQueue.push({ resolve, reject }))
            .then(token => {
              originalRequest.headers["Authorization"] = `Bearer ${token}`;
              return http(originalRequest);
            })
            .catch(err => {
              return Promise.reject(err);
            });
        }

        originalRequest._retry = true;
        isRefreshing = true;

        return new Promise(async (resolve, reject) => {
          const refresh = localStorage.getItem("refreshToken");

          if ((!refresh || isTokenExpired(refresh)) && localStorage.getItem("remember_me") !== "true") {
            handleClearTokens();
          } else {
            axios
              .post(`${baseURL}${apiEndpoint.TOKEN_REFRESH.substring(1)}`, {
                refresh: refresh,
              })
              .then(res => {
                localStorage.setItem("accessToken", res.data.access);

                http.defaults.headers.common["Authorization"] = `Bearer ${res.data.access}`;
                originalRequest.headers["Authorization"] = `Bearer ${res.data.access}`;

                processQueue(null, res.data.access);
                resolve(http(originalRequest));
              })
              .catch(error => {
                if (error.response) {
                  if (error.response.status === 401) {
                    //need to use axios in order to get this 401
                    handleClearTokens();
                  }
                }
                processQueue(error, null);
                reject(error);
              })
              .finally(() => {
                isRefreshing = false;
              });

            // try {
            //   const { data } = await http.post(apiEndpoint.TOKEN_REFRESH, { refresh });

            //   localStorage.setItem("accessToken", data.access);

            //   http.defaults.headers.common["Authorization"] = `Bearer ${data.access}`;
            //   originalRequest.headers["Authorization"] = `Bearer ${data.access}`;

            //   processQueue(null, data.access);
            //   resolve(http(originalRequest));
            // } catch (error) {
            //   processQueue(error, null);
            //   reject(error);
            // } finally {
            //   isRefreshing = false;
            // }
          }
        });
      }

      return Promise.reject(error);
    }
  );

  return <HttpContext.Provider value={{ http, apiEndpoint }}>{children}</HttpContext.Provider>;
};

export default HttpProvider;

export const apiEndpoint = {
  TOKEN: `/token/`,
  TOKEN_REFRESH: `/token/refresh/`,
  CURRENT_USER: `/users/me/`,
  USER: (username: string) => `/users/${username}/`,
  PERMISSIONS: `/users/permissions/`,
  CONFIGS: `/configs/`,
  PARAMS: `/params/`,
  NOTIFICATIONS: `/notification/`,
  MERCHANTS: `/superadmin/merchants/`,
  OUTLETS: (merchant_uuid: string) => `/admin/merchants/${merchant_uuid}/outlets/`,
  OUTLET_UPDATE: (merchant_uuid: string, outlet_uuid: string) => `/admin/merchants/${merchant_uuid}/outlets/${outlet_uuid}/`,
  LOYALTY_PROGRAMS: (merchant_uuid: string) => `/admin/merchants/${merchant_uuid}/loyalty_programs/`,
};

export const HttpConsumer = HttpContext.Consumer;
