import React from "react";
import MerchantSidebarItem from "./merchant-sidebar-item";
/* components */
/* 3rd party lib */
import { alpha, Box, Button, Divider, Drawer, List, Stack, Typography, useTheme } from "@mui/material";
/* Util */
import blue from "src/images/blue.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/pro-solid-svg-icons";
import { faPowerOff } from "@fortawesome/pro-regular-svg-icons";
import useAuth from "src/hooks/use-auth";

interface MerchantSidebarProps {
  open: boolean;
  toggleDrawer: (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => void;
}

type Props = MerchantSidebarProps;

const MerchantSidebar: React.FC<Props> = ({ open, toggleDrawer }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const theme = useTheme();
  const mainBlue = theme.palette.secondary.main;
  const lightBlue = theme.palette.secondary.light;
  const { logout } = useAuth();
  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <>
      <Drawer
        PaperProps={{
          sx: {
            background: lightBlue,
            width: 300,
            py: 2,
            overflow: "visible",
          },
        }}
        sx={{ position: "relative", overflow: "visible" }}
        anchor={"left"}
        open={open}
        onClose={toggleDrawer(false)}
      >
        <Box
          className="center"
          onClick={toggleDrawer(false)}
          sx={{
            cursor: "pointer",
            background: "white",
            position: "absolute",
            right: "-30px",
            top: "20px",
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            border: `10px solid ${lightBlue}`,
          }}
        >
          <FontAwesomeIcon icon={faAngleLeft} size="xl" color={mainBlue} />
        </Box>
        <Stack direction="row" py={4} pl={2} spacing={1} alignItems="center">
          <Box className="center" sx={{ width: "100px", height: "100px", border: "1px solid white", borderRadius: "50%" }}>
            <Box className="center" sx={{ width: "95px", height: "95px", background: "white", borderRadius: "50%" }}>
              <img src={blue} alt="bluestar" width="80px" />
            </Box>
          </Box>
          <Stack>
            <Typography variant="h5" fontWeight={"bolder"} color="secondary.dark">
              Henry
            </Typography>
            <Typography variant="body2" fontWeight="bolder" color="text.primary">
              Member ID: ABC001
            </Typography>
            <Typography variant="caption" color="text.primary">
              Since 21 Feb 2023
            </Typography>
          </Stack>
        </Stack>
        <Divider sx={{ borderColor: alpha(mainBlue, 0.3), mx: 2, mb: 3 }} />
        <Stack justifyContent={"space-between"} sx={{ height: "calc(100% - 220px)" }}>
          <List disablePadding>
            <MerchantSidebarItem
              title="My Profile"
              toggleDrawer={toggleDrawer}
              icon={<i className="fak fa-profile fa-xl" style={{ width: "24px" }}></i>}
            />
            <MerchantSidebarItem
              title="Reset Password"
              toggleDrawer={toggleDrawer}
              icon={<i className="fak fa-reset fa-xl" style={{ width: "24px" }}></i>}
            />
            <MerchantSidebarItem
              title="Settings"
              toggleDrawer={toggleDrawer}
              icon={<i className="fak fa-settings fa-xl" style={{ width: "24px" }}></i>}
            />
            <MerchantSidebarItem
              title="Contact Us"
              toggleDrawer={toggleDrawer}
              icon={<i className="fak fa-contact fa-xl" style={{ width: "24px" }}></i>}
            />
          </List>
          <Stack direction="row" sx={{ px: 2 }} alignItems="center" justifyContent={"space-between"}>
            <Button color="secondary" variant="contained" onClick={() => logout()}>
              <Stack direction="row" className="center" spacing={1}>
                <FontAwesomeIcon icon={faPowerOff} />
                <Typography variant="body1">Log out</Typography>
              </Stack>
            </Button>
            <Box sx={{ background: "white", borderRadius: "16px", px: 1 }}>
              <Typography variant="body2">Version {process.env.REACT_APP_VERSION}</Typography>
            </Box>
          </Stack>
        </Stack>
      </Drawer>
    </>
  );
};

export default MerchantSidebar;
