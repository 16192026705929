import { useRef, useState } from "react";
import useAuth from "src/hooks/use-auth";
import { Button, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightFromBracket, faUser } from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";
import { NAVBAR_LOGOUT, NAVBAR_WELCOME } from "../../constants/translate-keys/common";
import { getTranslateString } from "../../utils/translate";
import useMediaQueries from "src/hooks/use-mediaqueries";
import { useSelector } from "react-redux";
import { RootState } from "src/store";

interface DashboardNavbarAccountProps {}
type Props = DashboardNavbarAccountProps;

const DashboardNavbarAccount: React.FC<Props> = () => {
  const { lgUp } = useMediaQueries();
  const anchorRef = useRef(null);
  const [popover, setPopover] = useState(false);
  const { logout } = useAuth();
  const { user } = useSelector((state: RootState) => state.auth);
  const { t } = useTranslation();

  return (
    <>
      {lgUp ? (
        <Button ref={anchorRef} onClick={() => setPopover(true)} size="small" sx={{ color: "inherit" }}>
          <Typography component="span" variant="body2">
            {getTranslateString(t, NAVBAR_WELCOME)},&nbsp;
          </Typography>
          {user?.name || user?.username}
        </Button>
      ) : (
        <IconButton ref={anchorRef} onClick={() => setPopover(true)} color="inherit">
          <FontAwesomeIcon fixedWidth icon={faUser} size="sm" />
        </IconButton>
      )}
      <Menu
        anchorEl={anchorRef.current}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={popover}
        onClose={() => setPopover(false)}
        PaperProps={{ sx: { minWidth: 150 } }}
      >
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faArrowRightFromBracket} />
          </ListItemIcon>
          <ListItemText primary={getTranslateString(t, NAVBAR_LOGOUT)} primaryTypographyProps={{ variant: "body2", fontWeight: 600 }} />
        </MenuItem>
      </Menu>
    </>
  );
};

export default DashboardNavbarAccount;
