import { createSlice } from "@reduxjs/toolkit";

export interface IPermissions {
  [key: string]: boolean;
}

export interface IMerchants {
  profile: {
    organization_name: string;
    since: string;
  };
  uuid: string;
}

export interface AuthRootState {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: any | null;
  permissions: IPermissions | null;
  configs: any | null;
  merchants: IMerchants[] | null;
}

const initialState: AuthRootState = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
  permissions: null,
  configs: null,
  merchants: null,
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    initialize: (state, action) => {
      const { isAuthenticated, user, permissions, merchants, configs } = action.payload;
      state.isInitialized = true;
      state.isAuthenticated = isAuthenticated;
      state.user = user;
      state.permissions = permissions;
      state.configs = configs;
      state.merchants = merchants;
    },
    login: (state, action) => {
      const { user, permissions, merchants, configs } = action.payload;

      state.isInitialized = true;
      state.isAuthenticated = true;
      state.user = user;
      state.permissions = permissions;
      state.configs = configs;
      state.merchants = merchants;
    },
    logout: state => {
      state.isInitialized = true;
      state.isAuthenticated = false;
      state.user = null;
      state.permissions = null;
      state.configs = null;
      state.merchants = null;
    },
    updateUser: (state, action) => {
      state.user = action.payload;
    },
  },
});

export default slice.reducer;

export const { initialize, login, logout, updateUser } = slice.actions;
