import { Box, Container, Link, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React from "react";
import routes from "src/routes";
/* components */
/* 3rd party lib */
/* Util */
interface MerchantActivitiesCarouselProps {}

type Props = MerchantActivitiesCarouselProps;

const MerchantActivitiesCarousel: React.FC<Props> = () => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const navigate = useNavigate();

  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <Container sx={{ mb: 4 }}>
      <Stack direction="row" justifyContent={"space-between"} mb={1}>
        <Typography variant="body1" color="secondary.main" fontWeight={"bolder"}>
          Recent Activities
        </Typography>
        <Link
          // href={routes.SIGNUP}
          variant="body1"
          fontWeight={"bolder"}
          color="text.primary"
          underline="hover"
          sx={{ cursor: "pointer", whiteSpace: "nowwrap", textDecoration: "underline" }}
          onClick={() => navigate(routes.MERCHANT_ACTIVITIES)}
        >
          View All
        </Link>
      </Stack>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Stack alignItems={"flex-start"}>
          <Box sx={{ textAlign: "left" }}>
            Employee A issued&nbsp;
            <Typography sx={{ display: "inline-block" }} variant="body1" color="text.primary" fontWeight={"bolder"}>
              X number of stamps
            </Typography>
            &nbsp;to USER B on&nbsp;
            <Typography sx={{ display: "inline-block" }} variant="body1" color="text.primary" fontWeight={"bolder"}>
              Loyalty Program A
            </Typography>
          </Box>
          <Typography variant="caption" color="text.primary">
            05 Feb 2023, 09:00AM
          </Typography>
        </Stack>
      </Stack>
    </Container>
  );
};

export default MerchantActivitiesCarousel;
