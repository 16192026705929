import React, { useState } from "react";
/* components */
import useMediaQueries from "src/hooks/use-mediaqueries";
import CustomContainer from "src/components/common/data-display/custom-container";
import MerchantTopNavbar from "src/components/common/navigation/merchant-top-navbar";
import MerchantLoyaltyActivityList from "src/components/merchant/merchant-loyalty-activity-list";
import MerchantLoyaltyDetails from "src/components/merchant/merchant-loyalty-details";
import MerchantLoyaltyDaily from "src/pages/merchant/loyalty-program-daily";
import { merchantLoyaltyListArr } from "src/components/merchant/merchant-loyalty-list";
/* 3rd party lib */
import { useParams } from "react-router-dom";
import { styled, Container, Stack, Tab, Tabs, Typography, useTheme } from "@mui/material";
/* Util */

const StyledTab = styled(Tab)(({ theme }) => ({
  "&.Mui-selected": {
    color: theme.palette.secondary.main,
  },
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  "& .MuiTabs-indicator": {
    backgroundColor: theme.palette.secondary.main,
  },
}));

interface MerchantLoyaltyProgramDetailsProps {}

type Props = MerchantLoyaltyProgramDetailsProps;

const TabPanel = ({ value, index, children }: { value: number; index: number; children: React.ReactNode }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`workorder-tabpanel-${index}`}
      aria-labelledby={`workorder-tab-${index}`}
      style={{ flexGrow: "1" }}
    >
      {value === index && children}
    </div>
  );
};

const tabProps = (index: number) => ({
  id: `workorder-tab-${index}`,
  "aria-controls": `workorder-tabpanel-${index}`,
});

const MerchantLoyaltyProgramDetails: React.FC<Props> = () => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const { smDown } = useMediaQueries();
  const theme = useTheme();
  const [tab, setTab] = useState(0);
  const { id } = useParams();
  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <CustomContainer>
      <MerchantTopNavbar back version="curve" />

      <Stack
        sx={{
          paddingTop: "52px",
          width: smDown ? "100vw" : "420px",
          height: "180px",
          background: theme.palette.primary.light,
          // borderRadius: "50%",,
          clipPath: `circle(90% at 50% ${smDown ? "-12vw" : "-80px"})`,
          backgroundImage: !!id ? `url(${merchantLoyaltyListArr[parseInt(id)].image})` : "",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        spacing={2}
        alignItems="center"
        justifyContent={"flex-start"}
      ></Stack>

      <Container sx={{ pb: 2 }}>
        <Typography variant="h5" fontWeight={"bolder"} color="secondary.main" mb={1}>
          {!!id && merchantLoyaltyListArr[parseInt(id)].title}
        </Typography>

        <StyledTabs
          centered
          value={tab}
          color="secondary.main"
          onChange={(event: React.SyntheticEvent<Element, Event>, tabValue: number) => setTab(tabValue)}
        >
          <StyledTab
            label={
              <Stack spacing={1} direction="row" alignItems={"center"}>
                Activities
              </Stack>
            }
            {...tabProps(0)}
          />
          <StyledTab
            label={
              <Stack spacing={1} direction="row" alignItems={"center"}>
                Details
              </Stack>
            }
            {...tabProps(1)}
          />
          <StyledTab
            label={
              <Stack spacing={1} direction="row" alignItems={"center"}>
                Daily
              </Stack>
            }
            {...tabProps(2)}
          />
        </StyledTabs>
        <TabPanel value={tab} index={0}>
          <MerchantLoyaltyActivityList />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <Stack mt={2} spacing={2}>
            <MerchantLoyaltyDetails />
          </Stack>
        </TabPanel>
        <TabPanel value={tab} index={2}>
          <Stack mt={2} spacing={2}>
            <MerchantLoyaltyDaily />
          </Stack>
        </TabPanel>
      </Container>
    </CustomContainer>
  );
};

export default MerchantLoyaltyProgramDetails;
