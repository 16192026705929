import React from "react";
/* components */
import Wataa from "src/images/wataa.png";
/* 3rd party lib */
import { Box, Card, Grid, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import routes from "src/routes";
import Stamp, { IStampStatus } from "src/components/loyalty/stamp";
/* Util */
interface LoyaltyCardProps {
  cardObj: { id: number; title: string; totalStars: number; gift: number[]; image: any; stampedAt: number };
}

type Props = LoyaltyCardProps;

const LoyaltyCard: React.FC<Props> = ({ cardObj }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */

  const navigate = useNavigate();
  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */

  return (
    <Card
      elevation={0}
      sx={{
        // border: `1px solid ${theme.palette.primary.main}`,
        cursor: "pointer",
        // backgroundColor: theme.palette.primary.main,
        // backgroundImage: data ? `url(${data.HOME_IMAGE})` : theme.palette.primary.light,
        // backgroundImage: `url(${cardObj.image})`,
        // backgroundRepeat: "no-repeat",
        // backgroundSize: "cover",
        // backgroundPosition: "center",
        display: "grid",
        placeItems: "center",
        py: 1,
        position: "relative",
      }}
      onClick={() => {
        navigate(`${routes.CARDS}/${cardObj.id}`);
      }}
    >
      <img
        style={{
          opacity: 0.7,
          zIndex: 1,
          position: "absolute",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
        src={cardObj.image}
        alt={cardObj.title}
      />
      <Box
        sx={{
          zIndex: 2,
          width: "100%",
          height: "100%",
          // position: "absolute",
          // backdropFilter: "blur(8px)",
          // WebkitBackdropFilter: "blur(8px)",
          // background: "rgba(255, 255, 255, 0.6)",
          // borderRadius: "80%",
          // clipPath: "circle(65% at 50% 0)",

          // "&:before": {
          //   content: '" "',
          //   display: "block",
          //   position: "absolute",
          //   left: 0,
          //   top: 0,
          //   width: "100%",
          //   height: "100%",
          //   opacity: 0.6,
          //   backgroundImage: `url(${cardObj.image})`,
          //   backgroundRepeat: "no-repeat",

          //   backgroundSize: "cover",
          // },
        }}
      >
        <Stack py={1} spacing={1.5} sx={{ minHeight: "150px" }}>
          <Stack
            className="center"
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{
              width: "100%",
              background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.5))",
              backdropFilter: "blur(5px)",
              opacity: 0.8,
              py: 0.5,
            }}
            position="relative"
          >
            <img src={Wataa} alt="logo" width="30px" height="30px" />

            <Typography variant="h6" color="black">
              {cardObj.title}
            </Typography>
          </Stack>
          {/* Old Code */}
          {/* <Stack direction="row" justifyContent={"center"} sx={{ width: "100%" }}> */}
          <Stack direction="row" sx={{ position: "absolute", width: "160px", right: "8px", bottom: "8px" }}>
            <Grid container sx={{ width: "100%", height: "100%" }}>
              {Array(cardObj.totalStars)
                .fill(0)
                .map((_child, index) => {
                  let stampType: IStampStatus = "inactive_star";

                  // inactive star when index is greater than currently stamped position, and not last stamp, and also not gifts
                  if (index > cardObj.stampedAt - 1 && index !== cardObj.totalStars - 1 && !cardObj.gift.includes(index + 1)) {
                    stampType = "inactive_star";
                  }
                  // active star when index is less than currently stamped position, and not last stamp, and also not gifts
                  if (index <= cardObj.stampedAt - 1 && index !== cardObj.totalStars - 1 && !cardObj.gift.includes(index + 1)) {
                    stampType = "active_star";
                  }
                  // inactive gift when index is greater than currently stamped position, and the array includes the index
                  if (index > cardObj.stampedAt - 1 && cardObj.gift.includes(index + 1)) {
                    stampType = "inactive_gift";
                  }
                  // active gift when index is less than currently stamped position, and the array includes the index
                  if (index <= cardObj.stampedAt - 1 && cardObj.gift.includes(index + 1)) {
                    stampType = "active_gift";
                  }
                  if (cardObj.stampedAt - 1 < cardObj.totalStars - 1 && index === cardObj.totalStars - 1) {
                    stampType = "inactive_trophy";
                  }
                  if (index === cardObj.stampedAt - 1 && index === cardObj.totalStars - 1) {
                    stampType = "active_trophy";
                  }

                  return (
                    <Grid key={index} item xs={12 / 5} className="center" sx={{ paddingLeft: 0 }}>
                      <Stamp size={0.6} status={stampType} />
                    </Grid>
                  );
                })}
            </Grid>
          </Stack>
        </Stack>
      </Box>
    </Card>
  );
};

export default LoyaltyCard;
