import React from "react";
/* components */
import LoyaltyCard from "src/components/loyalty/loyalty-card";
/* 3rd party lib */
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
/* Util */
import chocolateCake from "src/images/chococake.jpg";
import croissant from "src/images/croissant.jpg";
import eyebrow from "src/images/eyebrow.jpg";
import manicure from "src/images/manicure.jpg";
import { useNavigate } from "react-router-dom";
import routes from "src/routes";

interface LoyaltyCardsListProps {}

type Props = LoyaltyCardsListProps;

export const totalCards = [
  {
    id: 0,
    title: "Get 1 Chocolate Cake",
    totalStars: 15,
    gift: [2, 10],
    image: chocolateCake,
    stampedAt: 3,
    expiredDate: "20230620",
  },
  {
    id: 1,
    title: "Get 10 Croissant",
    totalStars: 10,
    gift: [3, 8],
    image: croissant,
    stampedAt: 8,
    expiredDate: "20230620",
  },
  {
    id: 2,
    title: "Free Eyebrow Session",
    totalStars: 5,
    gift: [2],
    image: eyebrow,
    stampedAt: 5,
    expiredDate: "20230620",
  },
  {
    id: 3,
    title: "Free Manicure Session",
    totalStars: 15,
    gift: [9, 15],
    image: manicure,
    stampedAt: 15,
    expiredDate: "20230620",
  },
];
const LoyaltyCardsList: React.FC<Props> = () => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const navigate = useNavigate();
  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <Container sx={{ pb: 10 }}>
      <Stack direction="row" justifyContent={"space-between"}>
        <Typography variant="body1" color="primary.main" fontWeight={"bolder"}>
          My Cards
        </Typography>
        <Link
          // href={routes.SIGNUP}
          variant="body1"
          fontWeight={"bolder"}
          color="text.primary"
          underline="hover"
          sx={{ cursor: "pointer", whiteSpace: "nowwrap", textDecoration: "underline" }}
          onClick={() => navigate(routes.PERSONAL_CARDS)}
        >
          View All
        </Link>
      </Stack>
      <Stack mt={2} spacing={2}>
        {totalCards.map((child, index) => (
          <LoyaltyCard key={index} cardObj={child} />
        ))}
        {/* <LoyaltyCard image={chocolateCake} />
        <LoyaltyCard image={croissant} />
        <LoyaltyCard image={eyebrow} />
        <LoyaltyCard image={manicure} /> */}
      </Stack>
    </Container>
  );
};

export default LoyaltyCardsList;
