import React from "react";
/* components */
/* 3rd party lib */ import {
  Grid,
  Stack,
  Button,
  Dialog,
  useTheme,
  IconButton,
  TextField,
  Typography,
  DialogTitle,
  FormControl,
  DialogActions,
  DialogContent,
  SelectChangeEvent,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk } from "@fortawesome/pro-light-svg-icons";
import { getTranslateString, getTranslateStringWithSubject } from "src/utils/translate";
import useMediaQueries from "src/hooks/use-mediaqueries";
import { ACTION_CREATE, BUTTON_CANCEL, BUTTON_SAVE, FORM_NAMING, FORM_WARNING_REQUIRED, SUBJECT_OUTLET } from "src/constants/translate-keys/common";
import { IOutlet } from "src/pages/admin/configuration";
import useHttp from "src/hooks/use-http";

/* Util */
interface OutletCrudDialogProps {
  open: boolean;
  crud: "create" | "update";
  outlet?: IOutlet | null;
  onClose: () => void;
}

type Props = OutletCrudDialogProps;

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

const schema = (t: any) =>
  Yup.object().shape({
    outlet_name: Yup.string().required(getTranslateString(t, FORM_WARNING_REQUIRED)),
  });

const OutletCrudDialog: React.FC<Props> = ({ crud, outlet, open, onClose }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const { t } = useTranslation();
  const { smDown } = useMediaQueries();
  const { apiEndpoint, http } = useHttp();

  const formik = useFormik({
    validateOnChange: false,
    // validationSchema: schema(t),
    enableReinitialize: true,
    initialValues:
      crud === "create"
        ? {
            outlet_name: "",
          }
        : {
            outlet_name: outlet ? outlet.outlet_name : "",
          },
    onSubmit: values => {
      const handleCreateOutlet = async (formikValues: typeof values) => {
        const { data } = await http.post(apiEndpoint.OUTLETS("2a903b85-0c17-4468-b9c5-fbaeacf1f401"), JSON.stringify(formikValues));
      };
      // const handleUpdateOutlet = async (formikValues: typeof values) => {
      //   const { data } = await http.post(apiEndpoint.OUTLETS, JSON.stringify(formikValues));
      // };

      handleCreateOutlet(values);
      // handleUpdateOutlet(values);
    },
  });
  const { values } = formik;
  const theme = useTheme();
  /* ================================================== */
  /*  method */
  /* ================================================== */

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    formik.setFieldValue("participating_outlets", typeof value === "string" ? value.split(",") : value);
  };

  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */

  return (
    <Dialog maxWidth="md" fullWidth open={open} onClose={onClose} fullScreen={smDown}>
      <DialogTitle>
        <Stack direction="row" spacing={3} alignItems="center">
          <Typography variant="inherit" sx={{ flexGrow: 1, pl: 2 }}>
            {getTranslateStringWithSubject(t, ACTION_CREATE, SUBJECT_OUTLET, false, false)}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseRoundedIcon fontSize="small" />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <form id="outletForm" onSubmit={formik.handleSubmit}>
          <Grid container spacing={1} sx={{ px: 2 }}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={4} sm={3} md={1} lg={3} sx={{ whiteSpace: "pre-line", display: "flex", alignItems: "center" }}>
                  <Typography variant="body2" color={(theme.palette.neutral as any)[500]}>
                    {getTranslateString(t, FORM_NAMING)}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={8}
                  sm={9}
                  md={11}
                  lg={9}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <FormControl fullWidth>
                    <TextField
                      hiddenLabel
                      variant="outlined"
                      name="outlet_name"
                      size="small"
                      placeholder={getTranslateString(t, FORM_NAMING)}
                      value={values.outlet_name}
                      onChange={formik.handleChange}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2}>
          <Grid item xs>
            <Stack direction="row" spacing={2} justifyContent="flex-end">
              <Button variant="outlined" onClick={onClose} size="small">
                {getTranslateString(t, BUTTON_CANCEL)}
              </Button>
              <Button
                variant="contained"
                form="outletForm"
                color="primary"
                type="submit"
                size="small"
                startIcon={<FontAwesomeIcon icon={faFloppyDisk} />}
              >
                {getTranslateString(t, BUTTON_SAVE)}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default OutletCrudDialog;
