import React, { useState } from "react";
/* components */
import useMediaQueries from "src/hooks/use-mediaqueries";
import CustomContainer from "src/components/common/data-display/custom-container";
import MerchantTopNavbar from "src/components/common/navigation/merchant-top-navbar";
/* 3rd party lib */
import { styled, Container, Stack, Tab, Tabs, Typography, useTheme } from "@mui/material";
import { merchantLoyaltyListArr } from "src/components/merchant/merchant-loyalty-list";
import MerchantLoyalty from "src/components/merchant/merchant-loyalty";
/* Util */

const StyledTab = styled(Tab)(({ theme }) => ({
  "&.Mui-selected": {
    color: theme.palette.secondary.main,
  },
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  "& .MuiTabs-indicator": {
    backgroundColor: theme.palette.secondary.main,
  },
}));

interface MerchantLoyaltyProgramsProps {}

type Props = MerchantLoyaltyProgramsProps;

const TabPanel = ({ value, index, children }: { value: number; index: number; children: React.ReactNode }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`workorder-tabpanel-${index}`}
      aria-labelledby={`workorder-tab-${index}`}
      style={{ flexGrow: "1" }}
    >
      {value === index && children}
    </div>
  );
};

const tabProps = (index: number) => ({
  id: `workorder-tab-${index}`,
  "aria-controls": `workorder-tabpanel-${index}`,
});

const MerchantLoyaltyPrograms: React.FC<Props> = () => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const { smDown } = useMediaQueries();
  const theme = useTheme();
  const [tab, setTab] = useState(0);
  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <CustomContainer>
      <MerchantTopNavbar back />
      <Stack
        sx={{
          width: smDown ? "100vw" : "420px",
          background: theme.palette.secondary.light,
          // borderRadius: "50%",
          pt: 8,
          height: "240px",
          clipPath: smDown ? "circle(100% at 50% -50vw)" : " circle(100% at 50% -210px)",
        }}
        spacing={2}
      >
        <Typography variant="h5" fontWeight={"bolder"} color="secondary.main">
          Loyalty Programs
        </Typography>
      </Stack>
      <Container sx={{ transform: "translateY(-140px)" }}>
        <StyledTabs
          centered
          value={tab}
          color="secondary.main"
          onChange={(event: React.SyntheticEvent<Element, Event>, tabValue: number) => setTab(tabValue)}
        >
          <StyledTab
            label={
              <Stack spacing={1} direction="row" alignItems={"center"}>
                Active
              </Stack>
            }
            {...tabProps(0)}
          />
          <StyledTab
            label={
              <Stack spacing={1} direction="row" alignItems={"center"}>
                Inactive
              </Stack>
            }
            {...tabProps(1)}
          />
        </StyledTabs>
        <TabPanel value={tab} index={0}>
          <Stack mt={2} spacing={2}>
            {merchantLoyaltyListArr.map((child, index) => (
              <MerchantLoyalty key={index} cardObj={child} />
            ))}
          </Stack>
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <Stack mt={2} spacing={2}>
            <MerchantLoyalty cardObj={merchantLoyaltyListArr[1]} />
          </Stack>
        </TabPanel>
      </Container>
    </CustomContainer>
  );
};

export default MerchantLoyaltyPrograms;
