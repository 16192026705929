import React, { useEffect } from "react";
/* components */
import routes from "src/routes";
import { RootState } from "src/store";
import blue from "src/images/blue.png";
import WhiteStar from "src/images/whitestar.png";
import WhiteDiamond from "src/images/whitediamond.png";
import CustomContainer from "src/components/common/data-display/custom-container";
/* 3rd party lib */
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Box, Link, Stack, Button, Typography, useTheme } from "@mui/material";

interface GetStartedProps {}

type Props = GetStartedProps;

const GetStarted: React.FC<Props> = () => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);
  const bottomHeight = 60;

  const expandStars = (delay: number) => {
    return {
      position: "absolute",

      animation: `starExpands 1.5s linear infinite ${delay}s`,
      "@keyframes starExpands": {
        "0%": {
          transform: "scale(0.5)",
        },
        "50%": {
          transform: "scale(1)",
        },
        "100%": {
          transform: "scale(0.5)",
        },
      },
    };
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate(routes.HOME, { replace: true });
    }
  }, [isAuthenticated, navigate]);

  return (
    <>
      <CustomContainer>
        <Stack sx={{ minHeight: "100vh", width: "100%", height: "100vh" }}>
          <Box
            py={3}
            sx={{
              borderRadius: "0% 100% 0% 100% / 80% 20% 80% 20%",
              background: theme.palette.primary.light,
              width: "100%",
              height: `calc(100% - ${bottomHeight}px)`,
              mb: "20px",
              display: "grid",
              placeItems: "center",
            }}
          >
            <Box>
              <Stack sx={{ position: "relative" }} direction="row" justifyContent="center" alignItems="center" spacing={1} mb={1}>
                <Box sx={{ ...expandStars(0.5), left: "5px", top: "50px" }}>
                  <img src={WhiteDiamond} alt="topleftwhitediamond" width="8px" height="8px" />
                </Box>
                <Box sx={{ ...expandStars(1), left: "50%", top: "-20px" }}>
                  <img src={WhiteDiamond} alt="whitediamond" width="8px" height="8px" />
                </Box>
                <Box sx={{ ...expandStars(0.2), right: "5px", top: "80px" }}>
                  <img src={WhiteDiamond} alt="whitediamond" width="8px" height="8px" />
                </Box>
                <Box sx={{ ...expandStars(0.5), left: "30px", bottom: "-10px" }}>
                  <img src={WhiteStar} alt="whitediamond" width="20px" height="20px" />
                </Box>
                <Box sx={{ ...expandStars(1.5), left: "65px", bottom: "-10px" }}>
                  <img src={WhiteDiamond} alt="whitediamond" width="8px" height="8px" />
                </Box>
                <Box sx={{ ...expandStars(0.2), right: "50px", bottom: "-20px" }}>
                  <img src={WhiteStar} alt="whitediamond" width="20px" height="20px" />
                </Box>
                <Box
                  sx={{
                    ...expandStars(0.8),
                    right: "30px",
                    bottom: "10px",
                  }}
                >
                  <img src={WhiteStar} alt="whitediamond" width="15px" height="15px" />
                </Box>

                <Box
                  sx={{
                    display: "grid",
                    placeItems: "center",
                    padding: 2,
                    // borderRadius: "50%",
                    // width: "150px",
                    // height: "150px",
                  }}
                >
                  <Stack>
                    <Box
                      sx={{
                        animation: `logoShake 2s linear infinite`,
                        "@keyframes logoShake": {
                          "0%": {
                            transform: " rotate(0deg)",
                          },
                          "25%": {
                            transform: " rotate(3deg)",
                          },
                          "50%": {
                            transform: " rotate(0deg)",
                          },
                          "75%": {
                            transform: " rotate(-3deg)",
                          },
                          "100%": {
                            transform: " rotate(0deg)",
                          },
                        },
                      }}
                    >
                      <img src={blue} alt="bluestar" width="200" />
                    </Box>
                  </Stack>
                </Box>
              </Stack>
              <Stack justifyContent={"center"} alignItems="center">
                <Typography mb={5} variant="h1" fontWeight="light" color="primary.dark">
                  WELCOME
                </Typography>
                <Typography variant="h5" fontWeight={"bolder"} color="primary.dark">
                  CREATE AN ACCOUNT
                </Typography>
                <Typography variant="body1" color="primary.dark">
                  to gain access to amazing rewards
                </Typography>
                <Button onClick={() => navigate(routes.SIGNUP)} variant="contained" sx={{ mt: 2, width: "200px", borderRadius: "20px" }}>
                  GETTING STARTED
                </Button>
              </Stack>
            </Box>
          </Box>
          <Box sx={{ height: `${bottomHeight}px` }}>
            <Stack direction="row" justifyContent={"center"} spacing={1}>
              <Typography variant="body1" color="text.primary" sx={{ whiteSpace: "nowwrap" }}>
                Already have an account?
              </Typography>
              <Link
                onClick={() => navigate(routes.LOGIN)}
                // href={routes.SIGNUP}
                variant="body1"
                fontWeight={"bolder"}
                color="text.primary"
                underline="hover"
                sx={{ cursor: "pointer", whiteSpace: "nowwrap" }}
              >
                LOG IN
              </Link>
            </Stack>
          </Box>
        </Stack>
      </CustomContainer>
    </>
  );
};

export default GetStarted;
