/* components */
/* 3rd party lib */
import React, { useState } from "react";
import Fab from "@mui/material/Fab";
import { faBars, faBell } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppBar, useTheme } from "@mui/material";
import Box from "@mui/material/Box/Box";
import { useNavigate } from "react-router-dom";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import MerchantSidebar from "src/components/common/navigation/merchant-sidebar";

/* Util */
interface MerchantTopNavbarProps {
  back?: boolean;
  url?: string;
  version?: string;
}

type Props = MerchantTopNavbarProps;

const MerchantTopNavbar: React.FC<Props> = ({ back = false, version = "", url }) => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  const theme = useTheme();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  /* ================================================== */
  /*  method */
  /* ================================================== */
  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event.type === "keydown" && ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")) {
      return;
    }

    setOpen(open);
  };

  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <AppBar
      sx={{
        boxShadow: "none",
        zIndex: theme.zIndex.drawer - 1,
        height: "52px",
        width: "100%",
        background: version === "curve" ? "transparent" : theme.palette.secondary.light,
      }}
    >
      <Box
        sx={{
          "&:hover": {
            "& .fa-bars": {
              transition: "all 0.5s ease",
              color: theme.palette.secondary.main,
            },
          },
          "&:active": {
            "& .fa-bars": {
              transition: "none",
              color: "white",
            },
          },
        }}
        onClick={() => {
          if (!!url) {
            // !!url is the same as url !== undefined
            navigate(url); // navigate to the url
            return;
          }

          if (back) {
            navigate(-1);
          } else {
            setOpen(true);
          }
        }}
      >
        {back ? (
          <FontAwesomeIcon
            icon={faAngleLeft}
            size="xl"
            color={theme.palette.secondary.main}
            style={{ cursor: "pointer", position: "absolute", top: "50%", transform: "translateY(-50%)", left: "16px" }}
          />
        ) : (
          <FontAwesomeIcon
            icon={faBars}
            size="xl"
            color={theme.palette.secondary.main}
            style={{ cursor: "pointer", position: "absolute", top: "50%", transform: "translateY(-50%)", left: "16px" }}
          />
        )}
      </Box>
      <Fab
        sx={{ background: "white", position: "absolute", top: "50%", transform: "translateY(-50%)", right: "16px" }}
        size="small"
        aria-label="edit"
      >
        <FontAwesomeIcon icon={faBell} color={theme.palette.secondary.main} size="lg" />
      </Fab>

      <MerchantSidebar open={open} toggleDrawer={toggleDrawer} />
    </AppBar>
  );
};

export default MerchantTopNavbar;
