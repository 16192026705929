import { createTheme as createMuiTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { baseThemeOptions } from "./base-theme-options";
import { darkThemeOptions } from "./dark-theme-options";
import { lightThemeOptions } from "./light-theme-options";

export const useCreateTheme = (config: { mode: "light" | "dark" }) => {
  const { i18n } = useTranslation();
  let theme = createMuiTheme(baseThemeOptions(i18n.language) as any, config.mode === "dark" ? darkThemeOptions : lightThemeOptions);

  return theme;
};
