import React from "react";
/* components */
import MerchantLoyaltyActivityCard from "src/components/merchant/merchant-loyalty-activity-card";
/* 3rd party lib */
import { Stack } from "@mui/material";
/* Util */
interface MerchantLoyaltyActivityListProps {}

type Props = MerchantLoyaltyActivityListProps;

const MerchantLoyaltyActivityList: React.FC<Props> = () => {
  /* ================================================== */
  /*  state */
  /* ================================================== */
  /* ================================================== */
  /*  method */
  /* ================================================== */
  /* ================================================== */
  /*  useEffect */
  /* ================================================== */

  /* ================================================== */
  /* ================================================== */
  return (
    <Stack spacing={2} mt={3}>
      <MerchantLoyaltyActivityCard />
      <MerchantLoyaltyActivityCard />
      <MerchantLoyaltyActivityCard />
      <MerchantLoyaltyActivityCard />
      <MerchantLoyaltyActivityCard />
      <MerchantLoyaltyActivityCard />
      <MerchantLoyaltyActivityCard />
      <MerchantLoyaltyActivityCard />
      <MerchantLoyaltyActivityCard />
      <MerchantLoyaltyActivityCard />
      <MerchantLoyaltyActivityCard />
    </Stack>
  );
};

export default MerchantLoyaltyActivityList;
