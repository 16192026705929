import { useState } from "react";
// import NextLink from "next/link";
import { Collapse, IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText, useTheme, alpha } from "@mui/material";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { useDispatch } from "react-redux";
import { closeSidebar } from "src/slices/layout";
import useMediaQueries from "src/hooks/use-mediaqueries";
import { useNavigate } from "react-router-dom";

export interface IDashboardSidebarItem {
  active: boolean;
  open?: boolean;
  title: string;
  path: string;
  icon: React.ReactNode;
  children?: React.ReactNode;
}

const DashboardSidebarItem = ({ active, open, title, path, icon, children }: IDashboardSidebarItem) => {
  const [submenuOpen, setSubmenuOpen] = useState<boolean>(!!open);
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { lgUp } = useMediaQueries();
  const handleCloseSidebar = () => {
    if (!lgUp) {
      dispatch(closeSidebar());
    }
  };

  if (children) {
    return (
      <>
        <ListItem
          disablePadding
          secondaryAction={
            <IconButton
              onClick={e => {
                e.stopPropagation();
                setSubmenuOpen(!submenuOpen);
              }}
            >
              {submenuOpen ? (
                <KeyboardArrowDownRoundedIcon fontSize="small" sx={{ color: "white" }} />
              ) : (
                <KeyboardArrowRightRoundedIcon fontSize="small" sx={{ color: "white" }} />
              )}
            </IconButton>
          }
          sx={{
            px: 1,
            transition: "all 0.5s ease",
            backgroundColor: active ? alpha(theme.palette.gradient_secondary.main, 0.3) : "transparent",
            color: "#ffffff",
            justifyContent: "flex-start",
            textAlign: "left",
            textTransform: "none",
            width: "100%",
            "&:hover": {
              backgroundColor: alpha(theme.palette.gradient_secondary.main, 0.6),
            },
          }}
          onClick={handleCloseSidebar}
        >
          <ListItemButton onClick={() => navigate(path, { replace: true })}>
            <ListItemIcon sx={{ color: "#ffffff" }}>{icon}</ListItemIcon>
            <ListItemText primary={title} primaryTypographyProps={{ variant: "body2", fontWeight: 600 }} />
          </ListItemButton>
        </ListItem>
        <Collapse in={submenuOpen}>{children}</Collapse>
      </>
    );
  } else {
    return (
      <ListItem
        disablePadding
        sx={{
          px: 1,
          transition: "all 0.5s ease",
          backgroundColor: active ? alpha(theme.palette.gradient_secondary.main, 0.3) : "transparent",
          color: "#ffffff",
          justifyContent: "flex-start",
          textAlign: "left",
          textTransform: "none",
          width: "100%",
          "&:hover": {
            backgroundColor: alpha(theme.palette.gradient_secondary.main, 0.6),
          },
        }}
        onClick={handleCloseSidebar}
      >
        <ListItemButton onClick={() => navigate(path, { replace: true })}>
          <ListItemIcon sx={{ color: "#ffffff" }}>{icon}</ListItemIcon>
          <ListItemText primary={title} primaryTypographyProps={{ variant: "body2", fontWeight: 600 }} />
        </ListItemButton>
      </ListItem>
    );
  }
};

export default DashboardSidebarItem;
