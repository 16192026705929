import { Box, useTheme } from "@mui/material";
import { Global } from "@emotion/react";

const SpinnerComponent = () => {
  const theme = useTheme();
  return (
    <>
      <Global
        styles={{
          ".lds-ring": {
            display: "inline-block",
            position: "relative",
            width: "80px",
            height: "80px",
          },
          ".lds-ring div": {
            boxSizing: "border-box",
            display: "block",
            position: "absolute",
            width: "64px",
            height: "64px",
            margin: "8px",
            border: `6px solid ${theme.palette.primary.main}`,
            borderRadius: "50%",
            animation: "lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite",
            borderColor: `${theme.palette.primary.main} transparent transparent transparent`,
          },
          ".lds-ring div:nth-of-type(1)": {
            animationDelay: "-0.45s",
          },
          ".lds-ring div:nth-of-type(2)": {
            animationDelay: "-0.3s",
          },
          ".lds-ring div:nth-of-type(3)": {
            animationDelay: "-0.15s",
          },
          "@keyframes lds-ring": {
            "0%": {
              transform: "rotate(0deg)",
            },
            "100%": {
              transform: "rotate(360deg)",
            },
          },
        }}
      />

      <Box sx={{ display: "grid", placeItems: "center" }}>
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </Box>
    </>
  );
};

export default SpinnerComponent;
