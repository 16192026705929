import { useSelector } from "react-redux";
import DashboardSidebar from "./dashboard-sidebar";
import DashboardNavbar from "./dashboard-navbar";
import { Box, Breakpoint, Container, styled, useTheme } from "@mui/material";
import { RootState } from "src/store";
import useMediaQueries from "src/hooks/use-mediaqueries";
import React from "react";

interface RootProps {
  open?: boolean;
}

const SidebarLayoutRoot = styled("div", { shouldForwardProp: props => props !== "open" })<RootProps>(({ theme, open }) => ({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
  marginTop: 64,
  paddingLeft: open ? 280 : 0,
  transition: theme.transitions.create(["padding"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.leavingScreen,
  }),
  [theme.breakpoints.down("lg")]: {
    paddingLeft: 0,
  },
}));

interface RootProps {
  disablePadding?: string;
}

const StyledContainer = styled(Container, { shouldForwardProp: props => props !== "disablePadding" })<RootProps>(({ theme, disablePadding }) => {
  return disablePadding === "true" ? { padding: 0, [theme.breakpoints.down("lg")]: { padding: 0 } } : {};
});

const DashboardSidebarLayout = ({
  children,
  maxWidth = "lg",
  disablePadding = false,
}: {
  children: React.ReactNode;
  maxWidth?: Breakpoint | false;
  disablePadding?: boolean;
}) => {
  const { sidebar } = useSelector((state: RootState) => state.layout);
  const { lgUp } = useMediaQueries();
  const theme = useTheme();
  return (
    <>
      <DashboardSidebar open={sidebar.open} />
      <DashboardNavbar sidebarOpen={sidebar.open} lgUp={lgUp} />
      <SidebarLayoutRoot open={sidebar.open}>
        <Box
          component="main"
          sx={{
            display: "flex",
            flex: "1 1 auto",
            flexDirection: "column",
            py: 3,
            width: "100%",
            [theme.breakpoints.down("sm")]: {
              py: 2,
            },
          }}
        >
          <StyledContainer disablePadding={disablePadding.toString()} maxWidth={maxWidth === false ? false : maxWidth}>
            {children}
          </StyledContainer>
        </Box>
      </SidebarLayoutRoot>
    </>
  );
};

export default DashboardSidebarLayout;
